import { Injectable } from '@angular/core';
import { ServiceRouter } from '../core/http-service-router.service';
import { Environment } from 'src/app/config/environment';
import { Config } from 'src/app/config/config';
import { of, Observable } from 'rxjs';

import { METHOD_TYPE } from 'src/app/constants/service.constants';

/**
 * Show tutorial interface
 */
interface ShowTutorial {
	/** @param showTutorial - Show tutorial */
	showTutorial: boolean;
}
/** Tutorial Service */
@Injectable({
	providedIn: 'root',
})
export class TutorialService {
	/**
	 * Tutorial constructor
	 * @param config - Url configuration
	 * @param serviceRouter - Service router
	 * @param environment - Environment
	 */
	constructor(
		private serviceRouter: ServiceRouter,
		private environment: Environment,
		private config: Config
	) {}

	/** Service call to decide to show tutorial at start */
	showTutorial(): Observable<ShowTutorial> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().DASHBOARD_BASE_URL,
			this.config.url.dashboard.tutorial.status,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}
	/** Service call to post tutorial status (skipped or finished)  */
	postStatus(body: any): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().DASHBOARD_BASE_URL,
			this.config.url.dashboard.tutorial.status,
			METHOD_TYPE.SECURED_POST,
			body
		);
	}
}
