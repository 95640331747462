import { FitBoundsOptions } from 'maplibre-gl';
import { CUSTOM_MAP_MARKERS, MAP_MARKER_TYPES } from 'src/app/constants/scheduling.constants';
import { InventorySlot } from '../shared/types';

/** Book appointment object */
export class BookAppointment {
	/** Array of location Ids */
	locationIDs: any[];
	/** Procedure Id */
	procedureId: any;
	/** Array of sub procedure Ids */
	subProcedureIDs: any[];
	/** Date object */
	date: Date;
	/** Slots for only available days flag */
	availableDays: boolean;
}

/** Appointment bean */
export class Appointment {
	/** appointment id which is to be rescheduled */
	reschedApptID: number;
	/** location id in which appointment is booked */
	facilityID: number;
	/** slot of appointment */
	selectedSlot: InventorySlot;
}

/** UtmParams Bean */
export class UtmParams {
	/** source of the traffic */
	utm_source: string;
	/** medium used. eg email */
	utm_medium: string;
	/** campaign strategy - launch, promotion */
	utm_campaign: string;
	/** keywords */
	utm_term: string;
	/** additional details */
	utm_content: string;
}

/** Scheduling appointment bean */
export class SchedulingAppointmentBean {
	/** appointment which is rescheduled */
	reschedApptID: number;
	/** location id */
	facilityID: string | number;
	/** selected time slot - holds date and time */
	selectedSlot: any;
	/** utm params - if applicable */
	utmParams: UtmParams;
	/** procedures selected */
	selectedProcedures: any;
}

export class SchedulingPVAppointmentBean {
	/**  */
	canConfirm: boolean;
	/** appointment which is rescheduled */
	reschedApptID?: number;
	/** selected time slot - holds date and time */
	selectedSlot: InventorySlot;
	/** utm params - if applicable */
	utmParams: UtmParams;
	/** State selected for a PV appointment */
	state: string;
	/** Timezone */
	timezone: string;
}

/** Mammogram bean */
export class MammogramBean {
	/** holds previous mammogram history details */
	previousMammogram: string;
	/** last mammo month - eg.08 */
	lastMammogramMonth: number;
	/** last mammo year- eg.1990 */
	lastMammogramYear: number;
	/** to check if user had breast implants */
	haveBreastImplants: number;
	/** to check if user has breast surgery */
	haveBreastSurgery: number;
	/** to check if user has lumps or discharges */
	haveLumpsOrDischarge: number;
	/** to check if family had cancer */
	familyHistoryHasCancer: number;
}

/** Request appointment bean */
export class RequestAppointmentBean {
	/** boolean to ask for attire */
	askForAttire: boolean;
	/** location details */
	facilityID: number;
	/** to check if attire note has been added */
	hasNoteOnAttire: boolean;
	/** procedure id - selected */
	procedureID: number;
	/** to check if booking prompt has to be shown */
	showPromptForBooking: boolean;
	/** to check if report format has to be shown */
	showReportFormat: boolean;
	/** holds list of subprocedure ids */
	subProcedureIDs: Array<any>;
	/** to collect sms reminder details */
	smsReminder: number;
}

/** appointment bean */
export class AppointmentBean {
	/** holds if user has mammo history or not */
	askForMammoHistory: boolean;
	/** holds mammo history info */
	mammoHistInfo: MammogramBean;
	/** holds report format */
	reportFormat: number;
	/** holds appointment booking reason */
	bookingReason: string;
	/** holds sms reminder info */
	smsReminder: string;
	/** holds cellphone info */
	cellPhone: string;
	/** holds the information to be sent to coordinator */
	noteToCoordinator: string;
	/** holds credit card info */
	hasCreditCardInfo: boolean;
	/** holds scrub size */
	scrubSize: string;
	/** holds old phone number */
	oldCellPhone: string;
	/** holds if car service is required */
	fromCarService: string;
	/** holds information about car service */
	toCarService: string;
	/** holds car service info */
	carService: string;
	/** holds credit card number */
	cardNumber: string;
	/** holds card holder name */
	nameOnCard: string;
	/** holds address type either profile or other */
	addressType: string;
	/** holds card type info - type of card - visa, mastercard, etc */
	cardType: string;
	/** holds card expiration date */
	cardExpirationDate: string;
	/** holds cvv info of card */
	cvvCode: string;
	/** holds address details - line 1 */
	addressLine1: string;
	/** holds address details - line 2 */
	addressLine2: string;
	/** holds address details - city */
	city: string;
	/** holds address details - state */
	state: string;
	/** holds address details - zip */
	zip: string;
}

/** ReschedulingAppointmentBean */
export class ReschedulingAppointmentBean {
	/** holds procedure id */
	procedureId: any;
	/** holds procedure name */
	procedureName: string;
	/** holds reschedule appointment id */
	rescheduleAppointmentId: any;
	/** holds location */
	location: any;
	/** holds appointment confirmation number */
	confirmationNumber: string;
	/** holds appointment date */
	date: any;
	/** holds procedure for rescheduling */
	rescheduleProcedures: Array<any>;
}

/**
 * Map config
 */
export interface MapConfig {
	/**
	 * Marker color
	 */
	markerColor?: string;
	/**
	 * Source name for Default MapGL Amplify Marker
	 */
	mapLibreAmplifyMarkersSourceName: string;
	/**
	 * Type of Map marker to use
	 * Must initialized customMarkerVariant, if `MAP_MARKER_TYPES.CUSTOM` is selected
	 */
	markerType: MAP_MARKER_TYPES;
	/**
	 * If Map Marker is CUSTOM, then which Variant of the custom map markers to use
	 * Must be initialized if markerType is `MAP_MARKER_TYPES.CUSTOM`
	 * @default CUSTOM_MAP_MARKERS.CUSTOM_HTML_1
	 */
	customMarkerVariant?: CUSTOM_MAP_MARKERS; //
	/**
	 * Get Bounding options for the map when markers are added
	 * @param markerType Which marker type is used
	 * @param customMarkerVariant If marker type is `MAP_MARKER_TYPES.CUSTOM`, which custom marker variant is used
	 */
	getMapBoundingOptions?(markerType?: MAP_MARKER_TYPES, customMarkerVariant?: CUSTOM_MAP_MARKERS): FitBoundsOptions;
}
