import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AnalyticsBean } from '../../../../beans/analytics/analytics-module.bean';

@Component({
	selector: 'app-ehe-browser-notification-modal',
	templateUrl: './ehe-browser-notification-modal.component.html',
	styleUrls: ['./ehe-browser-notification-modal.component.scss'],
})
export class BrowserNotificationModalComponent implements OnInit {
	analyticsData: AnalyticsBean;

	constructor(
		public dialogRef: MatDialogRef<BrowserNotificationModalComponent>,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.analyticsData = new AnalyticsBean();
		this.analyticsData.componentName = 'BrowserNotificationModalComponent';
		this.analyticsData.pageUrl = window.location.pathname;

		this.analyticsData.id = 'IE Browser Notification';
		this.analyticsData.redirectedTo = '';
		this.analyticsData.placement = 'IE Browser Notification modal';
	}

	skip(): void {
		this.dialogRef.close(0);
	}

	finish(): void {
		this.dialogRef.close(1);
	}
}
