<div class="wordpress-modal-wrapper">
	<app-white-overlay>
		<div (click)="close()" class="close-icon">
			<div class="close"></div>
		</div>
		<div class="content">
			<iframe
				class="loader"
				[src]="safeUrl"
				frameborder="0"
				scrolling="auto"
				(load)="$any($event).target.contentWindow.focus()"></iframe>
		</div>
	</app-white-overlay>
</div>
