<button id="variationTrigger" style="display: none" (click)="applyVariation = !applyVariation"></button>
<app-ehe-header></app-ehe-header>
<div class="general-wrapper" [ngClass]="getWrapperClass()">
	<section *ngIf="!applyVariation" class="container">
		<div class="row justify-content-md-center">
			<div class="col-md-12 content-box value-p-0">
				<div class="auth-content-wrapper">
					<div class="auth-content home-page-wrapper">
						<app-value-proposition></app-value-proposition>
					</div>
					<div *ngIf="simplified; else oldForm">
						<app-login-simplified
							[externalError]="inactivityLogoutDetected ? inactivityErrorText : null"></app-login-simplified>
					</div>
					<ng-template #oldForm>
						<app-login></app-login>
					</ng-template>
				</div>
				<div class="divider">
					<div class="row">
						<div class="col-md-4 align-center">
							<div class="placeholder">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.27 171.27">
									<title>Asset 1</title>
									<g id="Layer_2" data-name="Layer 2">
										<g id="Layer_1-2" data-name="Layer 1">
											<path
												class="cls-1"
												d="M112.4,161.33V144a64.23,64.23,0,1,0-53.53,0v17.33a80.29,80.29,0,1,1,53.53,0m-5.36-65V163c-1.76.49-3.54.93-5.35,1.29v-68a2.68,2.68,0,0,0-1.53-2.42C94.33,91.17,91,85.23,91,77.61c0-11,6.5-21.41,13.38-21.41s13.38,10.4,13.38,21.41c0,7.62-3.35,13.56-9.17,16.31A2.67,2.67,0,0,0,107,96.34m-10.7,47.19a59,59,0,0,1-21.41,0V99.65L80,89.51a2.67,2.67,0,0,0,.28-1.2h0V53.52a2.68,2.68,0,0,0-5.35,0V85.63H69.58V53.52a2.68,2.68,0,0,0-5.35,0V85.63H58.87V53.52a2.68,2.68,0,0,0-5.35,0V88.31h0a2.8,2.8,0,0,0,.27,1.2l5.07,10.14v38.41a58.88,58.88,0,1,1,53.53,0V98c6.73-3.9,10.7-11.37,10.7-20.34,0-12.66-7.69-26.76-18.73-26.76S85.63,65,85.63,77.61c0,9,4,16.44,10.71,20.34Zm0,21.67a79.94,79.94,0,0,1-21.41,0V149a64.12,64.12,0,0,0,21.41,0ZM69.86,97.82A2.6,2.6,0,0,0,69.58,99V164.3c-1.81-.36-3.59-.8-5.35-1.29V99a2.61,2.61,0,0,0-.29-1.19L60.53,91H73.28ZM85.63,0a85.64,85.64,0,1,0,85.64,85.63A85.74,85.74,0,0,0,85.63,0" />
											<path
												class="cls-1"
												d="M112.4,161.33V144a64.23,64.23,0,1,0-53.53,0v17.33a80.29,80.29,0,1,1,53.53,0Zm-5.36-65V163c-1.76.49-3.54.93-5.35,1.29v-68a2.68,2.68,0,0,0-1.53-2.42C94.33,91.17,91,85.23,91,77.61c0-11,6.5-21.41,13.38-21.41s13.38,10.4,13.38,21.41c0,7.62-3.35,13.56-9.17,16.31A2.67,2.67,0,0,0,107,96.34Zm-10.7,47.19a59,59,0,0,1-21.41,0V99.65L80,89.51a2.67,2.67,0,0,0,.28-1.2h0V53.52a2.68,2.68,0,0,0-5.35,0V85.63H69.58V53.52a2.68,2.68,0,0,0-5.35,0V85.63H58.87V53.52a2.68,2.68,0,0,0-5.35,0V88.31h0a2.8,2.8,0,0,0,.27,1.2l5.07,10.14v38.41a58.88,58.88,0,1,1,53.53,0V98c6.73-3.9,10.7-11.37,10.7-20.34,0-12.66-7.69-26.76-18.73-26.76S85.63,65,85.63,77.61c0,9,4,16.44,10.71,20.34Zm0,21.67a79.94,79.94,0,0,1-21.41,0V149a64.12,64.12,0,0,0,21.41,0ZM69.86,97.82A2.6,2.6,0,0,0,69.58,99V164.3c-1.81-.36-3.59-.8-5.35-1.29V99a2.61,2.61,0,0,0-.29-1.19L60.53,91H73.28ZM85.63,0a85.64,85.64,0,1,0,85.64,85.63A85.74,85.74,0,0,0,85.63,0Z" />
										</g>
									</g>
								</svg>
							</div>
							<p [translate]="'registration.welcome-screen.bottom-section.content.left'">
								Nutritional guidance tailored to your health needs.
							</p>
						</div>
						<div class="col-md-4 align-center">
							<div class="placeholder">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 176.68 176.68">
									<title>Asset 1</title>
									<g id="Layer_2" data-name="Layer 2">
										<g id="Layer_1-2" data-name="Layer 1">
											<path
												class="cls-1"
												d="M161.39,127.33,159.05,126a2.76,2.76,0,1,0-2.76,4.78l2.33,1.34a83.54,83.54,0,0,1-26.52,26.52l-1.34-2.33a2.76,2.76,0,0,0-4.78,2.76l1.35,2.34a82.44,82.44,0,0,1-36.23,9.72V157.35a2.76,2.76,0,1,0-5.52,0v13.76a82.4,82.4,0,0,1-36.23-9.72l1.35-2.34a2.76,2.76,0,1,0-4.78-2.76l-1.35,2.33A83.51,83.51,0,0,1,18.06,132.1l2.33-1.34A2.76,2.76,0,0,0,17.63,126l-2.34,1.35A82.31,82.31,0,0,1,5.57,91.1H19.32a2.76,2.76,0,1,0,0-5.52H5.57a82.31,82.31,0,0,1,9.72-36.23l2.34,1.35a2.76,2.76,0,1,0,2.76-4.78l-2.33-1.34A83.41,83.41,0,0,1,44.57,18.06l1.35,2.33a2.76,2.76,0,1,0,4.78-2.76l-1.35-2.34A82.27,82.27,0,0,1,85.58,5.57V19.33a2.76,2.76,0,1,0,5.52,0V5.57a82.31,82.31,0,0,1,36.23,9.72L126,17.63a2.74,2.74,0,0,0,1,3.77,2.71,2.71,0,0,0,1.37.37,2.77,2.77,0,0,0,2.4-1.38l1.34-2.33a83.44,83.44,0,0,1,26.52,26.52l-2.33,1.34a2.76,2.76,0,1,0,2.76,4.78l2.34-1.35a82.3,82.3,0,0,1,9.71,36.23H157.35a2.76,2.76,0,1,0,0,5.52H171.1a82.3,82.3,0,0,1-9.71,36.23m3.52-83a1.11,1.11,0,0,0-.07-.16c0-.07-.09-.12-.13-.18a88.94,88.94,0,0,0-32-32,1,1,0,0,0-.18-.13,1.3,1.3,0,0,0-.17-.08,88.21,88.21,0,0,0-88,0,1.19,1.19,0,0,0-.16.08A.75.75,0,0,0,44,12,88.87,88.87,0,0,0,12,44a1.24,1.24,0,0,0-.14.18s0,.11-.07.16a88.23,88.23,0,0,0,0,88c0,.05,0,.11.07.16a3,3,0,0,0,.2.29,89,89,0,0,0,32,31.91c.06,0,.11.09.18.13a1.67,1.67,0,0,0,.23.12,88.21,88.21,0,0,0,87.87,0l.24-.12.18-.13a89.1,89.1,0,0,0,32-31.91,2.9,2.9,0,0,0,.19-.29,1.11,1.11,0,0,0,.07-.16,88.17,88.17,0,0,0,0-88" />
											<path
												class="cls-1"
												d="M161.39,127.33,159.05,126a2.76,2.76,0,1,0-2.76,4.78l2.33,1.34a83.54,83.54,0,0,1-26.52,26.52l-1.34-2.33a2.76,2.76,0,0,0-4.78,2.76l1.35,2.34a82.44,82.44,0,0,1-36.23,9.72V157.35a2.76,2.76,0,1,0-5.52,0v13.76a82.4,82.4,0,0,1-36.23-9.72l1.35-2.34a2.76,2.76,0,1,0-4.78-2.76l-1.35,2.33A83.51,83.51,0,0,1,18.06,132.1l2.33-1.34A2.76,2.76,0,0,0,17.63,126l-2.34,1.35A82.31,82.31,0,0,1,5.57,91.1H19.32a2.76,2.76,0,1,0,0-5.52H5.57a82.31,82.31,0,0,1,9.72-36.23l2.34,1.35a2.76,2.76,0,1,0,2.76-4.78l-2.33-1.34A83.41,83.41,0,0,1,44.57,18.06l1.35,2.33a2.76,2.76,0,1,0,4.78-2.76l-1.35-2.34A82.27,82.27,0,0,1,85.58,5.57V19.33a2.76,2.76,0,1,0,5.52,0V5.57a82.31,82.31,0,0,1,36.23,9.72L126,17.63a2.74,2.74,0,0,0,1,3.77,2.71,2.71,0,0,0,1.37.37,2.77,2.77,0,0,0,2.4-1.38l1.34-2.33a83.44,83.44,0,0,1,26.52,26.52l-2.33,1.34a2.76,2.76,0,1,0,2.76,4.78l2.34-1.35a82.3,82.3,0,0,1,9.71,36.23H157.35a2.76,2.76,0,1,0,0,5.52H171.1A82.3,82.3,0,0,1,161.39,127.33Zm3.52-83a1.11,1.11,0,0,0-.07-.16c0-.07-.09-.12-.13-.18a88.94,88.94,0,0,0-32-32,1,1,0,0,0-.18-.13,1.3,1.3,0,0,0-.17-.08,88.21,88.21,0,0,0-88,0,1.19,1.19,0,0,0-.16.08A.75.75,0,0,0,44,12,88.87,88.87,0,0,0,12,44a1.24,1.24,0,0,0-.14.18s0,.11-.07.16a88.23,88.23,0,0,0,0,88c0,.05,0,.11.07.16a3,3,0,0,0,.2.29,89,89,0,0,0,32,31.91c.06,0,.11.09.18.13a1.67,1.67,0,0,0,.23.12,88.21,88.21,0,0,0,87.87,0l.24-.12.18-.13a89.1,89.1,0,0,0,32-31.91,2.9,2.9,0,0,0,.19-.29,1.11,1.11,0,0,0,.07-.16,88.17,88.17,0,0,0,0-88Z" />
											<path
												class="cls-1"
												d="M128.3,64.15a2.74,2.74,0,0,0-1.13,3.71,43.55,43.55,0,0,1-7.71,51.64,44,44,0,0,1-60.11,1.88h7.08a2.74,2.74,0,1,0,0-5.48H52.7A2.74,2.74,0,0,0,50,118.64v13.7a2.75,2.75,0,0,0,5.49,0v-7.08a49.39,49.39,0,0,0,76.56-60,2.75,2.75,0,0,0-3.71-1.13" />
											<path
												class="cls-1"
												d="M128.3,64.15a2.74,2.74,0,0,0-1.13,3.71,43.55,43.55,0,0,1-7.71,51.64,44,44,0,0,1-60.11,1.88h7.08a2.74,2.74,0,1,0,0-5.48H52.7A2.74,2.74,0,0,0,50,118.64v13.7a2.75,2.75,0,0,0,5.49,0v-7.08a49.39,49.39,0,0,0,76.56-60A2.75,2.75,0,0,0,128.3,64.15Z" />
											<path
												class="cls-1"
												d="M117.32,55.3h-7.07a2.74,2.74,0,1,0,0,5.48H124A2.75,2.75,0,0,0,126.72,58V44.34a2.75,2.75,0,0,0-5.49,0v7.08a49.39,49.39,0,0,0-76.56,60,2.72,2.72,0,0,0,2.42,1.45,2.78,2.78,0,0,0,1.3-.32,2.74,2.74,0,0,0,1.12-3.71,43.53,43.53,0,0,1,7.7-51.66,44,44,0,0,1,60.11-1.88" />
											<path
												class="cls-1"
												d="M117.32,55.3h-7.07a2.74,2.74,0,1,0,0,5.48H124A2.75,2.75,0,0,0,126.72,58V44.34a2.75,2.75,0,0,0-5.49,0v7.08a49.39,49.39,0,0,0-76.56,60,2.72,2.72,0,0,0,2.42,1.45,2.78,2.78,0,0,0,1.3-.32,2.74,2.74,0,0,0,1.12-3.71,43.53,43.53,0,0,1,7.7-51.66A44,44,0,0,1,117.32,55.3Z" />
											<path
												class="cls-1"
												d="M79.94,92.89c4.67-5.18,5.37-10.15,5.13-13.41A13.65,13.65,0,0,0,72.32,66.62a13.21,13.21,0,0,0-9.89,3.75,13.52,13.52,0,0,0-4.15,9.82,2.68,2.68,0,1,0,5.36,0,8.13,8.13,0,0,1,2.49-5.89,7.9,7.9,0,0,1,5.94-2.25,8.27,8.27,0,0,1,7.66,7.83c.22,3.16-1,6.28-3.69,9.27L59.12,105.38a2.73,2.73,0,0,0-.65,3A2.7,2.7,0,0,0,61,110.07H82.41a2.72,2.72,0,0,0,0-5.43H67.69L79.8,93a1.85,1.85,0,0,0,.14-.14" />
											<path
												class="cls-1"
												d="M79.94,92.89c4.67-5.18,5.37-10.15,5.13-13.41A13.65,13.65,0,0,0,72.32,66.62a13.21,13.21,0,0,0-9.89,3.75,13.52,13.52,0,0,0-4.15,9.82,2.68,2.68,0,1,0,5.36,0,8.13,8.13,0,0,1,2.49-5.89,7.9,7.9,0,0,1,5.94-2.25,8.27,8.27,0,0,1,7.66,7.83c.22,3.16-1,6.28-3.69,9.27L59.12,105.38a2.73,2.73,0,0,0-.65,3A2.7,2.7,0,0,0,61,110.07H82.41a2.72,2.72,0,0,0,0-5.43H67.69L79.8,93A1.85,1.85,0,0,0,79.94,92.89Z" />
											<path
												class="cls-1"
												d="M95.85,93.77,106.61,72h1V93.77Zm14.48,16.3a2.7,2.7,0,0,0,2.68-2.72V99.2h2.7a2.72,2.72,0,0,0,0-5.43H113V69.32a2.69,2.69,0,0,0-2.68-2.71H105a2.7,2.7,0,0,0-2.41,1.5L89.09,95.27a2.77,2.77,0,0,0,.12,2.65A2.68,2.68,0,0,0,91.5,99.2h16.14v8.15a2.7,2.7,0,0,0,2.69,2.72" />
											<path
												class="cls-1"
												d="M95.85,93.77,106.61,72h1V93.77Zm14.48,16.3a2.7,2.7,0,0,0,2.68-2.72V99.2h2.7a2.72,2.72,0,0,0,0-5.43H113V69.32a2.69,2.69,0,0,0-2.68-2.71H105a2.7,2.7,0,0,0-2.41,1.5L89.09,95.27a2.77,2.77,0,0,0,.12,2.65A2.68,2.68,0,0,0,91.5,99.2h16.14v8.15A2.7,2.7,0,0,0,110.33,110.07Z" />
										</g>
									</g>
								</svg>
							</div>
							<p [translate]="'registration.welcome-screen.bottom-section.content.center'">
								Helping you find balance and resilience for well-being.
							</p>
						</div>
						<div class="col-md-4 align-center">
							<div class="placeholder">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 197.87 173.2">
									<title>Asset 1</title>
									<g id="Layer_2" data-name="Layer 2">
										<g id="Layer_1-2" data-name="Layer 1">
											<path
												class="cls-1"
												d="M191.69,160.83H6.18v-6.19H191.69ZM39.63,122.68a3.06,3.06,0,0,0,.56-1.78v-7.26a3.08,3.08,0,0,0-1.32-2.53A18.56,18.56,0,1,1,67.25,90.57a3.07,3.07,0,0,0,3,2.21h35c-.07,1-.12,2.06-.12,3.1a43.86,43.86,0,0,0,.45,6.18H69a3.09,3.09,0,0,0-2.79,1.77,18.65,18.65,0,0,1-6.16,7.26,3.11,3.11,0,0,0-1.33,2.54v7.27a3.07,3.07,0,0,0,.57,1.78l18.2,25.77H21.43Zm67.31-14.43a43.28,43.28,0,0,0,32.19,29.93v10.27h-54L64.93,119.92v-4.74a24.9,24.9,0,0,0,6-6.93ZM136,60.9l9.41,35.76a3.09,3.09,0,1,0,6-1.57L142,59.33a36.67,36.67,0,0,1,6.42-.57,37.12,37.12,0,0,1,5.71,73.79,3.1,3.1,0,0,0-2.62,3.06v12.84h-6.19V135.61a3.1,3.1,0,0,0-2.62-3.06A37.11,37.11,0,0,1,136,60.9m58.77,87.55h-37.1V138.18a43.3,43.3,0,0,0-17.27-84.85L136.64,39l17.86-6.85a9.27,9.27,0,0,0,6.27-8.78V9.28A9.28,9.28,0,0,0,151.5,0a3.09,3.09,0,0,0,0,6.18,3.1,3.1,0,0,1,3.09,3.1v14.1a3.08,3.08,0,0,1-2.12,2.93l-.12.05-20.51,7.87A3.08,3.08,0,0,0,130,37.9l4.47,17a43.43,43.43,0,0,0-28.3,31.69H72.4A24.92,24.92,0,0,0,49.47,71.13a24.38,24.38,0,0,0-6.82,1l-7-16.43h17a3.09,3.09,0,0,0,0-6.18H27.83V40.21a3.1,3.1,0,1,0-6.19,0V52.58a3.09,3.09,0,0,0,3.09,3.09h4.15L37,74.54a24.73,24.73,0,0,0-3,40.65v4.73L13.86,148.45H3.09A3.1,3.1,0,0,0,0,151.55v12.37A3.09,3.09,0,0,0,3.09,167h9.28v3.09a3.09,3.09,0,1,0,6.18,0V167H179.32v3.09a3.1,3.1,0,1,0,6.19,0V167h9.27a3.09,3.09,0,0,0,3.09-3.09V151.55a3.09,3.09,0,0,0-3.09-3.1" />
											<path
												class="cls-1"
												d="M191.69,160.83H6.18v-6.19H191.69ZM39.63,122.68a3.06,3.06,0,0,0,.56-1.78v-7.26a3.08,3.08,0,0,0-1.32-2.53A18.56,18.56,0,1,1,67.25,90.57a3.07,3.07,0,0,0,3,2.21h35c-.07,1-.12,2.06-.12,3.1a43.86,43.86,0,0,0,.45,6.18H69a3.09,3.09,0,0,0-2.79,1.77,18.65,18.65,0,0,1-6.16,7.26,3.11,3.11,0,0,0-1.33,2.54v7.27a3.07,3.07,0,0,0,.57,1.78l18.2,25.77H21.43Zm67.31-14.43a43.28,43.28,0,0,0,32.19,29.93v10.27h-54L64.93,119.92v-4.74a24.9,24.9,0,0,0,6-6.93ZM136,60.9l9.41,35.76a3.09,3.09,0,1,0,6-1.57L142,59.33a36.67,36.67,0,0,1,6.42-.57,37.12,37.12,0,0,1,5.71,73.79,3.1,3.1,0,0,0-2.62,3.06v12.84h-6.19V135.61a3.1,3.1,0,0,0-2.62-3.06A37.11,37.11,0,0,1,136,60.9Zm58.77,87.55h-37.1V138.18a43.3,43.3,0,0,0-17.27-84.85L136.64,39l17.86-6.85a9.27,9.27,0,0,0,6.27-8.78V9.28A9.28,9.28,0,0,0,151.5,0a3.09,3.09,0,0,0,0,6.18,3.1,3.1,0,0,1,3.09,3.1v14.1a3.08,3.08,0,0,1-2.12,2.93l-.12.05-20.51,7.87A3.08,3.08,0,0,0,130,37.9l4.47,17a43.43,43.43,0,0,0-28.3,31.69H72.4A24.92,24.92,0,0,0,49.47,71.13a24.38,24.38,0,0,0-6.82,1l-7-16.43h17a3.09,3.09,0,0,0,0-6.18H27.83V40.21a3.1,3.1,0,1,0-6.19,0V52.58a3.09,3.09,0,0,0,3.09,3.09h4.15L37,74.54a24.73,24.73,0,0,0-3,40.65v4.73L13.86,148.45H3.09A3.1,3.1,0,0,0,0,151.55v12.37A3.09,3.09,0,0,0,3.09,167h9.28v3.09a3.09,3.09,0,1,0,6.18,0V167H179.32v3.09a3.1,3.1,0,1,0,6.19,0V167h9.27a3.09,3.09,0,0,0,3.09-3.09V151.55A3.09,3.09,0,0,0,194.78,148.45Z" />
											<path
												class="cls-1"
												d="M108.33,96a3.13,3.13,0,0,0,6.26,0A31.22,31.22,0,0,0,86.76,65a3.13,3.13,0,1,0-.69,6.22A25,25,0,0,1,108.33,96" />
											<path
												class="cls-1"
												d="M108.33,96a3.13,3.13,0,0,0,6.26,0A31.22,31.22,0,0,0,86.76,65a3.13,3.13,0,1,0-.69,6.22A25,25,0,0,1,108.33,96Z" />
											<path
												class="cls-1"
												d="M92.65,95.74A6.29,6.29,0,1,1,98.94,102a6.26,6.26,0,0,1-6.29-6.22m12.57,31.13h-3.14V107.78a12.57,12.57,0,1,0-6.28,0v19.09H92.65a3.12,3.12,0,1,0,0,6.23h12.57a3.12,3.12,0,1,0,0-6.23" />
											<path
												class="cls-1"
												d="M92.65,95.74A6.29,6.29,0,1,1,98.94,102,6.26,6.26,0,0,1,92.65,95.74Zm12.57,31.13h-3.14V107.78a12.57,12.57,0,1,0-6.28,0v19.09H92.65a3.12,3.12,0,1,0,0,6.23h12.57a3.12,3.12,0,1,0,0-6.23Z" />
										</g>
									</g>
								</svg>
							</div>
							<p [translate]="'registration.welcome-screen.bottom-section.content.right'">
								Support for building a more physically active life.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<!-- screen variation -->
	<section *ngIf="applyVariation">
		<div class="row pb-5">
			<div class="col-md-6 col-xs-12">
				<div class="pt-4 pb-4">
					<app-value-proposition></app-value-proposition>
				</div>
				<div>
					<app-login-simplified
						[externalError]="inactivityLogoutDetected ? inactivityErrorText : null"></app-login-simplified>
				</div>
			</div>
			<div class="col-md-6 col-xs-12">
				<div class="pt-4">
					<app-login-screen-variation></app-login-screen-variation>
				</div>
			</div>
		</div>
	</section>
	<app-ehe-spinner></app-ehe-spinner>
	<app-ehe-footer [socialNetwork]="false"></app-ehe-footer>
</div>
