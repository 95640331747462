<div class="content-box-wrapper">
	<div class="content-box form-wrapper">
		<h2 class="align-center section-heading" [translate]="'registration.login.form.title'">Sign In</h2>
		<div class="pb-4">
			<div class="align-center inline-error-message text-16" *ngIf="externalError || displayErrMsg || profileNotFound">
				<div *ngIf="externalError">
					<span>{{ externalError }}</span>
				</div>
				<ng-container *ngIf="!externalError">
					<div *ngIf="errMessage && displayErrMsg">
						<span [translate]="'registration.login.errors.sign-in-error.attempt-exceed'">
							Password attempts exceeded. Your account has been temporarily locked for security reasons.
						</span>
					</div>
					<div *ngIf="!errMessage">
						<span [translate]="'registration.login.errors.sign-in-error.line-1'">
							We couldn't find your EHE Health profile. Try signing in with any email that may be associated with your
							account.
						</span>
					</div>
					<div class="pt-2">
						<span
							class="link"
							[routerLink]="['/forgot/password']"
							[translate]="'registration.login.errors.sign-in-error.forgot-password-link'">
							Forgot email/username or password?
						</span>
					</div>
				</ng-container>
			</div>
		</div>
		<form [formGroup]="signInForm">
			<div class="row">
				<div class="col-12">
					<mat-form-field class="form-group">
						<mat-label>{{ 'registration.login.form.email-username' | translate }}</mat-label>
						<input matInput formControlName="email" />
						<mat-error>
							<app-error-message [control]="'email'"></app-error-message>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="col-12">
					<mat-form-field class="form-group">
						<mat-label>{{ 'registration.login.form.password' | translate }}</mat-label>
						<input matInput formControlName="password" type="password" autocomplete="off" appShowHidePassword />
						<mat-error>
							<app-error-message [control]="'password'"></app-error-message>
						</mat-error>
						<mat-hint
							*ngIf="showPasswordHint()"
							class="password-hint"
							[translate]="'registration.login.descriptions.password'"
							>Password is case-sensitive and contains special characters</mat-hint
						>
					</mat-form-field>
				</div>
				<div class="col-12">
					<div class="center">
						<button
							class="button"
							mat-button
							appEheAnalytics
							[data]="analyticsData"
							appGoogleAnalytics
							[eventData]="{
								eventCategory: 'Button',
								eventAction: 'Click-SignInButton',
								eventLabel: 'Signin - Sign In Button'
							}"
							(click)="login()">
							<span [translate]="'registration.login.form.submit-button'">Sign In</span>
						</button>
					</div>
				</div>
			</div>
		</form>
		<div class="align-center margin-top-20">
			<app-button-outline
				[analyticsData]="{
					pageUrl: '/login',
					redirectedTo: '/registration',
					componentName: 'login',
					id: 'Create My Account',
					placement: 'login screen'
				}"
				[googleAnalyticsData]="{
					eventCategory: 'Link',
					eventAction: 'Click-CreateAccount',
					eventLabel: 'Signin - Create Account Link'
				}"
				[buttonTextKey]="'registration.login.links.create-account'"
				(clickEvent)="redirect(createAccountUrl)">
			</app-button-outline>
		</div>

		<div class="align-center margin-top-20">
			<a
				id="forgotEmailPasswordLink"
				class="link"
				appEheAnalytics
				[data]="{
					pageUrl: '/login',
					redirectedTo: '/forgot/password',
					componentName: 'login',
					id: 'Forgot Password',
					placement: 'login screen'
				}"
				appGoogleAnalytics
				[eventData]="{
					eventCategory: 'Link',
					eventAction: 'Click-ForgotPassword',
					eventLabel: 'Signin - Forgot Password Link'
				}"
				[translate]="'registration.login.links.forgot-password'"
				(click)="redirect(forgotPasswordUrl)">
				Forgot email/username or password?
			</a>
		</div>
	</div>
</div>
