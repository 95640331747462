import { Injectable } from '@angular/core';
import { Environment } from '../../config/environment';
import { ServiceRouter } from '../core/http-service-router.service';
import { Config } from '../../config/config';
import { METHOD_TYPE } from '../../constants/service.constants';

@Injectable({
	providedIn: 'root',
})
export class VaxPassService {
	constructor(
		private serviceRouter: ServiceRouter,
		private environment: Environment,
		private config: Config
	) {}

	getPatientCovidRecord() {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().DASHBOARD_BASE_URL,
			this.config.url.dashboard.vaxpass.profile,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	getConsentMessage() {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().DASHBOARD_BASE_URL,
			this.config.url.dashboard.vaxpass.getConsent,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	setConsent() {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().DASHBOARD_BASE_URL,
			this.config.url.dashboard.vaxpass.getConsent,
			METHOD_TYPE.SECURED_POST,
			''
		);
	}

	sendPatientInfo({ body, vaccineState }) {
		const profileUrl = `${this.config.url.dashboard.vaxpass.profile}?states=${vaccineState}`;
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().DASHBOARD_BASE_URL,
			profileUrl,
			METHOD_TYPE.SECURED_POST,
			body
		);
	}

	getImmunization() {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.healthRecord.getImmunization,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	getVaccinations() {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().DASHBOARD_BASE_URL,
			this.config.url.dashboard.vaxpass.getVaccines,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	getFullStateNames() {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.dashboard.vaxpass.getStates,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}
}
