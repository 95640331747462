import { Injectable } from '@angular/core';
import { Environment } from '../../config/environment';
import { ServiceRouter } from '../core/http-service-router.service';
import { Config } from '../../config/config';
import { METHOD_TYPE } from '../../constants/service.constants';
import { Observable } from 'rxjs';
import { AssessmentVersion } from './health-history.types';

@Injectable({
	providedIn: 'root',
})
export class AssessmentCheckService {
	constructor(
		private serviceRouter: ServiceRouter,
		private environment: Environment,
		private config: Config
	) {}

	getAssessmentVersion(): Observable<AssessmentVersion> {
		const self = this;
		return self.serviceRouter.makeRequest(
			self.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			self.config.url.healthAssessmentApi.getAssessmentVersion,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}
}
