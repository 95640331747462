import { Injectable } from '@angular/core';
import { AppConfigService } from './app.config';

interface _ENV_FILE_CONFIG {
	env_key?: string;
	aws_region?: 'us-east-1';
	base_domain?: string;
	base_url?: string;
	cognito_client_id?: string;
	cognito_user_pool_id?: string;
	cognito_identity_pool_id?: string;
	cognito_login_url?: string;
	analytics_auth_events_bucket?: string;
	analytics_unauth_events_bucket?: string;
	under_maintenance?: string;
	maintenance_message?: string;
	s3_bucket_name?: string;
	s3_ui_logs_bucket?: string;
	s3_feed_image_bucket?: string;
	dashboard_static_content_bucket?: string;
	aws_location_service_map_name?: string;
	aws_location_service_place_index_name?: string;
	ssn_associated_clients?: string;
	comcast_clients?: string;
	catalent_clients?: string;
	ha_base_domain?: string;
	ha_base_url?: string;
	vaxpass_toggle?: string;
	consent_domain?: string;
	consent_url?: string;
	pulse_wellness_domain?: string;
	pulse_wellness_url?: string;
	enable_osano?: string;
}

export interface ENV {
	AWS_REGION: string;
	BASE_DOMAIN: string;
	DASHBOARD_BASE_URL: string;
	EPMS_PROXY_BASE_URL: string;
	CLIENT_ID: string;
	USER_POOL_ID: string;
	IDENTITY_POOL_ID: string;
	LOGINS_URL: string;
	ANALYTICS_AUTH_BUCKET: string;
	ANALYTICS_UNAUTH_BUCKET: string;
	UNDER_MAINTENANCE: string;
	MAINTENANCE_MESSAGE: string;
	S3_BUCKET_NAME: string;
	SSN_ASSOCIATED_CLIENTS: string;
	S3_FEED_IMAGE_BUCKET: string;
	ENV_KEY: string;
	DASHBOARD_STATIC_CONTENT_BUCKET: string;
	AWS_LOCATION_SERVICE_MAP_NAME: string;
	AWS_LOCATION_SERVICE_PLACE_INDEX_NAME: string;
	S3_UI_LOGS_BUCKET: string;
	COMCAST_CLIENTS: string;
	CATALENT_CLIENTS: string;
	HA_BASE_DOMAIN: string;
	HA_BASE_URL: string;
	VAXPASS_TOGGLE: string;
	CONSENT_DOMAIN: string;
	CONSENT_URL: string;
	PULSE_WELLNESS_DOMAIN: string;
	PULSE_WELLNESS_URL: string;
	ENABLE_OSANO: string;
}

/**
 * Environment configuration
 */
@Injectable()
export class Environment {
	/** These will be replaced per environment by Docker file from ENV using environment.json */
	ENV: _ENV_FILE_CONFIG = {
		env_key: '',
		aws_region: 'us-east-1',
		base_domain: '',
		base_url: '',
		cognito_client_id: '',
		cognito_user_pool_id: '',
		cognito_identity_pool_id: '',
		cognito_login_url: '',
		analytics_auth_events_bucket: '',
		analytics_unauth_events_bucket: '',
		under_maintenance: '',
		maintenance_message: '',
		s3_bucket_name: '',
		s3_ui_logs_bucket: '',
		s3_feed_image_bucket: '',
		dashboard_static_content_bucket: '',
		aws_location_service_map_name: '',
		aws_location_service_place_index_name: '',
		ssn_associated_clients: '',
		comcast_clients: '',
		catalent_clients: '',
		ha_base_domain: '',
		ha_base_url: '',
		vaxpass_toggle: '',
		consent_domain: '',
		consent_url: '',
		pulse_wellness_domain: '',
		pulse_wellness_url: '',
		enable_osano:''
	};

	/**
	 * Environment Config Constructor
	 * @param appConfig - App config service
	 */
	constructor(private appConfig: AppConfigService) { }

	/** After reading from assets/environment.json file, updating environment variable */
	getEnvironment() {
		this.ENV = Object.assign(this.ENV, this.appConfig.getConfig());
		const environment: ENV = {
			AWS_REGION: this.ENV.aws_region,
			BASE_DOMAIN: this.ENV.base_domain,
			DASHBOARD_BASE_URL: this.ENV.base_url,
			EPMS_PROXY_BASE_URL: this.ENV.base_url,
			CLIENT_ID: this.ENV.cognito_client_id,
			USER_POOL_ID: this.ENV.cognito_user_pool_id,
			IDENTITY_POOL_ID: this.ENV.cognito_identity_pool_id,
			LOGINS_URL: this.ENV.cognito_login_url,
			ANALYTICS_AUTH_BUCKET: this.ENV.analytics_auth_events_bucket,
			ANALYTICS_UNAUTH_BUCKET: this.ENV.analytics_unauth_events_bucket,
			UNDER_MAINTENANCE: this.ENV.under_maintenance,
			MAINTENANCE_MESSAGE: this.ENV.maintenance_message,
			S3_BUCKET_NAME: this.ENV.s3_bucket_name,
			SSN_ASSOCIATED_CLIENTS: this.ENV.ssn_associated_clients,
			S3_FEED_IMAGE_BUCKET: this.ENV.s3_feed_image_bucket,
			ENV_KEY: this.ENV.env_key,
			DASHBOARD_STATIC_CONTENT_BUCKET: this.ENV.dashboard_static_content_bucket,
			AWS_LOCATION_SERVICE_MAP_NAME: this.ENV.aws_location_service_map_name,
			AWS_LOCATION_SERVICE_PLACE_INDEX_NAME: this.ENV.aws_location_service_place_index_name,
			S3_UI_LOGS_BUCKET: this.ENV.s3_ui_logs_bucket,
			COMCAST_CLIENTS: this.ENV.comcast_clients,
			CATALENT_CLIENTS: this.ENV.catalent_clients,
			HA_BASE_DOMAIN: this.ENV.ha_base_domain,
			HA_BASE_URL: this.ENV.ha_base_url,
			VAXPASS_TOGGLE: this.ENV.vaxpass_toggle,
			CONSENT_DOMAIN: this.ENV.consent_domain,
			CONSENT_URL: this.ENV.consent_url,
			PULSE_WELLNESS_DOMAIN: this.ENV.pulse_wellness_domain,
			PULSE_WELLNESS_URL: this.ENV.pulse_wellness_url,
			ENABLE_OSANO: this.ENV.enable_osano,
		};
		return environment;
	}
}
