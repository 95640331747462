import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Data Carrier related services
 */
@Injectable({
	providedIn: 'root',
})
export class DataCarrierService {
	/** all channel keys are indexed under this master DATA_CARRIER_KEY */
	private DATA_CARRIER_KEY = 'DATA_CARRIER_LIST';
	/** key value pair */
	private channel = {};
	/** Observable which is returning the values stored in data carrier */
	private channelEventEmitter = new BehaviorSubject(this.channel);

	/**
	 * Adding value to data carrier
	 * @param key - Key name of value
	 * @param value - Value to hold
	 */
	public pushValue(key: string, value: any): void {
		this.channel[key] = value;
		this.channelEventEmitter.next(this.channel);
		sessionStorage.setItem(key, this.encodeValue(value));
		this.updateDataCarrierList(key);
	}

	/**
	 * returning the channel
	 */
	public getChannel(): BehaviorSubject<any> {
		const value = this.channelEventEmitter.getValue();

		const dataCarrierValue = sessionStorage.getItem(this.DATA_CARRIER_KEY);
		if (dataCarrierValue) {
			const storedValues = new Set(this.decodeValue(dataCarrierValue));
			/**
			 * Check whether any value missed values from the channel,
			 * Yes - reload the values from session storage
			 */
			if (Object.keys(value).length < storedValues.size && storedValues.size > 0) {
				let temp: any;
				storedValues.forEach((element: any) => {
					temp = sessionStorage.getItem(element);
					if (temp) {
						this.channel[element] = this.decodeValue(temp);
						this.channelEventEmitter.next(this.channel);
					}
				});
			}
		}
		return this.channelEventEmitter;
	}

	/**
	 * Update the data carrier by removing all the existing values
	 * and adding new value
	 * @param key - Key of the value
	 * @param value - New Value
	 */
	public update(key: string, value: any): any {
		const data = this.channelEventEmitter.getValue();

		this.reset();
		data[key] = value;
		this.channelEventEmitter.next(data);
		this.updateDataCarrierList(key);
		sessionStorage.setItem(key, this.encodeValue(value));
		return this.channelEventEmitter.getValue();
	}

	/**
	 * Reset the data carrier to empty
	 */
	public reset(): void {
		this.channel = {};
		this.channelEventEmitter = new BehaviorSubject({});
		this.clearStorage();
	}

	/**
	 * Keep track of the variables stored in data carrier
	 * if new value adding to the carrier, adding the key to the list
	 */
	public updateDataCarrierList(key: string): void {
		const dataCarrierList = this.getFromStorage();
		dataCarrierList.add(key);
		sessionStorage.setItem(this.DATA_CARRIER_KEY, btoa(JSON.stringify(Array.from(dataCarrierList))));
	}

	/**
	 * Get the list of keys stored in data carrier from session storage
	 * if empty return and empty array
	 */
	private getFromStorage(): any {
		const storage = sessionStorage.getItem(this.DATA_CARRIER_KEY);

		if (storage) {
			return new Set(this.decodeValue(storage));
		} else {
			return new Set([]);
		}
	}

	/**
	 * Clearing the all the values stored in session storage
	 */
	private clearStorage(): void {
		const dataCarrierList = this.getFromStorage();
		dataCarrierList.forEach((element: any) => {
			sessionStorage.removeItem(element);
		});
		sessionStorage.setItem(this.DATA_CARRIER_KEY, btoa(JSON.stringify(Array.from(new Set()))));
	}

	/**
	 * Encode value before storing session storage
	 * @param value - value to encode
	 */
	encodeValue(value: any): string {
		return btoa(unescape(encodeURIComponent(JSON.stringify(value))));
	}

	/**
	 * decode value after retrieving from session storage
	 * @param value - value to decode
	 */
	decodeValue(value: string): any {
		return JSON.parse(decodeURIComponent(escape(atob(value))));
	}
}
