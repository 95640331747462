import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../modules/shared/shared.module';
import { MigrationComponent } from './migration.component';
import { MigrationPasswordComponent } from './migration-password/migration-password.component';
import { MigrationEmailEntryComponent } from './migraton-email-entry/migration-email-entry.component';
import { MigrationRoutingModule } from './migration-routing.module';
import { MaterialModule } from 'src/app/modules/shared/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MigrationNotificationModalComponent } from './migration-notification-modal/migration-notification-modal.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

@NgModule({
	imports: [
		CommonModule,
		MigrationRoutingModule,
		SharedModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		MatPasswordStrengthModule,
	],
	declarations: [
		MigrationComponent,
		MigrationPasswordComponent,
		MigrationEmailEntryComponent,
		MigrationNotificationModalComponent,
	],
	exports: [MigrationNotificationModalComponent],
	providers: [],
})
export class MigrationModule {}
