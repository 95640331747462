<app-white-overlay
	(action)="actionFunction($event)"
	[title]="'Consent Permission'"
	[button1]="'Continue'"
	[button1Disabled]="!isConsented"
	[showCloseIcon]="true"
	[closeButtonText]="'Cancel'"
	[gaButton1]="{
		eventCategory: 'Button',
		eventAction: 'Overlay-Continue-Button',
		eventLabel: 'Overlay - Consent accepted'
	}"
	[gaCloseIcon]="{
		eventCategory: 'Button',
		eventAction: 'Overlay-Close-Icon',
		eventLabel: 'Overlay - Consent closed from close icon'
	}"
	[gaCloseButtonText]="{
		eventCategory: 'Button',
		eventAction: 'Overlay-Cancel-Button',
		eventLabel: 'Overlay - Consent cancelled from cancel button'
	}">
	<div class="consent-message" [innerHTML]="data.consent"></div>
	<div class="consent-checkbox">
		<mat-checkbox
			[(ngModel)]="isConsented"
			appGoogleAnalytics
			[eventData]="{
				eventCategory: 'checkbox',
				eventAction: 'Overlay-Consent-Checkbox',
				eventLabel: 'Overlay - Consent accept checkbox ' + (isConsented ? 'selected' : 'unselected')
			}">
			<span [translate]="'vax-pass.form.consent-text'">I consent to the above terms</span>
		</mat-checkbox>
	</div>
</app-white-overlay>
