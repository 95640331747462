import { Pipe, PipeTransform } from '@angular/core';

/*
 * Remove HTML tags from string
 * Usage:
 *   value | removeHTMLTags
 */
@Pipe({ name: 'removeHTMLTags' })
export class RemoveHTMLTagsPipe implements PipeTransform {
	transform(value: string): string {
		if (_validData(value) === true) {
			return (value || '') && value.replace(/<[^>]*>/g, '');
		}
		return '';
	}
}

/*
 * Remove non-word characters from strings
 * Usage:
 *   value | sanitizeString
 */
/** Sanitize String Pipe */
@Pipe({ name: 'sanitizeString' })
export class SanitizeStringPipe implements PipeTransform {
	transform(value: string): string {
		if (_validData(value) === true) {
			const string = (value || '') && value.replace(/ /g, '-');
			// remove non-word characters
			return (string || '') && string.replace(/\W/g, '');
		}
		return '';
	}
}

/** get keys as array from JSON */
@Pipe({ name: 'getKeys' })
export class GetKeysPipe implements PipeTransform {
	transform(object: any): Array<any> {
		return object ? Object.keys(object) || [] : [];
	}
}

/** Mask phone pipe */
@Pipe({ name: 'maskPhone' })
export class MaskPhonePipe implements PipeTransform {
	transform(phone: string): string {
		if (_validData(phone) === true) {
			phone = phone.replace(/\D+/g, '');
			const maskChar = '.';
			const maskedPhone = phone.slice(0, 3) + maskChar + phone.slice(3, 6) + maskChar + phone.slice(6, 10);
			return maskedPhone;
		}
		return '';
	}
}

/** Mask SSN */
@Pipe({
	name: 'maskSSN',
})
export class MaskSSNPipe implements PipeTransform {
	transform(value: string, showMask: boolean): string {
		if (_validData(value) === true) {
			value = String(value);
			if (value.length < 9) {
				return value;
			}
			return 'XXX-XX-' + value.substr(value.length - 4, value.length);
		} else {
			return '';
		}
	}
}
/** Slice first letter of string */
@Pipe({ name: 'truncateLastName' })
export class TruncateLastNamePipe implements PipeTransform {
	transform(value: string): string {
		if (_validData(value) === true) {
			const string = (value || '') && value.slice(0, 1);
			// remove non-word characters
			return ` ${string.toUpperCase()}`;
		}
		return '';
	}
}
/** Checks if value undefined or null */
function _validData(val) {
	return val !== undefined && val !== null;
}
