import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Environment } from '../../../config/environment';

/** Maintenance Component used for showing maintenance message on the application */
@Component({
	selector: 'app-maintenance',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnDestroy {
	/**
	 * Maintenance Component Constructor
	 * @param environment - Environment Config
	 * @param router - Angular Router
	 */
	constructor(
		private environment: Environment,
		private router: Router
	) {
		document.body.classList.add('maintenance');
		/** if @UNDER_MAINTENANCE variable has set to true in Environment, the maintenance page will show,
		 * otherwise it will redirect to login page
		 * */
		if (this.environment.getEnvironment().UNDER_MAINTENANCE !== 'true') {
			this.router.navigate(['/']);
		}
	}

	/** On Component destroy */
	ngOnDestroy() {
		document.body.classList.remove('maintenance');
	}
}
