import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialComponent } from './tutorial.component';
import { MaterialModule } from '../shared/material/material.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	imports: [CommonModule, MaterialModule, SharedModule],
	declarations: [TutorialComponent],
	exports: [TutorialComponent],
})
export class TutorialModule {}
