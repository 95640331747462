<button class="close" mat-button (click)="close()">&#10005;</button>
<div class="vid-container" id="video">
	<!-- <video
      controls
      loop
      autoplay
      class="video"
      style="width: 100%;"
      [src]="videoUrl"
      type="video/mp4">
    </video> -->
	<iframe
		[src]="videoUrl"
		style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
		frameborder="0"
		allow="autoplay; fullscreen"
		allowfullscreen>
	</iframe>
</div>
