import { Component, Input } from '@angular/core';
import { SpinnerHideComponent } from 'src/app/modules/shared/spinner-hide/spinner.component';
import { VaxPassService } from 'src/app/services/dashboard/vax-pass.service';
import { MatDialog } from '@angular/material/dialog';
import { WordPressOverlayComponent } from 'src/app/modules/shared/modals/word-press-overlay/word-press-overlay.component';

@Component({
	selector: 'app-vax-pass-card',
	templateUrl: './vax-pass-card.component.html',
	styleUrls: ['./vax-pass-card.component.scss'],
})
export class VaxPassCardComponent {
	@Input() profile: any;

	vaxpassFAQUrl = 'https://ehe.health/vaxstatusfaq/';
	constructor(
		private vaxPassService: VaxPassService,
		private spinner: SpinnerHideComponent,
		private readonly dialog: MatDialog
	) {}

	showFaq(event: MouseEvent) {
		if (event && event.preventDefault) {
			event.preventDefault();
		}
		this.dialog.open(WordPressOverlayComponent, {
			width: '100vw',
			data: { url: this.vaxpassFAQUrl },
			autoFocus: false,
			panelClass: 'wp-overlay',
		});
	}
}
