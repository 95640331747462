import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { VaxPassService } from '../../../../services/dashboard/vax-pass.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MemberInfoConfirmationModalComponent } from '../../../shared/member-info-confirmation-modal/member-info-confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DataCarrierService } from '../../../../services/core/data-carrier.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, Subscription } from 'rxjs';
import { AnalyticsBean } from 'src/app/beans/analytics/analytics-module.bean';
import { JwtHelper } from '../../../../services/core/jwt-helper.service';
import { Environment } from '../../../../config/environment';
import { ServiceRouter } from '../../../../services/core/http-service-router.service';
import { Config } from '../../../../config/config';
import { METHOD_TYPE } from '../../../../constants/service.constants';
import { SpinnerComponent } from '../../../../modules/shared/spinner/spinner.component';
import { WordPressOverlayComponent } from '../../../shared/modals/word-press-overlay/word-press-overlay.component';

@Component({
	selector: 'app-vaxpass-consent-form',
	templateUrl: './vaxpass-consent-form.component.html',
	styleUrls: ['./vaxpass-consent-form.component.scss'],
})
export class VaxpassConsentFormComponent implements OnInit {
	loader = false;
	analyticsData: AnalyticsBean;
	/* consent form group */
	consentForm: FormGroup;
	/* holds consent language */
	consent: any;
	/* holds user profile info */
	profile: any;
	errorMessage = '';
	step2 = true;
	error = false;
	enableNextButton = false;
	subscriptions: Subscription[] = [];
	maxRetry = 3;
	attemptedRetries = 0;
	@Output() cancel = new EventEmitter<boolean>();
	states = [];
	vaxpassFAQUrl = 'https://ehe.health/vaxstatusfaq/';

	constructor(
		private vaxPassService: VaxPassService,
		private dialog: MatDialog,
		private router: Router,
		private dataCarrierService: DataCarrierService,
		private jwtService: JwtHelper,
		private serviceRouter: ServiceRouter,
		private config: Config,
		private environment: Environment,
		private spinner: SpinnerComponent
	) {
		this.analyticsData = new AnalyticsBean();
		this.analyticsData.componentName = 'vax-pass';
		this.analyticsData.pageUrl = '/vaxstatus';
	}

	ngOnInit() {
		this.spinner.startLoader();
		this.profile = this.dataCarrierService.getChannel().getValue().profile.profileInfo;

		forkJoin({
			email: this.getEmail(),
			consent: this.vaxPassService.getConsentMessage(),
			states: this.vaxPassService.getFullStateNames(),
		}).subscribe(res => {
			res.email.forEach(email => {
				if (email.primary) {
					this.profile.email = email.email;
				}
			});
			this.states = res.states;
			this.consent = atob(res.consent.data);
			this.spinner.stopLoader();
		});

		this.consentForm = new FormGroup({
			isConsented: new FormControl('', [Validators.required]),
			vaccineState: new FormControl('', Validators.compose([Validators.required])),
		});
	}

	/* overlay opens where user can edit their details */
	editProfileInfo() {
		const dialogRef = this.dialog.open(MemberInfoConfirmationModalComponent, {
			width: '750px',
			height: '570px',
			disableClose: true,
			panelClass: 'overlay-no-padding',
			data: {
				editInfo: true,
				states: this.states,
			},
		});
		dialogRef.componentInstance.editInfo = true;
		dialogRef.afterClosed().subscribe(_res => {
			this.spinner.startLoader();
			this.profile = this.dataCarrierService.getChannel().getValue().profile.profileInfo;
			this.getEmail().subscribe(res => {
				res.forEach(email => {
					if (email.primary) {
						this.profile.email = email.email;
					}
				});
				this.spinner.stopLoader();
			});
		});
	}

	refreshProfile() {
		this.loader = true;
		this.errorMessage = '';
		this.vaxPassService.getPatientCovidRecord().subscribe(
			response => {
				this.processResponse(response);
			},
			err => {
				this.processError(err);
			}
		);
	}

	processResponse(response) {
		if (response.message === 'AWAITING_RESULTS') {
			const _context = this;
			this.loader = true;
			if (this.attemptedRetries < this.maxRetry) {
				setTimeout(() => {
					_context.attemptedRetries += 1;
					this.refreshProfile();
				}, 10000);
			} else {
				this.loader = false;
				this.error = true;
				this.step2 = true;
				this.errorMessage =
					'The retrieval of your vaccination record is taking longer than expected. It might take a minute or two to complete.';
			}
		} else if (response.message === 'RECORD_NOT_FOUND' || response.status === 'NOT_VACCINATED') {
			this.step2 = true;
			this.error = true;
			let states;
			if (response.states && response.states.vaccineStates) {
				states = response.states.vaccineStates[response.states.vaccineStates.length - 1];
			}
			this.errorMessage =
				'We located your record with the state of ' +
				states +
				', but it does not include a COVID-19 vaccination event. If your vaccination was recent, please check back in a few days. Sorry for the inconvenience. ';
			this.loader = false;
		} else if (response.status === 'UNKNOWN') {
			this.error = true;
			this.step2 = true;
		} else {
			this.router.navigate(['/vaxstatus'], {
				queryParams: {
					params: btoa(JSON.stringify({ profile: response })),
				},
			});
			this.loader = false;
		}
	}

	processError(err) {
		console.error(`[${VaxpassConsentFormComponent.name}][${this.processError.name}]`, err);
		let states = '';
		if (err.data && err.data.states) {
			const matches = err.data.states.match(/\[(.*?)\]/);
			if (matches) {
				states = matches[1];
			}
		}
		this.step2 = true;
		this.error = true;
		if (err.data && err.data.vaxpassStatus == '422') {
			this.errorMessage =
				'We are currently not able to access vaccination records in ' +
				states +
				". We are actively working to connect to the state's vaccination registry. Please check back in a week or so. Sorry for the inconvenience.";
		} else if (err.data && err.data.vaxpassStatus == '404') {
			this.errorMessage =
				'We were not able to locate your vaccination record in the state of ' +
				states +
				'. Please check your information and try again. If you continue to experience difficulties retrieving your vaccination record, there may be a discrepancy between the state’s vaccination registry and the information you have provided, or your vaccination event has not been processed yet.  States vary in the time it takes to update their vaccination records. Please check back in a week or so. Sorry for the inconvenience.';
		} else {
			this.errorMessage = 'There was a problem retrieving your vaccination record. Please try again later.';
		}
		this.loader = false;
	}

	submitConsent() {
		this.analyticsData.id = 'Next';
		const formValue = this.consentForm.getRawValue();
		// reformatting cellphone and dob
		const cellPhone = this.profile.cellPhone.replace(/\D+/g, '').replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3');
		const dateOfBirth = moment(this.profile.dateOfBirth).format('YYYY-MM-DD');
		const body = {
			firstName: this.profile.firstName,
			lastName: this.profile.lastName,
			email: this.profile.email,
			phone: cellPhone,
			addressLine1: this.profile.address,
			addressLine2: this.profile.address2,
			city: this.profile.city,
			state: this.profile.state,
			zip: this.profile.zipCode,
			gender: this.profile.gender,
			dob: dateOfBirth,
		};
		const vaccineState = formValue.vaccineState['key'];
		this.loader = true;
		this.step2 = false;

		this.vaxPassService.sendPatientInfo({ body, vaccineState }).subscribe(
			response => {
				this.processResponse(response);
			},
			err => {
				// show error message and ask to try again
				this.processError(err);
			}
		);
	}

	/* displaying original vaxpass screen if user aborts */
	cancelConsentForm() {
		this.analyticsData.id = 'Cancel';
		this.cancel.emit(true);
	}

	consentChanged(event) {
		this.enableNextButton = event.checked;
	}

	getEmail() {
		const epmsPID = this.jwtService.getEpmsId();
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.profileEmailsApi.baseUrl + `/${epmsPID}/emails`,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	showFaq(event: MouseEvent) {
		if (event && event.preventDefault) {
			event.preventDefault();
		}
		this.dialog.open(WordPressOverlayComponent, {
			width: '100vw',
			data: { url: this.vaxpassFAQUrl },
			autoFocus: false,
			panelClass: 'wp-overlay',
		});
	}
}
