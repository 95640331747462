<div class="maintenance-module">
	<div class="m-header">
		<div class="logo">
			<svg class="icon" alt="EHE">
				<use xlink:href="#ehe1913">
					<svg class="sprite" id="ehe1913" viewBox="0 0 211.2 67.13" width="100%" height="100%">
						<polygon
							class="sta"
							points="0 0.3 0 66.53 22.48 66.53 22.48 61 5.87 61 5.87 35.95 20.86 35.95 20.86 30.41 5.87 30.41 5.87 5.87 22.48 5.87 22.48 0.3 0 0.3"></polygon>
						<polygon
							class="sta"
							points="62.63 0.3 62.63 66.53 85.1 66.53 85.1 61 68.5 61 68.5 35.95 83.5 35.95 83.5 30.41 68.5 30.41 68.5 5.87 85.1 5.87 85.1 0.3 62.63 0.3"></polygon>
						<polygon
							class="sta"
							points="50.59 0.3 50.59 30.41 33.76 30.41 33.76 0.3 27.84 0.3 27.84 66.53 33.76 66.53 33.76 35.95 50.59 35.95 50.59 66.53 56.52 66.53 56.52 0.3 50.59 0.3"></polygon>
						<path
							class="sta"
							d="M116.69,21.74V2.18H123v2h-4.15v6.52h3v1.91h-3v7.19H123v2Z"
							transform="translate(-2.58 -1.88)"></path>
						<path
							class="sta"
							d="M128.47,22a3.59,3.59,0,0,1-3.75-3.85V16h2.07V18.1c0,1.29.62,2.14,1.66,2.14s1.71-.76,1.71-2c0-1.88-.71-3.47-2.25-5.44-1.39-1.76-3-4.37-3-6.67s1.22-4.26,3.73-4.26c2.13,0,3.53,1.59,3.53,3.88V8.08h-2V5.94c0-1.35-.5-2.15-1.57-2.15S127,4.44,127,5.59c0,2.08.74,3.4,2.48,5.69s2.78,4.26,2.78,6.5C132.23,20.89,130.28,22,128.47,22Z"
							transform="translate(-2.58 -1.88)"></path>
						<path
							class="sta"
							d="M138.18,4.18V21.74h-2.1V4.18h-2.63v-2h7.28v2Z"
							transform="translate(-2.58 -1.88)"></path>
						<path
							class="sta"
							d="M147.49,21.74,147,17.51h-2.72l-.62,4.23h-2l3-19.56h2.22l2.87,19.56ZM145.65,6.82l-1.15,8.72h2.19Z"
							transform="translate(-2.58 -1.88)"></path>
						<path
							class="sta"
							d="M156,21.74h-4V2.18h3.91c1.84,0,3.26.91,3.26,3.52V8.93c0,1.44-.48,2.44-1.66,2.82,1.27.39,1.72,1.41,1.72,2.85v3.62C159.26,20.83,157.84,21.74,156,21.74Zm1.1-16.18c0-.91-.39-1.38-1.16-1.38h-1.75V10.9h1.51c.89,0,1.4-.58,1.4-1.58Zm.05,8.72c0-1-.53-1.59-1.39-1.59h-1.57v7H156c.77,0,1.18-.47,1.18-1.38Z"
							transform="translate(-2.58 -1.88)"></path>
						<path class="sta" d="M161.64,21.74V2.18h2.13V19.72h4v2Z" transform="translate(-2.58 -1.88)"></path>
						<path class="sta" d="M170.3,21.74V2.18h2.16V21.74Z" transform="translate(-2.58 -1.88)"></path>
						<path
							class="sta"
							d="M179.7,22a3.59,3.59,0,0,1-3.76-3.85V16H178V18.1c0,1.29.62,2.14,1.66,2.14s1.72-.76,1.72-2c0-1.88-.71-3.47-2.25-5.44-1.39-1.76-3-4.37-3-6.67s1.21-4.26,3.73-4.26c2.13,0,3.52,1.59,3.52,3.88V8.08h-2V5.94c0-1.35-.51-2.15-1.57-2.15s-1.6.65-1.6,1.8c0,2.08.74,3.4,2.49,5.69s2.78,4.26,2.78,6.5C183.46,20.89,181.51,22,179.7,22Z"
							transform="translate(-2.58 -1.88)"></path>
						<path
							class="sta"
							d="M191.82,21.74V13h-3.34v8.72h-2.17V2.18h2.17v8.87h3.34V2.18h2.1V21.74Z"
							transform="translate(-2.58 -1.88)"></path>
						<path
							class="sta"
							d="M197.19,21.74V2.18h6.28v2h-4.15v6.52h3v1.91h-3v7.19h4.15v2Z"
							transform="translate(-2.58 -1.88)"></path>
						<path
							class="sta"
							d="M210.17,21.74h-3.85V2.18h3.85c2.55,0,3.61,1.58,3.61,4.32V17.42C213.78,20.16,212.72,21.74,210.17,21.74Zm1.48-15.39c0-1.47-.44-2.12-1.54-2.12h-1.63V19.69h1.63c1.1,0,1.54-.65,1.54-2.09Z"
							transform="translate(-2.58 -1.88)"></path>
						<path class="sta" d="M116.69,68.39V26.59h4.62v41.8Z" transform="translate(-2.58 -1.88)"></path>
						<path class="sta" d="M156.41,68.39V26.59H161v41.8Z" transform="translate(-2.58 -1.88)"></path>
						<path class="sta" d="M188,68.39V26.59h4.62v41.8Z" transform="translate(-2.58 -1.88)"></path>
						<path
							class="sta"
							d="M138.67,68.39l-7.14-21.16-2-6.71V68.39h-4.3V26.59h4.11l6.52,21.46,1.89,6.78V26.59H142v41.8Z"
							transform="translate(-2.58 -1.88)"></path>
						<path
							class="sta"
							d="M174.33,69a7,7,0,0,1-7.27-7.34v-5h3.73v4.77c0,2.14,1.39,3.39,3.61,3.39a3.07,3.07,0,0,0,3.35-3.39V49.87a6.06,6.06,0,0,1-5.25,2.45c-3.42,0-5.44-2.64-5.44-6V33.93c0-4.64,2.78-8,7.4-8s7.46,3.33,7.46,8V61C181.92,65.81,179,69,174.33,69Zm3.42-35.65a3.07,3.07,0,0,0-3.29-3.26,3,3,0,0,0-3.29,3.08V45.79c0,1.7,1.08,3,3.29,3a3,3,0,0,0,3.29-3Z"
							transform="translate(-2.58 -1.88)"></path>
						<path
							class="sta"
							d="M206.7,69c-4.81,0-7-3.2-7-7.4V56.27h3.73v5.27c0,2.2.89,3.33,3,3.33s3.1-1.13,3.1-3.14V51.13a1.93,1.93,0,0,0-2.21-2.08h-2.72V45.41h2.72c1.64,0,2.21-.87,2.21-2.07V33.43c0-2.07-.95-3.33-3.1-3.33s-3,1.26-3,3.45v5.21h-3.73V33.43c0-4.27,2-7.47,7-7.47,4.36,0,7.08,3.14,7.08,7.53v9.16a4.54,4.54,0,0,1-3.29,4.52,4.4,4.4,0,0,1,3.29,4.4v9.91C213.78,65.75,211.06,69,206.7,69Z"
							transform="translate(-2.58 -1.88)"></path>
						<rect class="sta" x="99.23" y="0.3" width="0.75" height="66.23"></rect>
					</svg>
				</use>
			</svg>
		</div>
	</div>

	<div class="m-container">
		<h1>We’ll Be Right Back!</h1>
		<p>
			Stretching is essential to fitness. Scheduled maintenance also keeps MyEHE running smoothly! Fortunately, ours
			will be finished shortly and we can continue helping you.
		</p>

		<p>
			We apologize for the inconvenience. In the meantime, you can explore our
			<a href="https://www.ehe.health/blog">blog</a> for tips on healthier nutrition, exercise, and more.
		</p>

		<p>For immediate assistance, please call us at 800.362.8671.</p>
	</div>
</div>
