import { Component, OnInit } from '@angular/core';

/**
 * Base forgot password component
 *
 * The forgot password component will be displayed inside this component and
 * this will not be there in the case of Marketo
 */
@Component({
	selector: 'app-base-forgot-password',
	templateUrl: './base-forgot-password.component.html',
})
export class BaseForgotPasswordComponent {}
