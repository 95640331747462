import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';

@Component({
	selector: 'app-white-overlay',
	templateUrl: './white-overlay.component.html',
	styleUrls: ['./white-overlay.component.scss'],
})
export class WhiteOverlayComponent {
	/** Submit button Name */
	submitButton: string = 'SUBMIT';
	/** Modal Title */
	@Input() title: string;
	/** Denote whether the close icon has to show on modal */
	@Input() showCloseIcon: boolean;
	/** google analytics data for Close Icon */
	@Input() gaCloseIcon: any;
	/** holds value of first button */
	@Input() button1: string;
	/** google analytics data for first button */
	@Input() gaButton1: any;
	/** Denote whether the first button is disabled or not */
	@Input() button1Disabled: boolean = false;
	/** holds value of second button */
	@Input() closeButton: string;
	/** google analytics data for Close button */
	@Input() gaCloseButton: any;
	/** holds value of second button - if it should be displayed as text instead of button */
	@Input() closeButtonText: string;
	/** google analytics data for Close button text */
	@Input() gaCloseButtonText: any;
	/** Denote whether the header text should be left aligned */
	@Input() headerLeft: boolean = false;
	/** emits output event */
	@Output() action: EventEmitter<string>;

	/**
	 * White overlay modal constructor
	 * @param cdr Angular Change detection reference
	 */
	constructor() {
		this.action = new EventEmitter<string>();
	}

	/** Modal submit button
	 * *sending action as 'CLOSE' to parent component
	 */
	submit() {
		this.action.emit('SUBMIT');
	}

	/** Modal close button,
	 * sending action as 'CLOSE' to parent component */
	close() {
		this.action.emit('CLOSE');
	}
}
