import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { Config } from '../../../config/config';
import { PrintService } from 'src/app/services/core/print.service';
import { AnalyticsBean } from 'src/app/beans/analytics/analytics-module.bean';
import { AppointmentsService } from 'src/app/services/scheduling/appointment/appointments.service';
import { VaxPassService } from 'src/app/services/dashboard/vax-pass.service';
import { ConsentOnlyFormComponent } from '../vax-pass/vax-pass-card/consent-only-form/consent-only-form.component';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { ENgxPrintComponent } from '../../shared/ngx-print-block/e-ngx-print.component';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import * as moment from 'moment';

/**
 * Immunization component
 */
@Component({
	selector: 'app-immunization',
	templateUrl: './immunization.component.html',
	styleUrls: ['./immunization.component.scss'],
})
export class ImmunizationComponent implements OnInit, OnDestroy {
	/** User's vaccine profile */
	@Input() profile: any;
	/** MatSort ViewChild */
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	/** Print ViewChild */
	@ViewChild('print2', { static: false }) printComponent: ENgxPrintComponent;

	printCSS = [this.config.print.css.filePath];
	printStyle; // = `th, td {color: blue !important;}`;
	/** Immunization Data */
	immunizationData: any[] = [];
	/** Maximum number of retries to get Vaccine Profile */
	maxRetry = 3;
	/** Attempted number of retries to get Vaccine Profile */
	attemptedRetries = 0;
	xpandStatus = false;
	// sortEvents$: Observable<Sort>;
	tableContent = '';
	/** Analytics bean */
	analyticsData: AnalyticsBean;
	/** Upcoming appointment data */
	upcomingAppointmentData: any = '';
	/** Maximum number of retries for generating image in vaxPass pdf before continuing with empty data */
	pdfImageGenerationRetryLimit: 3;
	/** Upcoming travel appointment flag */
	upcomingTravelAppt = false;
	serviceCallsCount = 0;
	isVaccinationsCalled = false;
	/** COVID-19 Options */
	covid19VaccineOptions = {
		showQRCodesAndClearTile: false,
		showPdfDownloadLink: false,
	};
	private datePipe = new DatePipe('en-US');
	private titleCase = new TitleCasePipe();
	subscriptions: Subscription[] = [];
	/**
	 * Immunization constructor
	 * @param dialog - Material Dialog object
	 * @param serviceRouter - Service Router
	 * @param environment - Environment configuration
	 * @param spinner - Spinner
	 * @param appointmentService - Appointment Service
	 * @param config - Config
	 * @param printService - Print service
	 */
	constructor(
		public dialog: MatDialog,
		private spinner: SpinnerComponent,
		private appointmentService: AppointmentsService,
		private vaxPassService: VaxPassService,
		private config: Config,
		private printService: PrintService
	) {
		this.analyticsData = new AnalyticsBean();
		this.analyticsData.componentName = 'immunization';
		this.analyticsData.pageUrl = '/wellness/immunization';
	}

	/**
	 * Component initialization
	 */
	ngOnInit() {
		if (this.sort) {
			this.sort.disableClear = true;
		}
		// this.sortEvents$ = fromMatSort(this.sort);
		this.spinner.startLoader();
		this.getImmunization();
		this.getUpcomingAppointments();
	}

	/** Constructing table for printing immunization data */
	getTableContent() {
		this.spinner.startLoader();
		this.tableContent = '';
		this.immunizationData.forEach(data => {
			this.tableContent =
				this.tableContent +
				'<tr>' +
				'<td>' +
				data.name +
				'</td>' +
				'<td>' +
				data.date +
				'</td>' +
				'<td>' +
				data.source +
				'</td>' +
				'</tr> ';
		});
		this.spinner.stopLoader();
		return this.tableContent;
	}

	/** Custom print function to print immunization details in table view */
	customPrint() {
		this.analyticsData.id = 'Print immunization details';
		this.analyticsData.redirectedTo = '/wellness/immunization';
		this.analyticsData.placement = 'Immunization';
		const tableHeader = '<th>Type</th><th>Date</th><th>Source</th>';
		const tableContent = this.getTableContent();
		this.printService.printDocument(tableHeader, tableContent, this.printComponent, 'IMMUNIZATION LIST');
	}

	/** Retrieve Immunization data */
	getImmunization() {
		this.spinner.startLoader();
		this.serviceCallsCount++;
		this.subscriptions.push(
			this.vaxPassService.getImmunization().subscribe(
				res => {
					// const rows$ = of(res);
					// this.totalRows$ = rows$.pipe(map(rows => rows.length));
					// this.displayedRows$ = rows$.pipe(sortRows(this.sortEvents$));
					if (res.length > 0) {
						this.immunizationData.push(...res);
					}
					this.afterServiceCalls();
				},
				err => this.afterServiceCalls()
			)
		);
	}

	/** Retrieve Vaccination data */
	getVaccinations() {
		this.spinner.startLoader();
		this.serviceCallsCount++;
		this.subscriptions.push(
			this.vaxPassService.getVaccinations().subscribe(
				(res: any[] | undefined | null) => {
					if (res && res.length > 0) {
						// COVID-19 Vaccine Records when fully Vaccinated
						if (this.profile.status === 'FULLY_VACCINATED') {
							const covidVax = {
								name: this.getCovid19VaccineName(),
								date: this.getCovid19VaccineDates(),
								source: 'State Record',
							};
							this.immunizationData.push(covidVax);
							const el = document.querySelector('section');
							el.classList.remove('general-wrapper', 'enable-wellness');
							el.style.padding = '0px 0 70px 0';
						}
						// Other Vaccine Records when fully Vaccinated
						res = res
							.filter(item => item.name !== this.getCovid19VaccineName())
							.map(x => {
								return {
									name: x.name,
									date: moment(x.administrationDate).format('MM/DD/YYYY'),
									source: 'State Registry',
								};
							});
						this.immunizationData.push(...res);
					}
					this.afterServiceCalls();
				},
				err => this.afterServiceCalls()
			)
		);
	}

	/* To be called in the complete() callback of a subscription,
	 * so that when all the service calls are completed,
	 * loader can be removed
	 */
	afterServiceCalls() {
		this.serviceCallsCount--;
		if (!this.serviceCallsCount) {
			this.spinner.stopLoader();
			this.sortData({
				active: 'date',
				direction: 'desc',
			});
		}
	}

	updateVaccinesList() {
		if (!this.isVaccinationsCalled && this.profile && this.profile.consentAccepted) {
			this.isVaccinationsCalled = true;
			this.getVaccinations();
		}
	}

	/** Check if there are ant records for Immunization */
	isRecords() {
		return this.immunizationData && this.immunizationData.length;
	}

	/* addImmunization(): void {
		const dialogRef = this.dialog.open(AddImmunizationComponent, {
			width: '512px',
		});
		dialogRef.afterClosed().subscribe(result => {
		});
	}

	deleteImmunization(): void {
		const dialogRef = this.dialog.open(DeleteImmunizationComponent, {
			width: '512px',
		});
		dialogRef.afterClosed().subscribe(result => {
		});
	} */

	/** Sort Immunization data - type and date columns ascending or descending */
	sortData(sort: Sort) {
		const data = this.immunizationData.slice();
		if (!sort.active || sort.direction === '') {
			this.immunizationData = data;
			return;
		} else {
			this.changeDateFormatForSort();
		}
		this.immunizationData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'name':
					return this.compare(a.name, b.name, isAsc);
				case 'date':
					return this.compare(a.date, b.date, isAsc);
				default:
					return 0;
			}
		});
	}

	/**
	 * Comparing date and name values for sort
	 * @param a
	 * @param b
	 * @param isAsc
	 */
	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	/** If date is either unknown or year alone returning input date, format is changed to 'YYYY/MM/DD' */
	changeDateFormatForSort() {
		this.immunizationData.forEach(data => {
			// If date is either unknown or year alone returning input date
			data.date =
				data.date === 'Date Unknown' || data.date.length === 4 || data.date === this.getCovid19VaccineDates()
					? data.date
					: moment(data.date).format('YYYY/MM/DD');
		});
	}

	/** Retrieving upcoming appointments data to show or hide message:
	 * If 'Travel Consultation and Inoculation' procedure is not booked the following message is displayed
	 * Click here to schedule an EHE travel consultation (available at our clinics in the following locations:
	 * New York, NY; Stamford, CT; Morristown, NJ; Chicago, IL; Houston, TX; Boston, MA; and McLean, VA).
	 */
	getUpcomingAppointments() {
		this.spinner.startLoader();
		this.appointmentService.getUpcomingAppointments().subscribe(
			(res: any) => {
				this.spinner.stopLoader();
				this.upcomingAppointmentData = res;
				if (this.upcomingAppointmentData) {
					const upcomingAppointments = this.upcomingAppointmentData.filter(
						appointment => appointment.procedure.procedureInfo.name === 'Travel Consultation & Inoculation'
					);
					if (upcomingAppointments.length > 0) {
						this.upcomingTravelAppt = true;
					}
				}
			},
			err => {
				this.spinner.stopLoader();
			}
		);
	}

	getQRCodeUrl(value) {
		if (value === 'APPLE') {
			return (this.profile.qrCodeUrl || {})['APPLE'];
		} else {
			return (this.profile.qrCodeUrl || {})['GOOGLE'];
		}
	}

	tryDownloadVaxPassPDF() {
		if (!this.covid19VaccineOptions.showPdfDownloadLink) {
			return;
		}
		if (this.profile.consentAccepted) {
			this.saveVaxPassPDF();
		} else {
			this.spinner.startLoader();
			this.subscriptions.push(
				this.vaxPassService.getConsentMessage().subscribe(consent => {
					this.spinner.stopLoader();
					consent = atob(consent.data);
					this.dialog
						.open(ConsentOnlyFormComponent, {
							width: '600px',
							height: '600px',
							maxWidth: '90vh',
							maxHeight: '90vh',
							autoFocus: false,
							panelClass: 'vaxpass-consent-only-form',
							data: { consent },
						})
						.afterClosed()
						.subscribe(response => {
							if (response) {
								this.vaxPassService.setConsent().subscribe(
									() => {
										this.saveVaxPassPDF();
										this.profile.consentAccepted = true;
									}, error => console.error(`[${ImmunizationComponent.name}][${this.tryDownloadVaxPassPDF.name}]`, error)
								);
							}
						});
				})
			);
		}
	}

	async saveVaxPassPDF() {
		try {
			const vaxCardBGColor = '#0155A5';

			this.spinner.startLoader();
			const pdf = new jspdf({
				orientation: 'p',
				unit: 'mm',
				format: [275, 330],
			});
			this.addOpenSansInJsPDF(pdf);
			this.addProximaNovaInJsPDF(pdf);
			const pName = `${this.profile.firstName} ${this.profile.lastName}`;
			const vaccine = `COVID-19 (${this.titleCase.transform(this.getCovid19VaccineName())})`;
			const vDate = this.getCovid19VaccineDates();
			const x = 15;
			let imgData = await this.getBGFilledImageData('/assets/images/ehe_health_black.png', '#FFFFFF');
			pdf.addImage(imgData, 'JPEG', x, 20, 60, 15);
			let y = 45;
			pdf.setFillColor(255, 246, 229);
			pdf.rect(0, y, 275, 110, 'F');
			{
				// VaxStatus Card
				pdf.setFillColor(vaxCardBGColor);
				pdf.roundedRect(17, 60, 120, 65, 3, 3, 'F');
				imgData = await this.getBGFilledImageData('/assets/images/VaxStatusLogo_white.png', vaxCardBGColor);
				pdf.addImage(imgData, 'PNG', 23, 62.5, 70, 18.5);
				pdf.setFont('ProximaNova', 'Medium');
				pdf.setFontSize(13);
				pdf.setTextColor('#FFFFFF');
				const detailsX1 = 24;
				const detailsX2 = 45;
				let detailsY = 100;
				const detailsYOffset = 6;
				pdf.text('Name:', detailsX1, detailsY);
				pdf.text(pName, detailsX2, detailsY);
				detailsY += detailsYOffset;
				pdf.text('Vaccine:', detailsX1, detailsY);
				pdf.text(vaccine, detailsX2, detailsY);
				detailsY += detailsYOffset;
				pdf.text('Date:', detailsX1, detailsY);
				pdf.text(vDate, detailsX2, detailsY);
			}
			y = y + 132;
			pdf.setFont('OpenSans', 'Bold');
			pdf.setFontSize(40);
			pdf.setTextColor(0, 60, 82);
			pdf.text('VaxStatus: Simple, Secure,', x, y);
			pdf.text('and Everywhere You Go', x, y + 15);
			pdf.setFont('OpenSans', 'Regular');
			pdf.setFontSize(20);
			pdf.setTextColor(0, 0, 0);
			pdf.text('Save or print this page as documentation of your VaxStatus. Your full ', x, y + 25);
			pdf.text('vaccination record is also always accessible at www.myehe.health.', x, y + 35);
			y = y + 35;
			y = y + 25;
			this.setBold(pdf);
			pdf.text('Need to book your EHE Health exam? ', x, y);
			y = y + 15;
			pdf.text('1. ', x, y);
			this.setRegular(pdf);
			pdf.text('Visit www.myehe.health', x + 10, y);
			this.setBold(pdf);
			y = y + 15;
			pdf.text('2. ', x, y);
			this.setRegular(pdf);
			pdf.text('Enter your details', x + 10, y);
			this.setBold(pdf);
			y = y + 15;
			pdf.text('3. ', x, y);
			this.setRegular(pdf);
			pdf.text('Select date and time at a convenient nearby location', x + 10, y);

			pdf.save('vaxpass-card.pdf');
		} catch (e) {
			console.error(`[${ImmunizationComponent.name}][${this.saveVaxPassPDF.name}]`, e);
		} finally {
			this.spinner.stopLoader();
		}
	}

	async getBGFilledImageData(imgSRC: string, bgColor: string, tryNumber: number = 1): Promise<string> {
		const img = await getImageElement(imgSRC);
		img.style.opacity = '0';
		document.body.appendChild(img);
		const canvas = await html2canvas(img);
		canvas.height = img.height;
		canvas.width = img.width;
		const context = canvas.getContext('2d');
		context.fillStyle = bgColor;
		context.fillRect(0, 0, canvas.width, canvas.height);
		context.drawImage(img, 0, 0);
		const imgData = canvas.toDataURL('image/jpeg');
		console.log(imgData);
		if (imgData.startsWith('data:,')) {
			console.log('Image data was empty, retrying...');
			if (tryNumber <= this.pdfImageGenerationRetryLimit) {
				return this.getBGFilledImageData(imgSRC, bgColor, ++tryNumber);
			}
		}
		document.body.removeChild(img);
		return imgData;

		function getImageElement(imgSRC: string): Promise<HTMLImageElement> {
			return new Promise(resolve => {
				const img = new Image();
				img.src = imgSRC;
				img.onload = () => {
					resolve(img);
				};
			});
		}
	}

	setBold(pdf) {
		pdf.setFont('OpenSans', 'Bold');
		pdf.setFontSize(24);
		pdf.setTextColor(0, 60, 82);
	}

	setRegular(pdf) {
		pdf.setFont('OpenSans', 'Regular');
		pdf.setFontSize(22);
		pdf.setTextColor(0, 0, 0);
	}

	addOpenSansInJsPDF(pdf: jspdf) {
		const fontName = 'OpenSans';
		if (!Object.keys(pdf.getFontList()).includes(fontName)) {
			pdf.addFont('/assets/fonts/OpenSans-Regular.ttf', fontName, 'Regular');
			pdf.addFont('/assets/fonts/OpenSans-Bold.ttf', fontName, 'Bold');
		}
	}

	addProximaNovaInJsPDF(pdf: jspdf) {
		const fontName = 'ProximaNova';
		if (!Object.keys(pdf.getFontList()).includes(fontName)) {
			pdf.addFont('/assets/fonts/ProximaNova-Light.ttf', fontName, 'Light');
			pdf.addFont('/assets/fonts/ProximaNova-Regular.ttf', fontName, 'Regular');
			pdf.addFont('/assets/fonts/ProximaNova-Medium.ttf', fontName, 'Medium');
			pdf.addFont('/assets/fonts/ProximaNova-Semibold.ttf', fontName, 'Semibold');
			pdf.addFont('/assets/fonts/ProximaNova-Bold.ttf', fontName, 'Bold');
		}
	}

	/** @returns First Vaccine record's vaccine name from the COVID-19 vaccine list */
	getCovid19VaccineName(): string {
		if (this.profile && this.profile.vaccinations.length > 0) {
			return this.profile.vaccinations[0].name;
		}
	}

	getCovid19VaccineDates() {
		if (this.profile && this.profile.vaccinations.length > 0) {
			return (this.profile.vaccinations as any[])
				.filter(item => item.name === this.getCovid19VaccineName())
				.map(item => this.datePipe.transform(item.administrationDate, 'MMM dd, yyyy'))
				.join(' / ');
		}
		return '';
	}

	/** unsubscribing the subscriptions used */
	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
		this.subscriptions = [];
	}
}
