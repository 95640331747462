import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerComponent } from '../../spinner/spinner.component';
/** Configurable EHE Info Modal Component */
@Component({
	selector: 'app-ehe-info-modal',
	templateUrl: './ehe-info-modal.component.html',
	styleUrls: ['./ehe-info-modal.component.scss'],
})
export class EheInfoModalComponent {
	/** Bitflags for button types */
	static BUTTON_TYPES = {
		YESNO: 1,
		OKAY: 2,
		RESCHEDULE: 4,
	};

	/** Stores the bitflag for the button type(s) to apply */
	buttonType: number;

	/**
	 * EHE Info modal Constructor
	 * @param dialogRef - Mat Dialog Ref
	 * @param data - Mat Dialog Data
	 * @param spinner - Spinner Component
	 */
	constructor(
		private dialogRef: MatDialogRef<EheInfoModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private spinner: SpinnerComponent
	) {
		this.buttonType = this.data.buttonType || EheInfoModalComponent.BUTTON_TYPES.YESNO;
		this.spinner.stopLoader();
	}

	/**
	 * Bitwise check for template to determine if a button is active
	 *
	 * @param key button key to check / YESNO / OKAY / RESCHEDULE
	 */
	isButtonActive(key: string): boolean {
		return !!(
			EheInfoModalComponent.BUTTON_TYPES[key] &&
			// eslint-disable-next-line no-bitwise
			EheInfoModalComponent.BUTTON_TYPES[key] & this.buttonType
		);
	}

	/** Modal buttons callable from template */
	ok(): void {
		this.buttonAction('ok');
	}

	reschedule(): void {
		this.buttonAction('reschedule');
	}

	yes(): void {
		this.buttonAction('YES');
	}

	no(): void {
		this.buttonAction('NO');
	}

	/**
	 * Perform common button actions by passing a value context
	 * to dialogRef.close
	 *
	 * @param text value to pass through output context
	 */
	private buttonAction(text: string): void {
		const output = { value: text };
		this.dialogRef.close(output);
	}
}
