<app-ehe-header></app-ehe-header>
<div class="reset-section-wrapper general-wrapper">
	<section class="container">
		<div class="row justify-content-md-center">
			<div class="col-md-8 content-box">
				<div class="auth-content-wrapper">
					<app-reset-password></app-reset-password>
				</div>
			</div>
		</div>
	</section>
</div>
<app-ehe-spinner></app-ehe-spinner>
<app-ehe-footer [socialNetwork]="false"></app-ehe-footer>
