import { Injectable } from '@angular/core';
import { AUTH_STATIC_PATH, SESSION_HANDLER_KEY, UNAUTH_STATIC_PATH } from '../../constants/analytics.constants';
import { v4 as uuid } from 'uuid';
import * as AWSSDK from 'aws-sdk';
import { AuthService } from '../core/auth.service';
import { Environment } from '../../config/environment';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelper } from '../core/jwt-helper.service';
import {
	EventTrackingBean,
	ApptEventTrackingBean,
	ApptCancellationEventTrackingBean,
	HvaTrackingBean,
} from '../../beans/analytics/analytics-module.bean';

/** Data layer helper Object */
declare let DataLayerHelper: any;
/** AWS SDK Object */
declare let AWS: any;
/** UUID */
let RANDOM_SALT, SESSION_HANDLER;

/** Custom Analytics Service */
@Injectable({
	providedIn: 'root',
})
export class AnalyticsService {
	private helper: any;
	/** dataLayer */
	private dataLayer = [];
	/** S3 Object */
	private authS3: AWSSDK.S3;
	/** bucket name */
	// private bucketName: string;
	/** Holds environment variable */
	private env;

	/**
	 * Analytics Service constructor
	 * @param authService - Auth Service object
	 * @param environment - Environment Service
	 * @param http - Angular HTTP Object
	 */
	constructor(
		private authService: AuthService,
		private environment: Environment,
		private cookieService: CookieService,
		private http?: HttpClient,
		private jwtService?: JwtHelper
	) {
		this.setSessionId();
		this.helper = new DataLayerHelper(this.dataLayer, this.listener);
		this.env = this.environment.getEnvironment();
	}

	private listener(model, message) {
		// Message has been pushed.
		// The helper has merged it onto the model.
		// Now use the message and the updated model to do something.
	}

	getData(key: string) {
		return this.helper.get(key);
	}

	/**
	 * Send data to S3
	 * @param data - data to be passed
	 * @param auth - denote whether the data to be send to authenticated or un-authenticated bucket
	 */
	pushData(data) {
		const self = this;
		if (!data?.eventValue?.user?.uuid) {
			try {
				data.eventValue.user.uuid = JSON.parse(atob(sessionStorage.getItem('profile')))?.profileInfo?.epmsPID + '';
			} catch (error) {}
		}
		self.dataLayer.push(data);
		data.sessionId = RANDOM_SALT;
		data.eventValue.client = { useragent: navigator.userAgent };
		data.timestamp = Math.round(new Date().getTime() / 1000);
		AWSSDK.config.getCredentials(err => {
			if (err) {
				this.authService.getUnauthCredentials().then(res => {
					self.publishTOUnAuthS3(data);
				});
			} else {
				const cred = AWSSDK.config.credentials;
				const ssoType = localStorage.getItem('sso-type');
				if (
					!ssoType &&
					(!cred['params']['Logins'] ||
						(Object.keys(cred['params']['Logins']).length === 0 && cred['params']['Logins'].constructor === Object))
				) {
					self.publishTOUnAuthS3(data);
				} else {
					self.publishToS3(data, AWSSDK.config.credentials, this.env.ANALYTICS_AUTH_BUCKET);
				}
			}
		});
	}

	/**
	 * Publishing the data to Authenticated bucket
	 * @param data - data to be saved in S3
	 * @param credentials - holds aws credentials
	 */
	publishToS3(data, credentials, bucketName, fileName: string = '', originalConsoleErrorFn = null) {
		const { accessKeyId, secretAccessKey, sessionToken } = credentials;
		this.authS3 = new AWSSDK.S3({
			signatureVersion: 'v4',
			credentials: {
				accessKeyId: accessKeyId,
				secretAccessKey: secretAccessKey,
				sessionToken: sessionToken,
			},
		});

		const year = new Date().getFullYear();
		const month = ('0' + (new Date().getMonth() + 1)).slice(-2);
		const date = new Date().getDate();

		const loc = 'year=' + year + '/month=' + month + '/day=' + date + '/';

		if (!fileName) {
			fileName = AUTH_STATIC_PATH + loc + SESSION_HANDLER_KEY + '-' + uuid() + '.json';
		}
		const params = {
			Body: JSON.stringify(data),
			Bucket: bucketName,
			Key: fileName,
			ContentType: 'application/json',
		};

		const thisRef = this;
		// console.log('+++++++  Pushing to Authorized Bucket: ' + this.bucketName + '  FileName: ' + fileName);
		this.authS3.putObject(params, function (error, resData) {
			if (error) {
				if (originalConsoleErrorFn && typeof originalConsoleErrorFn === 'function') {
					originalConsoleErrorFn.call(console, error);
				} else {
					console.error(`[${AnalyticsService.name}][${thisRef.publishToS3.name}]`, error, error.stack);
				}
			}
		});
	}

	/**
	 * publishing the data to unauthenticated bucket
	 * @param data - data to be saved in S3
	 */
	publishTOUnAuthS3(data) {
		const bucketName = this.env.ANALYTICS_UNAUTH_BUCKET;
		const credentials = AWS.config.credentials;

		const { accessKeyId, secretAccessKey, sessionToken } = credentials;
		this.authS3 = new AWSSDK.S3({
			signatureVersion: 'v4',
			credentials: {
				accessKeyId: accessKeyId,
				secretAccessKey: secretAccessKey,
				sessionToken: sessionToken,
			},
		});

		const year = new Date().getFullYear();
		const month = ('0' + (new Date().getMonth() + 1)).slice(-2);
		const date = new Date().getDate();

		const loc = 'year=' + year + '/month=' + month + '/day=' + date + '/';

		const fileName = UNAUTH_STATIC_PATH + loc + SESSION_HANDLER_KEY + '-' + uuid() + '.json';

		const params = {
			Body: JSON.stringify(data),
			Bucket: bucketName,
			Key: fileName,
			ContentType: 'application/json',
		};

		const thisRef = this;
		this.authS3.putObject(params, function (error, resData) {
			if (error) {
				console.error(`[${AnalyticsService.name}][${thisRef.publishToS3.name}]`, error, error.stack);
			}
		});
	}

	/**
	 * Push UI logs to S3
	 * @param logs
	 * @param bucketName
	 * @param isError (default: `true`)
	 */
	pushLogs(logs: any, originalConsoleErrorFn: any, isError: boolean = true) {
		const data: any = { sessionId: '', agent: '', timestamp: '', error: '' };
		data.sessionId = RANDOM_SALT;
		data.type = sessionStorage.getItem('userId') || 'Anonymous';
		data.agent = { useragent: navigator.userAgent };
		data.timestamp = Math.round(new Date().getTime() / 1000);
		data[isError ? 'error' : 'data'] = logs;
		let email, epmsPID;
		const ssoType = localStorage.getItem('sso-type');
		if (!ssoType) {
			const auth = sessionStorage.getItem('auth');
			if (auth) {
				email = JSON.parse(sessionStorage.getItem('auth')).idToken.payload.email;
				epmsPID = JSON.parse(sessionStorage.getItem('auth')).idToken.payload['custom:epms-p-id'];
			}
		} else {
			epmsPID = this.jwtService.getEpmsId();
		}
		data.user = {
			email: email,
			uuid: epmsPID,
		};
		if (!data.user.uuid) {
			try {
				data.user.uuid = JSON.parse(atob(sessionStorage.getItem('profile')))?.profileInfo?.epmsPID + '';
			} catch (error) {}
		}
		const date = moment(new Date()).format('YYYY-MM-DD');
		const fileName = `${date}-ui-log-${RANDOM_SALT}___${uuid()}.json`;

		this.publishToS3(data, AWSSDK.config.credentials, this.env.S3_UI_LOGS_BUCKET, fileName, originalConsoleErrorFn);
		// const thisRef = this;
		// this.pushS3.putObject(params, function (err, resData) {
		//   if (err) {
		// 		console.error(`[${AnalyticsService.name}][${thisRef.publishToS3.name}]`, err, err.stack);
		//   }
		// });
	}
	private setSessionId(): void {
		if (this.cookieService.check('myehe-analytics-session-id')) {
			RANDOM_SALT = this.cookieService.get('myehe-analytics-session-id');
			/** Session key */
			SESSION_HANDLER = SESSION_HANDLER_KEY + '-' + RANDOM_SALT + '.json';
		} else {
			RANDOM_SALT = uuid();
			this.cookieService.set(
				'myehe-analytics-session-id',
				RANDOM_SALT,
				new Date(new Date().getTime() + 1000 * 60 * 60)
			);
			SESSION_HANDLER = SESSION_HANDLER_KEY + '-' + RANDOM_SALT + '.json';
		}
	}

	sendGaEvent(eventData: EventTrackingBean) {
		eventData.event = 'buttonClick';
		eventData.eventValue = window.location.href;
		(<any>window).dataLayer.push(eventData);
	}

	sendHvaEvent(eventData: HvaTrackingBean) {
		eventData.event = 'hva';
		(<any>window).dataLayer.push(eventData);
	}

	sendApptConfirmationEvent(eventData: ApptEventTrackingBean) {
		eventData.event = 'apptConfirmationEvent';
		(<any>window).dataLayer.push(eventData);
	}

	sendApptCancellationEvent(eventData: ApptCancellationEventTrackingBean) {
		eventData.event = 'apptCancelationEvent';
		(<any>window).dataLayer.push(eventData);
	}
}
