import { Appointment } from '../dashboard/dashboard-module.bean';

/** Analytics Bean */
export class AnalyticsBean {
	/** Url of the page in which the button is present */
	pageUrl?: string;
	/** Url of the page to which user is redirected after button click */
	redirectedTo?: string;
	/** Component in which the button is present */
	componentName?: string;
	/** An ID to identify the button */
	id?: string;
	/** Placement of the button in the page, e.g., header menu */
	placement?: string;
	/** Additional error details */
	errorDetails?: any;
}

/** Analytics User bean in analytics info */
export class AnalyticsUserBean {
	/** Email of the logged in user */
	email: string;
	/** UUID of the logged in user */
	uuid: string;
	/** Employer of the logged in user */
	company: string;
	/** Eligible for PE flag of the logged in user */
	eligibleForPE: boolean;
	/** Eligible for PV flag of the logged in user */
	eligibleForPV?: boolean;
	/** User Matched flag of the logged in user */
	userMatched: boolean;
	/** SSO Type either IDP or SP */
	ssoType: string;
	/** User Persona type */
	personaType: string;
}

/** Analytics Event value bean of authorized user */
export class AuthEventValueBean {
	/** User bean */
	user: AnalyticsUserBean;
	/** User status */
	status: string;
	/** UTM values */
	utm: any;
}

/** Analytics Page view bean of authorized user */
export class AnalyticsAuthPageViewBean {
	/** Event label (Page view) */
	eventLabel: string;
	/** Component in which the page is present */
	eventComponent: string;
	/** Event value bean for authorized user */
	eventValue: AuthEventValueBean;
}

// /** To Override Analytic Button Click Event Value Appointment Bean */
// export interface AnalyticsButtonClickEventAppointmentValueBean extends Appointment {
//   isRescheduled?: boolean;
// }

/** To Override Analytic Button Click Event Appointment Value Bean */
export interface AnalyticsButtonClickEvent_AppointmentValueBean {
	confirmationNumber: string;
	isRescheduled: boolean;
	apptID: number;
	procedure: AnalyticsButtonClickEvent_AppointmentProcedureValueBean;
	subProcedures?: AnalyticsButtonClickEvent_AppointmentProcedureValueBean[];
}

/** To Override Analytic Button Click Event Appointment Procedure Value Bean */
export interface AnalyticsButtonClickEvent_AppointmentProcedureValueBean {
	name: string;
	appDate: string;
	status: string;
	location: string;
	sdmStatus?: string;
}

/** To Override Analytic Button Click Event Values Bean */
export class AnalyticsButtonClickEventValueBean extends AuthEventValueBean {
	/** Booked appointment object (only available when passed manually while pushing event) */
	appointment?: AnalyticsButtonClickEvent_AppointmentValueBean;
}

/** Analytics Button click bean */
export class AnalyticsButtonClickBean {
	/** Event label (Button click) */
	eventLabel: string;
	/** Component in which the button is present */
	eventComponent: string;
	/** Event value bean */
	eventValue: AnalyticsButtonClickEventValueBean;
	/** Button element name as in html */
	elementName: string;
	/** Full url of the page in which the button is present */
	fullURL: string;
	/** Device size to differentiate mobile, laptops and desktops */
	deviceSize: string;
	/** Analytics data bean */
	analyticsData: any;
}

/** Google analytics button click bean */
export class EventTrackingBean {
	/** button click event */
	event?: string;
	/** Event category - button click */
	eventCategory?: string;
	/** Event action - button click */
	eventAction?: string;
	/** Event label - button click */
	eventLabel?: string;
	/** Event value - button click */
	eventValue?: string;
}

/** Google analytics button click bean */
export class HvaTrackingBean {
	/** button click event */
	event: string;
	/** status - success/fail */
	status: string;
	/** type - clicked button type */
	type: string;
	/** userID - User ID */
	userID: string;
}

/** Google analytics button click bean */
export class ApptEventTrackingBean {
	/** button click event */
	event: string;
	/** Event category - button click */
	eventCategory: string;
	/** Event action - button click */
	eventAction: string;
	/** Event label - button click */
	eventLabel: string;
	/** Event value - button click */
	eventValue: number;
	/** Appointment booking source - non email/ email/ regular login */
	bookingSource: string;
	/** Appointment confirmation number */
	confirmationNumber: string;
	/** Client ID of the logged in user */
	clientID: string;
	/** Location ID in which appt is booked */
	locationID: string;
	/** Patient Age - based on profile */
	patientAgeGroup: string;
	/** Flag to check if rescheduling flow */
	isRescheduling: string;
	/** Time left between appt in days */
	apptForwardLookTimeframe: string;
	/** Appointment date */
	apptDate: string;
	/** Goal - 1 which is tracked in GA */
	goal1: string;
	/** Secondary goal if any */
	otherGoal: string;
	/** Patient Segment based on profile & appt bookings */
	patientSegment: string;
	/** Procedure ID of the booked Appt */
	procedureID: string;
}

/** Google analytics button click bean */
export class ApptCancellationEventTrackingBean {
	/** button click event */
	event: string;
	/** Event category - button click */
	eventCategory: string;
	/** Event action - button click */
	eventAction: string;
	/** Event label - button click */
	eventLabel: string;
	/** Event value - button click */
	eventValue: number;
	/** User ID */
	userID: string;
	/** Location ID in which appt is booked */
	locationID: string;
	/** Appointment date */
	apptDate: string;
	/** Procedure ID of the booked Appt */
	procedureID: string;
}
