import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AnalyticsBean, EventTrackingBean } from 'src/app/beans/analytics/analytics-module.bean';

@Component({
	selector: 'app-button-outline',
	templateUrl: './button-outline.component.html',
	styleUrls: ['./button-outline.component.scss'],
})
export class ButtonOutlineComponent {
	/*
	 *  The key found in the src/assets/i18n/* files for the text of the button
	 */
	@Input() buttonTextKey: string = '';

	/*
	 *  Event which is invoked with the user clicks on the button
	 */
	@Output() clickEvent = new EventEmitter<any>();

	/*
	 *  Custom Analytics data passed in for the component
	 */
	@Input() analyticsData: AnalyticsBean;

	/*
	 *  Custom Analytics data passed in for the component
	 */
	@Input() googleAnalyticsData: EventTrackingBean;

	/*
	 *   This functon for the 'clickEvent' needs to be passed in from the parent
	 *   and will be called when the user clicks the button
	 */
	onClick() {
		this.clickEvent.emit();
	}
}
