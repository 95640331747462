<div class="pdf-wrapper">
	<div class="row pdf-title">
		<div class="col-md-12">
			<div class="float-left">
				<div class="clearfix">
					<i
						class="fa fa-arrow-left close-pdf-icon float-left clickable"
						aria-hidden="true"
						(click)="closePdf()"
						appEheAnalytics
						[data]="analyticsData"></i>
					<div class="modal-title float-left">{{ documentName }}</div>
				</div>
			</div>
			<div class="float-right">
				<i
					class="fa fa-download downloadBtn clickable"
					aria-hidden="true"
					(click)="downloadPdf()"
					appEheAnalytics
					[data]="analyticsData"></i>
			</div>
		</div>
	</div>
	<div class="content">
		<pdf-viewer
			[src]="pdfBase64"
			[show-all]="true"
			[original-size]="true"
			(page-rendered)="renderPage($event)"
			(error)="errorCallback($event)"></pdf-viewer>
	</div>
	<app-ehe-spinner></app-ehe-spinner>
</div>
