import { Injectable } from '@angular/core';
import { ServiceRouter } from '../core/http-service-router.service';
import { METHOD_TYPE } from '../../constants/service.constants';
import { Config } from '../../config/config';
import { SecureMessages } from '../../beans/messaging/messaging-module.bean';
import { Observable, BehaviorSubject } from 'rxjs';
import { Environment } from '../../config/environment';

/**
 * Messaging related service
 */
@Injectable({
	providedIn: 'root',
})
export class MessagingService {
	/** Observable to get message count */
	messageCount: BehaviorSubject<any>;

	/**
	 * Messaging service Constructor
	 * @param serviceRouter - Service router object
	 * @param environment - Environment Configuration
	 * @param config - url Configuration
	 */
	constructor(
		private serviceRouter: ServiceRouter,
		private environment: Environment,
		private config: Config
	) {
		this.messageCount = new BehaviorSubject<any>({});
	}

	/**
	 * Service call to get messages
	 */
	getMessages(): Observable<SecureMessages> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.messaging.getMessages,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * message count getter
	 */
	getCurrentMessageCount(): BehaviorSubject<any> {
		return this.messageCount;
	}

	/**
	 * Message count setter
	 * @param messageCount
	 */
	setCurrentMessageCount(messageCount) {
		this.messageCount.next(messageCount);
	}

	/**
	 * Service call for getting the message count
	 */
	getMessageCount() {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.messagingApi.getMessageCount,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * Service call for getting the messages based on folder Id
	 * @param folderId denote folders like inbox or sent items
	 */
	getMessagesInFolder(folderId) {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.messagingApi.getMessages + `?folderID=${folderId}`,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}
}
