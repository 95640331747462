import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WordPressOverlayComponent } from '../modals/word-press-overlay/word-press-overlay.component';

@Component({
	selector: 'app-value-proposition',
	templateUrl: './value-proposition.component.html',
	styleUrls: ['./value-proposition.component.scss'],
})
export class ValuePropositionComponent implements OnInit {
	currentRate: number;
	constructor(private dialog: MatDialog) {}

	ngOnInit() {
		this.currentRate = 4.5;
	}

	/**
	 * Opens Ratings overlay
	 */
	openRatingsOverlay() {
		this.dialog.open(WordPressOverlayComponent, {
			width: '100vw',
			data: { url: 'https://covid19.ehe.health/member-reviews/' },
			autoFocus: false,
			panelClass: 'wp-overlay',
		});
	}
}
