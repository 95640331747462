import { Injectable } from '@angular/core';
/** ICS js object*/
declare let ics: any;
/**
 * Add Calendar Service
 */
@Injectable({
	providedIn: 'root',
})
export class CalendarService {
	/**
	 * Generating the calendar event
	 * @param id - Id for the event (generally Appointment Id)
	 * @param subject - Event subject
	 * @param description - Event Description
	 * @param location - Event Location
	 * @param begin - Event starting time
	 * @param end - Event End time
	 * @param filename - File name to be downloaded
	 */
	addToCalendar(
		id: number,
		subject: string,
		description: string,
		location: any,
		begin: any,
		end: any,
		filename: string = 'Appointment'
	) {
		const cal = ics(id);
		cal.addEvent(subject, description, location, begin, end);
		cal.download(filename);
	}
}
