import { Component, Inject } from '@angular/core';
import * as moment from 'moment';
import { ToastData, TOAST_DATA } from './toaster.model';

/**
 * Appointment Count Toaster
 */
@Component({
	selector: 'app-toaster',
	templateUrl: './toaster.component.html',
	styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent {
	count: any;
	show: boolean = true;
	showNewToaster: boolean = true;

	/**
	 * Toaster component constructor
	 */
	constructor(@Inject(TOAST_DATA) readonly componentData: ToastData) {
		this.count = componentData.count;
		this.count.toLocaleString();
	}

	/**
	 * Gets current time in given format
	 */
	getCurrentTime() {
		return moment(new Date()).format('hh:mm A');
	}

	close() {
		this.show = false;
	}
}
