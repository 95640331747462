/** Tutorial disabled flag constant */
export const tutorialDisabled = false;

/** URL for Prep Instructions */
export const prepInstructions = 'https://ehe.health/prepguide';

/** Password Allowed special characters */
export const passwordAllowedSpecialChars = /[$^*.(){}?!@#%&",~`'_<>;:|\-\\/[\]]/.source;

/** Password Allowed special characters */
export const passwordSpecialCharsValidatorRegExp = new RegExp(
	'^(?=.*?' + passwordAllowedSpecialChars + ')' + passwordAllowedSpecialChars['splice'](1, 0, 'A-z0-9') + '+$'
);
