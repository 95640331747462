<div class="overlay-wrapper" [ngClass]="headerLeft ? 'heading-left' : ''">
	<div class="close" (click)="close()" *ngIf="showCloseIcon && !gaCloseIcon"></div>
	<div
		class="close"
		(click)="close()"
		*ngIf="showCloseIcon && gaCloseIcon"
		appGoogleAnalytics
		[eventData]="{
			eventCategory: gaCloseIcon.eventCategory,
			eventAction: gaCloseIcon.eventAction,
			eventLabel: gaCloseIcon.eventLabel
		}"></div>
	<div class="heading" [ngClass]="!headerLeft ? 'd-flex align-items-center flex-column' : 'pl-3'">
		<span>{{ title }}</span>
	</div>
	<div class="modal-body">
		<ng-content></ng-content>
	</div>
	<div class="buttons align-center">
		<span class="pr-3" *ngIf="closeButton">
			<button *ngIf="!gaCloseButton" (click)="close()" class="button button-medium" mat-stroked-button>
				{{ closeButton }}
			</button>
			<button
				*ngIf="gaCloseButton"
				(click)="close()"
				class="button button-medium"
				mat-stroked-button
				appGoogleAnalytics
				[eventData]="{
					eventCategory: gaCloseButton.eventCategory,
					eventAction: gaCloseButton.eventAction,
					eventLabel: gaCloseButton.eventLabel
				}">
				{{ closeButton }}
			</button>
		</span>
		<span class="pr-3" *ngIf="button1">
			<button
				*ngIf="!gaButton1"
				(click)="submit()"
				class="button button-medium"
				mat-button
				[disabled]="button1Disabled">
				{{ button1 }}
			</button>
			<button
				*ngIf="gaButton1"
				(click)="submit()"
				class="button button-medium"
				mat-button
				[disabled]="button1Disabled"
				appGoogleAnalytics
				[eventData]="{
					eventCategory: gaButton1.eventCategory,
					eventAction: gaButton1.eventAction,
					eventLabel: gaButton1.eventLabel
				}">
				{{ button1 }}
			</button>
		</span>
		<span *ngIf="closeButtonText && !gaCloseButtonText" (click)="close()" class="cancel-button">{{
			closeButtonText
		}}</span>
		<span
			*ngIf="closeButtonText && gaCloseButtonText"
			(click)="close()"
			class="cancel-button"
			appGoogleAnalytics
			[eventData]="{
				eventCategory: gaCloseButtonText.eventCategory,
				eventAction: gaCloseButtonText.eventAction,
				eventLabel: gaCloseButtonText.eventLabel
			}"
			>{{ closeButtonText }}</span
		>
	</div>
</div>
