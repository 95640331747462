import * as moment from 'moment';
import { Location } from '../beans/dashboard/dashboard-module.bean';
/**
 * Class containing Date util functions using moment js
 */
export class DateUtil {
	/**
	 * Get next date from current date
	 */
	public static getNextDate() {
		return moment(new Date()).add(1, 'days').format('MM/DD/YYYY');
	}

	/**
	 * Get current year
	 */
	public static getCurrentYear() {
		return new Date().getFullYear();
	}

	/**
	 * Get current date in MM/DD/YYYY format
	 */
	public static getDateInFormatMMDDYYYY(date) {
		return moment(new Date(date)).format('MM/DD/YYYY');
	}

	/**
	 * Get current date in MM/DD/YYYY format UTC
	 */
	public static getUTCDateInFormatMMDDYYYY(date) {
		return moment.utc(new Date(date)).format('MM/DD/YYYY');
	}

	/**
	 * Get current date
	 */
	public static getCurrentDate() {
		return moment(new Date()).format('MM/DD/YYYY');
	}

	/**
	 * Get current date in YYYY-MM-DD format
	 */
	public static getDateInSqlFormat(date) {
		return moment(new Date(date)).format('YYYY-MM-DD');
	}

	/**
	 * Get moment() object from US Date Format 'MM/DD/YYYY'
	 * @param date String in format of 'MM/DD/YYYY'
	 * @returns moment.Moment object
	 */
	public static getMoment(date: string): moment.Moment {
		return moment(date, 'MM/DD/YYYY');
	}

	/**
	 * Whether selected appointment date is Same-Day-Mammogram ready or not
	 * @param selectedDate Selected date for the appointment
	 * @param selectedLocation Selected location for the appointment
	 * @returns `true` if selected date is valid for SDM else `false`
	 */
	public static isSelectedApptDateSdmReadyDate(selectedDate: Date, selectedLocation: Location): boolean {
		if (
			selectedDate &&
			selectedLocation?.sameDayMammogram &&
			selectedLocation?.sameDayMammogramEligibilityDate
		) {
			return moment(selectedDate).isSameOrAfter(selectedLocation.sameDayMammogramEligibilityDate, 'date');
		}
		return false;
	}
}
