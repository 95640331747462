import { Injectable } from '@angular/core';
import { ServiceRouter } from '../core/http-service-router.service';
import { METHOD_TYPE } from 'src/app/constants/service.constants';
import { Config } from '../../config/config';
import { Observable } from 'rxjs';
import { Environment } from '../../config/environment';

@Injectable({
	providedIn: 'root',
})
export class EHEAndMeAuthService {
	constructor(
		private config: Config,
		private serviceRouter: ServiceRouter,
		private environment: Environment
	) {}

	/**
	 * Login to ehe&me
	 */
	eheandmeLogin(username: string, password: string): Observable<any> {
		const authData = {
			username: username,
			password: password,
		};
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.eheandme.login,
			METHOD_TYPE.GENERAL_POST,
			authData
		);
	}
}
