import { Injectable } from '@angular/core';
@Injectable()
export class SlotTiersConfig {
	config = {
		Morning: {
			'1': ['08:00', '08:30', '09:00', '09:30'],
			'2': ['07:30', '10:00', '10:30', '11:00'],
			'3': ['07:00', '07:45', '08:45', '09:15'],
			'4': ['07:15', '08:15', '10:15', '11:30'],
			'5': ['09:45', '10:45', '11:15', '08:45'],
		},
		Noon: {
			'1': ['12:00', '12:15', '12:30', '13:00'],
			'2': ['12:45', '13:30', '14:00', '15:30'],
			'3': ['13:15', '14:30', '14:45', '16:00', '16:15', '17:00', '19:00'],
			'4': ['13:45', '14:15', '15:00', '15:15', '15:45', '16:30', '16:45', '17:15', '17:30', '18:00', '18:30'],
			'5': ['17:45', '18:15', '18:45'],
		},
	};
	hiddenSlots = {
		'1': {
			'1': {
				Morning: '0',
			},
			'2': {
				Morning: '0',
			},
			'3': {
				Morning: '0',
			},
			'4': {
				Morning: '0',
			},
			'5': {
				Morning: '0',
				Noon: '0',
				showSlots: ['12:00', '12:30'],
			},
		},
	};
}
