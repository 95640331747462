<div class="value-text align-center">Your EHE Health benefit, provided</div>
<div class="second-heading">by your company, helps you:</div>
<p>Spot immediate issues</p>
<p>&#8226;</p>
<p>Identify long term risks</p>
<p>&#8226;</p>
<p>Set a clear action plan with your doctor</p>
<div class="container">
	<img
		class="link pt-4"
		(click)="openVideoOverlay()"
		appGoogleAnalytics
		[eventData]="{
			eventCategory: 'Overlay',
			eventAction: 'Overlay - Pulse Physical Video',
			eventLabel: 'Pulse Physical Video'
		}"
		src="/assets/images/pulse_video_thumbnail.png" />
</div>
