import { Component } from '@angular/core';

@Component({
	selector: 'app-pwc-landing-page',
	templateUrl: './pwc-landing-page.component.html',
	styleUrls: ['./pwc-landing-page.component.scss'],
})
export class PwcLandingPageComponent {
	constructor() {}
}
