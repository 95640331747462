import { Injectable } from '@angular/core';
import { METHOD_TYPE } from '../../constants/service.constants';
import { HttpServices } from './http.service';
import { ApiGatewayService } from './api-gateway.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ServiceRouter {
	constructor(
		private http: HttpServices,
		private apiG: ApiGatewayService
	) {}
	makeRequest(
		baseUrl,
		requestUrl,
		methodType,
		body,
		baseDomain: any = '',
		domainPath: string = '/base'
	): Observable<any> {
		const methodFunction = this._getMethodFunction(baseUrl, requestUrl, methodType, body, baseDomain, domainPath);
		return methodFunction;
	}
	private _getMethodFunction(baseUrl, requestUrl, methodType, body, baseDomain, domainPath): Observable<any> {
		switch (methodType) {
			case METHOD_TYPE.SECURED_GET:
				return this.apiG.securedGet(baseUrl, requestUrl, baseDomain, domainPath);
			case METHOD_TYPE.SECURED_POST:
				return this.apiG.securedPost(baseUrl, requestUrl, body, baseDomain, domainPath);
			case METHOD_TYPE.SECURED_PUT:
				return this.apiG.securedPut(baseUrl, requestUrl, body, baseDomain);
			case METHOD_TYPE.SECURED_DELETE:
				return this.apiG.securedDelete(baseUrl, requestUrl, body, baseDomain);
			case METHOD_TYPE.GENERAL_GET:
				return this.http.httpGet(baseUrl, requestUrl);
			case METHOD_TYPE.GENERAL_POST:
				return this.http.httpPost(baseUrl, requestUrl, body);
			case METHOD_TYPE.GENERAL_POST_WITHOUT_HEADERS:
				return this.http.httpPostWithoutHeaders(baseUrl, requestUrl, body);
			case METHOD_TYPE.GENERAL_GET_WITHOUT_HEADERS:
				return this.http.httpGetWithoutHeaders(baseUrl, requestUrl);
			case METHOD_TYPE.GENERAL_PUT:
				return this.http.httpPut(baseUrl, requestUrl, body);
			case METHOD_TYPE.GENERAL_DELETE:
				return this.http.httpDelete(baseUrl, requestUrl);
			default:
				break;
		}
	}
}
