/** Service method types constants */
export enum METHOD_TYPE {
	SECURED_GET = 'securedGet',
	SECURED_POST = 'securedPost',
	SECURED_PUT = 'securedPut',
	SECURED_DELETE = 'securedDelete',
	GENERAL_GET = 'generalGet',
	GENERAL_POST = 'generalPost',
	GENERAL_POST_WITHOUT_HEADERS = 'generalPostWithoutHeaders',
	GENERAL_GET_WITHOUT_HEADERS = 'generalGetWithoutHeaders',
	GENERAL_PUT = 'generalPut',
	GENERAL_DELETE = 'generalDelete',
	EHEANDME_AUTH = 'eheandmeAuth',
}
