import { Directive, AfterViewInit, ElementRef } from '@angular/core';

/**
 * Directive which enables autofocus on any angular input component
 */
@Directive({
	selector: '[appAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
	constructor(private el: ElementRef) {}

	ngAfterViewInit() {
		setTimeout(() => {
			this.el.nativeElement.focus();
		}, 0);
	}
}
