import { Component } from '@angular/core';
import { UpdateProviderVideoModalComponent } from 'src/app/modules/shared/update-provider-video-modal/update-provider-video-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Config } from 'src/app/config/config';

@Component({
	selector: 'app-login-screen-variation',
	templateUrl: './login-screen-variation.component.html',
	styleUrls: ['./login-screen-variation.component.scss'],
})
export class LoginScreenVariationComponent {
	constructor(
		private config: Config,
		private dialog: MatDialog,
		public router: Router
	) {}

	openVideoOverlay() {
		const dialogRef = this.dialog.open(UpdateProviderVideoModalComponent, {
			width: '70vw',
			panelClass: 'video-overlay',
			autoFocus: false,
			data: {
				url: this.config.url.resources.activationVideo,
			},
		});
	}
}
