import { Injectable, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EheInfoModalComponent } from 'src/app/modules/shared/ehe-modal/info-modal/ehe-info-modal.component';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Config } from '../../config/config';
import { ToastrService } from 'ngx-toastr';

/**
 * Modal Service-
 */
@Injectable({
	providedIn: 'root',
})
export class ModalService {
	/** user action event*/
	@Output() action = new EventEmitter();
	/**  Modal data */
	result: Subject<any>;

	/**
	 * Modal Service Constructor
	 * @param dialog - Mat Dialog
	 * @param router - Angular Router
	 * @param dialogRef - Mat Dialog Reference
	 * @param config - URL Config
	 * @param toastrService - Toaster Service
	 */
	constructor(
		public dialog: MatDialog,
		private router: Router,
		public dialogRef: MatDialog,
		private config: Config,
		private toastrService: ToastrService
	) {}

	/**
	 * Show Toaster
	 * @param message - Message
	 */
	showToaster(message?: string) {
		if (!message) {
			message = 'Server Error Occurred !';
		}
		this.toastrService.warning(message, '', this.config.toasterConfig);
	}

	/**
	 * Show Warning Toaster
	 */
	showWarningToaster() {
		this.toastrService.warning('', 'Server Error Occurred !', this.config.toasterConfig);
	}
	/**
	 * Show Success Toaster
	 */
	showSuccessToaster(message: string = 'Success !') {
		this.toastrService.success('', message, this.config.successMsgConfig);
	}
	/**
	 * Show Error Toaster
	 */
	showErrorToaster() {
		this.toastrService.error('', 'Error !', this.config.toasterConfig);
	}
	/**
	 * Show Info Toaster
	 */
	showInfoToaster() {
		this.toastrService.info('', 'Error !', this.config.toasterConfig);
	}

	/**
	 * Trigger Info Modal
	 * @param title - Title of the modal
	 * @param type - Modal type
	 * @param message - Message
	 * @param buttonType - boolean/string value denotes whether modal has 'OK' button (truthy) or 'YES/NO' button (falsy), or numeric encoding
	 */
	openInfoModal(title, type, message, buttonType: number | string | boolean) {
		if (['string', 'boolean'].includes(typeof buttonType)) {
			buttonType = buttonType ? EheInfoModalComponent.BUTTON_TYPES.OKAY : EheInfoModalComponent.BUTTON_TYPES.YESNO;
		}
		this.result = new Subject<any>();
		/** constructing the modal using Dialog */
		const dialogRef = this.dialog.open(EheInfoModalComponent, {
			width: '512px',
			data: {
				title: title,
				type: type,
				message: message,
				buttonType: buttonType,
			},
		});

		/** getting the data from Modal once it's closed */
		dialogRef.afterClosed().subscribe(result => {
			// closing all the dialog references
			this.dialogRef.closeAll();
			// pushing the data to @result observable
			this.result.next(result);
		});
	}

	/**
	 * get @result observable
	 */
	getResult(): Observable<any> {
		return this.result.asObservable();
	}

	/**
	 * Trigger session expiry modal
	 * @param title - Session expiry modal title
	 * @param message - Modal message body
	 */
	openSessionExpireModal(title, message) {
		// checking whether any modal has already opened
		if (this.dialog.openDialogs.length > 1) {
			return;
		}

		/** triggering the modal */
		const dialogRef = this.dialog.open(EheInfoModalComponent, {
			width: '512px',
			data: {
				title: title,
				type: 'warning',
				message: message,
				buttonType: EheInfoModalComponent.BUTTON_TYPES.OKAY,
			},
		});
		/** on close redirecting to login */
		dialogRef.afterClosed().subscribe(result => {
			this.dialogRef.closeAll();
			sessionStorage.removeItem('auth');
			this.router.navigate(['login']);
		});
	}
}
