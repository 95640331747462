import { Injectable } from '@angular/core';
import { Router, ResolveStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

/**
 * Title service helps to set browser title while navigating across routes
 */
@Injectable()
export class TitleService {
	/**
	 * TitleService constructor
	 * @param router Angular router
	 * @param titleService Angular Title Object
	 */
	constructor(
		private router: Router,
		private titleService: Title
	) {}
	/**
	 * initialization of title service
	 *
	 * which is being called for each router navigation and fetching the route param 'data'
	 */
	init() {
		this.router.events
			.pipe(
				filter(event => event instanceof ResolveStart),
				map(event => {
					let data = null;
					let route = event['state'].root;

					while (route) {
						data = route.data || data;
						route = route.firstChild;
					}

					return data;
				})
			)
			.subscribe(event => {
				this.titleService.setTitle(event.title + ' - EHE');
			});
	}
}
