<div class="footer-wrapper" *ngIf="socialNetwork; else notSocialNetwork" [style.top.px]="top">
	<div class="container">
		<div class="d-flex flex-wrap">
			<div class="col-md-12 clearfix">
				<p class="float-left">
					© {{ currentYear }} EHE. All rights reserved.
					<span>
						<a [routerLink]="['/terms']">Terms and Conditions</a>
						| <a [routerLink]="['/privacy-policy']">Data Privacy Policy</a> |
						<a [routerLink]="['/notice-of-privacy-practices']">Notice of Privacy Practices</a>
						| <a (click)="openCookiesPreferences($event)">Cookie Preferences</a>
					</span>
				</p>
				<ul class="float-right">
					<li class="mobile-view">
						<a href="tel:855.937.2720" aria-label="855.937.2720">Need Help? 855.937.2720</a>
					</li>
					<li class="phone-number">Need Help? 855.937.2720</li>
					<li>
						<a target="_blank" href="https://www.instagram.com/ehehealth/"><i class="fab fa-instagram"></i></a>
					</li>
					<li>
						<a target="_blank" href="https://www.facebook.com/ehehealth/"><i class="fab fa-facebook-f"></i></a>
					</li>
					<li>
						<a target="_blank" href="https://twitter.com/ehehealth"><i class="fab fa-twitter"></i></a>
					</li>
					<li>
						<a target="_blank" href="https://www.linkedin.com/company/ehe-health"><i class="fab fa-linkedin-in"></i></a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<ng-template #notSocialNetwork>
	<div class="footer-wrapper">
		<div class="center">
			<p class="float-left">
				© {{ currentYear }} EHE. All rights reserved.
				<span>
					<a [routerLink]="['/terms']">Terms and Conditions</a>
					| <a [routerLink]="['/privacy-policy']">Data Privacy Policy</a> |
					<a [routerLink]="['/notice-of-privacy-practices']">Notice of Privacy Practices</a>
					| <a (click)="openCookiesPreferences($event)">Cookie Preferences</a>
				</span>
			</p>
		</div>
	</div>
</ng-template>

<!--
	to reinsert privacy shield insert the following into both sections in between privacy policy and Notice of Privacy Practices:

	<a [routerLink]="['/privacy-shield']">Privacy Shield</a> |

-->
