import { Component, OnInit, Input, Inject, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { JwtHelper } from '../../../services/core/jwt-helper.service';
import { DataCarrierService } from '../../../services/core/data-carrier.service';
import { AuthService } from '../../../services/core/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceRouter } from '../../../services/core/http-service-router.service';
import { Config } from '../../../config/config';
import { METHOD_TYPE } from '../../../constants/service.constants';
import { Environment } from '../../../config/environment';
import { DOCUMENT } from '@angular/common';
import { MessagingService } from '../../../services/messaging/messaging.service';
import { HealthFinding } from '../../../beans/health-summary/health-summary.module.bean';
import { TutorialComponent } from '../../tutorial/tutorial.component';
import { MatDialog } from '@angular/material/dialog';
import { TutorialService } from '../../../services/tutorial/tutorial.service';
import { PECount } from 'src/app/beans/dashboard/dashboard-module.bean';
import { ProfileInfoBean, ProfileRelatedInfoBean } from '../../../beans/account/account-module.bean';
import { tutorialDisabled } from '../../../constants/app.constants';
import { USER_LEVEL } from '../../../constants/user.level.constants';
import { APP_USER_PERMISSIONS } from '../../../constants/app-user-permissions.constants';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppointmentsService } from '../../../services/scheduling/appointment/appointments.service';
import { ClientUtil } from '../../../utils/client.util';
import { AssessmentVersion } from 'src/app/services/health-history/health-history.types';
import { takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { AssessmentCheckService } from 'src/app/services/health-history/assessment-check.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ProfileService } from 'src/app/services/core/profile.service';
import { PulseWellnessService } from '../../../services/core/pulse-wellness.service';
/**
 * Header Component
 */
@Component({
	selector: 'app-ehe-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
	/** handling navbar triggers */
	navbarOpen = false;
	/** handling mega menu */
	browseAll = false;
	/** maintenance message */
	showMaintenanceMessage = '';
	/** exam result */
	examResults: HealthFinding;
	/** user permission list */
	userPermission: Array<string> = [];
	/** USER Level constants */
	USER_LEVEL = USER_LEVEL;
	/** MODULE Level constants */
	MODULE_LEVEL = APP_USER_PERMISSIONS;
	/** denote if biometricsNonEligible or not */
	biometricsNonEligible: boolean;
	/** denote if the page loaded */
	isLoaded: boolean;
	/** denote auth flag, helps to show different headers */
	@Input() auth: boolean = false;
	/** helps to show wellness header */
	@Input() wellness: boolean = false;
	/** To hide headers if user is from HA page */
	@Input() healthHistory: boolean = false;
	/** To show or hide phone number on header */
	@Input() showPhone: boolean = false;
	/** sharing profile related info to other components */
	@Output() profileRelatedInfo: EventEmitter<ProfileRelatedInfoBean>;
	/** profile related details */
	public profileRelatedInfoBean: ProfileRelatedInfoBean;
	/** user name */
	username: string;
	/** denote is matched member */
	isMatchedMember: boolean = false;
	/** tutorialDisabledFlag */
	tutorialDisabledFlag = tutorialDisabled;
	/** current URL */
	routerUrl: string;
	/** redirect URL */
	redirectUrl: string;
	/** Observable */
	private _destroyed$: Subject<boolean> = new Subject<boolean>();
	/** isSimplified */
	@Input() isSimplified: boolean = false;
	/** Total appointment count */
	appointmentCount: number;

	/**
	 * Header component Constructor
	 * @param document - DOM
	 * @param appointmentService - Appointment Service object
	 * @param assessmentCheckService - Assessment Check service object
	 * @param authService - Authentication Service
	 * @param config - URL Config Service
	 * @param dataCarrierService - DataCarrier Service
	 * @param dialog - MatDialog
	 * @param environment - Environment Service
	 * @param healthSummaryService - Health Summary Service
	 * @param jwtService - JWT Service
	 * @param permissionsService - NGX-Permission Service
	 * @param router - Angular Router
	 * @param serviceRouter - Service Router
	 * @param tutorialService - Tutorial Service
	 * @param messagingService - Messaging Service
	 */

	// Top navigation link titles
	navBookExam: string = 'Book My Exam';
	navMyHealth: string = 'My Health';
	navMyMentors: string = 'Coaching';
	navMyAppointments: string = 'My Appointments';
	navAccount: string = 'Account';
	navMessages: string = 'Messages';
	navHelp: string = 'Help & FAQ';
	navTour: string = 'Portal Tour';
	navMore: string = 'More';
	navSignOut: string = 'Sign Out';
	navPhoneNumber: string;

	@Input() hideLinks: boolean = false;
	hideMyMentorNavLink: boolean = false;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		public appointmentService: AppointmentsService,
		private assessmentCheckService: AssessmentCheckService,
		private authService: AuthService,
		private config: Config,
		private dataCarrierService: DataCarrierService,
		private dialog: MatDialog,
		private environment: Environment,
		private jwtService: JwtHelper,
		private permissionsService: NgxPermissionsService,
		private router: Router,
		private serviceRouter: ServiceRouter,
		private tutorialService: TutorialService,
		public messagingService: MessagingService,
		private spinner: SpinnerComponent,
		public profileService: ProfileService,
		private activatedRoute: ActivatedRoute,
		private pulseWellnessService: PulseWellnessService
	) {
		this.username = this.jwtService.getUsername();
		this.profileRelatedInfoBean = new ProfileRelatedInfoBean();
		this.profileRelatedInfo = new EventEmitter<ProfileRelatedInfoBean>();
		// handling maintenance page
		if (this.environment.getEnvironment().UNDER_MAINTENANCE === 'true') {
			this.router.navigate(['maintenance']);
		} else if (this.environment.getEnvironment().MAINTENANCE_MESSAGE.length > 0) {
			this.showMaintenanceMessage = this.environment.getEnvironment().MAINTENANCE_MESSAGE;
		}
		this.biometricsNonEligible = false;
		this.isLoaded = false;
		this.routerUrl = router.url;
		this.redirectUrl = '/scheduling/create/appointment/v3';
	}

	/** On initialization */
	ngOnInit() {
		this.navPhoneNumber = this.navPhoneNumber ?? '888.672.8172';

		if (!this.isSimplified) {
			localStorage.setItem('isMatchedUser', 'true');
			const epmsPID = this.jwtService.getEpmsId();
			if (this.auth && epmsPID) {
				this.getProfileInfo();
				this._getDataCarrierService();
			} else {
				this.profileRelatedInfoBean.noMatch = true;
				this.profileRelatedInfo.emit(this.profileRelatedInfoBean);
				const navBar = document.getElementById('main-menu-nav');
				if (navBar && navBar.classList) {
					navBar.classList.add('l-40');
				}
			}
		}
		// Remove disable wrapper scroll every time
		this.document.body.classList.remove('disable-wrapper-scroll');
		// redirects to vax pass
		this.activatedRoute.queryParams.subscribe(params => {
			if (params.tab === 'vax-pass') {
				this.spinner.startLoader();
				timer(1000).subscribe(t => {
					this.router.navigate(['/vaxstatus'], {
						queryParams: { display: 'consent-form' },
					});
				});
				this.spinner.stopLoader();
			}
		});
	}

	/*** Life cycle hook - On Changes */
	ngOnChanges() {
		this.biometricsNonEligible = this.dataCarrierService.getChannel().getValue().biometricsNonEligible;
	}

	/**
	 *
	 * Subscribes to the DataCarrierService to get the latest values
	 * these values are used when _loadBackgroundAsyncCalls is called
	 * @private
	 * @memberof HeaderComponent
	 */
	private _getDataCarrierService(): void {
		this.dataCarrierService
			.getChannel()
			.pipe(takeUntil(this._destroyed$))
			.subscribe(data => {
				if (data.peCount) {
					this._updateRedirectURL(data.peCount);
				}
				const canLoadData = this._permissionCheck();
				if (canLoadData === true) {
					return this._loadBackgroundAsyncCalls(data);
				}
				return;
			});
	}

	/**
	 *
	 * Checks if the user has the correct permissions to load the
	 * data from _loadBackgroundAsyncCalls
	 * @private
	 * @returns {boolean}
	 * @memberof HeaderComponent
	 */
	private _permissionCheck(): boolean {
		if (this.userPermission.length > 0) {
			const { HUBBELL_NOT_ELIGIBLE, UNMATCHED } = USER_LEVEL;
			const hubbleNotEligibleUser = this.userPermission.includes(HUBBELL_NOT_ELIGIBLE);
			const unmatchedUser = this.userPermission.includes(UNMATCHED);
			const invalidPerms = hubbleNotEligibleUser || unmatchedUser;
			return invalidPerms === false;
		}
		return false;
	}

	/**
	 *
	 * Checks if the respective api data is found in the data carrier
	 * service if it's not then this function will call the respective
	 * method to retrive the latest data. This means that an async call
	 * is made and then as soon as we have the data we won't keep trying
	 * to get the data.
	 * @private
	 * @param {*} data
	 * @memberof HeaderComponent
	 */
	private _loadBackgroundAsyncCalls(data: any): void {
		const { assessmentVersionFormat }: { assessmentVersionFormat: boolean } = data;
		if (assessmentVersionFormat === undefined || assessmentVersionFormat === null) {
			this._getAssessmentVersion();
		}
	}

	/**
	 *
	 * retrieves the AssessmentVersion for the user and then sends the data
	 * to _assessmentFormatCheck
	 * @private
	 * @memberof HeaderComponent
	 */
	private _getAssessmentVersion(): void {
		this.assessmentCheckService
			.getAssessmentVersion()
			.pipe(takeUntil(this._destroyed$))
			.subscribe(
				(assessmentVersion: AssessmentVersion) => this._assessmentFormatCheck(assessmentVersion),
				(err: Response) => {
					// FIXME: determine error type
					console.error(`[${HeaderComponent.name}][${this._getAssessmentVersion.name}]`, err);
				}
			);
	}

	/**
	 *
	 * Checks for an old format of the health assessment and if it's an older
	 * version then it will add the pdf into the DataCarrierService so it can
	 * be used instead of the new health assessment form
	 * @private
	 * @param {AssessmentVersion} assessmentVersion
	 * @memberof HeaderComponent
	 */
	private _assessmentFormatCheck(assessmentVersion: AssessmentVersion): void {
		const { oldFormat } = assessmentVersion;
		this.dataCarrierService.pushValue('assessmentVersionFormat', oldFormat);
		if (oldFormat === true) {
			const epmsPID = this.jwtService.getEpmsId();
			this.dataCarrierService.pushValue('assessmentPdf', {
				id: 0,
				documentName: 'Health_Assessment',
				epmsPID: epmsPID,
			});
		}
	}

	private _updateRedirectURL(peCount: PECount) {
		peCount.eligibleToBookPE = false;
		if (
			!peCount.eligibleToBookPE &&
			peCount.pulseVirtualStatusDetails &&
			peCount.pulseVirtualStatusDetails.isEligibleForPW
		) {
			// If the User doesn't have any PV Appointment, Navigate to the scheduler page
			if (!peCount.pulseVirtualStatusDetails.isPWAppointmentBooked) {
				this.redirectUrl = '/scheduling/create/appointment';
			}
		}
	}

	/** get profile information from service along with its
	 *  permissions for the application
	 * */
	getProfileInfo() {
		this.profileService.getProfileInfo().subscribe({
			next: (res: ProfileRelatedInfoBean) => {
				this.profileRelatedInfoBean = res;
				this.setProfileInfo();
				this.dataCarrierService.pushValue('profile', this.profileRelatedInfoBean);
			},
			error: err => {
				this.spinner.stopLoader();
			},
		});
	}

	setBasicContext() {
		this.profileService.setFirstName(this.profileRelatedInfoBean.profileInfo.firstName);
		this.profileService.setLastName(this.profileRelatedInfoBean.profileInfo.lastName);
		this.profileService.setGender(this.profileRelatedInfoBean.profileInfo.gender);
		this.isMatchedMember = this.profileRelatedInfoBean.profileInfo.userMatched;
		if (!this.isMatchedMember) {
			const navBar = document.getElementById('main-menu-nav');
			if (navBar && navBar.classList) {
				navBar.classList.add('l-40');
			}
		}
		localStorage.setItem('isMatchedUser', '' + this.isMatchedMember);
		if (this.userPermission.length === 0) {
			this.setPermission();
		}
		this.profileRelatedInfo.emit(this.profileRelatedInfoBean);
	}

	setProfileInfo() {
		this.setBasicContext();

		this.messagingService.setCurrentMessageCount({
			inbox: this.profileRelatedInfoBean.unreadMessageCount,
		});
		this.appointmentService.setUpcomingAppointmentCount(this.profileRelatedInfoBean.confirmedAppointmentCount);
		this.pulseWellnessService.getUpcomingConsultations().subscribe({
			next: res => {
				res?.data != null
					? this.appointmentService.setUpcomingPWappointment(true)
					: this.appointmentService.setUpcomingPWappointment(false);
			},
			error: err => {
				console.error(`[${HeaderComponent.name}][${this.setProfileInfo.name}]`, err);
			},
		});
	}

	/**
	 * logout the user from application
	 * clearing the storage and AWS Credentials
	 */
	logout() {
		this.authService.logoutUser();
		this.router.navigate(['/login'], { queryParams: { clear: true } });
	}

	/** Toggle mega menu */
	toggleBrowseAll() {
		this.browseAll = !this.browseAll;
	}

	/** On Mobile devices toggling navbar */
	toggleNavbar() {
		this.navbarOpen = !this.navbarOpen;
		this.document.body.classList.toggle('disable-wrapper-scroll');
	}

	/** Handling tutorial modal */
	openTutorialModal() {
		const dialogRef = this.dialog.open(TutorialComponent, {
			disableClose: true,
			backdropClass: 'tutorial-backdrop',
		});

		dialogRef.afterClosed().subscribe(result => {
			const postBody = { status: result };
			this.tutorialService.postStatus(postBody);
		});
	}

	/** Setting user permission based on flags */
	setPermission() {
		this.isLoaded = true;
		const { profileRelatedInfoBean } = this;
		const { profileInfo } = profileRelatedInfoBean;
		const { userMatched, client, eligibleForPE } = profileInfo;
		const { HUBBELL_ELIGIBLE, HUBBELL_NOT_ELIGIBLE, MATCHED, UNMATCHED, CATALENT_CLIENT } = USER_LEVEL;
		if (userMatched) {
			if (ClientUtil.checkClientType(client, this.environment.getEnvironment().SSN_ASSOCIATED_CLIENTS)) {
				this.dataCarrierService.pushValue('hubbleUser', true);
				this.dataCarrierService.pushValue('eligibleForPE', eligibleForPE);
				if (eligibleForPE) {
					this.biometricsNonEligible = false;
					this.dataCarrierService.pushValue('biometricsNonEligible', this.biometricsNonEligible);
					this.userPermission.push(HUBBELL_ELIGIBLE);
				} else if (!eligibleForPE) {
					this.biometricsNonEligible = true;
					this.dataCarrierService.pushValue('biometricsNonEligible', this.biometricsNonEligible);
					this.userPermission.push(HUBBELL_NOT_ELIGIBLE);
				}
			} else {
				this.biometricsNonEligible = false;
				this.userPermission.push(MATCHED);
				if (client.id === this.environment.getEnvironment().CATALENT_CLIENTS) {
					this.userPermission.push(CATALENT_CLIENT);
					this.hideMyMentorNavLink = true;
				}
			}
		} else {
			this.biometricsNonEligible = false;
			this.userPermission.push(UNMATCHED);
		}
		// need to keep the user level permissions in sync with app level permissions
		const appLevelPermissions = this.authService.getAppPermissionsForLoggedInUser() || [];
		this.permissionsService.loadPermissions([...this.userPermission, ...appLevelPermissions]);
	}

	ngOnDestroy(): void {
		this._destroyed$.next(true);
		this._destroyed$.unsubscribe();
	}

	getLastName() {
		if (
			this.profileRelatedInfoBean &&
			this.profileRelatedInfoBean.profileInfo &&
			this.profileRelatedInfoBean.profileInfo.lastName
		) {
			return this.profileRelatedInfoBean.profileInfo.lastName.charAt(0).toUpperCase();
		}
	}

	getUpcomingApptCount() {
		const upcomingCount = this.appointmentService.getUpcomingAppointmentsCount().value;
		const hasPWAppointment = this.appointmentService.hasUpcomingPWappointment().value;

		if (upcomingCount) {
			this.appointmentCount = hasPWAppointment ? upcomingCount + 1 : upcomingCount;
		} else if (hasPWAppointment) {
			this.appointmentCount = 1;
		} else {
			this.appointmentCount = 0;
		}

		return this.appointmentCount;
	}
}
