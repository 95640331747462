<div class="vax-pass-card-container">
	<img src="/assets/images/VaxStatusLogo.svg" alt="vaxstatus" />
	<p [translate]="'vax-pass.card.description'">
		VaxStatus stores your vaccination records(for example, COVID-19 or measles) for convenient access. If you have any
		questions or difficulty, please visit our
		<span
			><a class="faq" (click)="showFaq($event)" [translate]="'vax-pass.card.faq'" [href]="vaxpassFAQUrl"> FAQs</a></span
		>
	</p>
</div>
