import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

/**
 * Guard specific to simplified booking flow
 */
@Injectable()
export class SimplifiedSchedulingGuard implements CanActivate, CanActivateChild {
	/**
	 * Auth Guard Service Constructor
	 * @param router - Angular Router
	 * @param authService - Auth Service Object
	 */
	constructor(
		private router: Router,
		private authService: AuthService
	) {}

	/**
	 * Implementing canActivate interface
	 * @param route - ActivatedRouteSnapshot
	 * @param state - RouterStateSnapshot
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const params = state.root.queryParams;
		if (params && params.u) {
			sessionStorage.setItem('token', params.u);
			if (params.procedure) localStorage.setItem('proc', params.procedure);
			else localStorage.removeItem('proc');
		}
		if (params && Object.keys(params).length !== 0) {
			this.authService.setUtmParameters(params);
		}
		// check if the token is present in session storage
		if (sessionStorage.getItem('token')) {
			return true;
		}
		// redirect the user to login screen
		this.router.navigate(['login'], {
			queryParams: { returnUrl: encodeURI(state.url) },
		});
		return false;
	}

	/**
	 * Checking condition for enabling the child routes
	 * @param route - ActivatedRouteSnapshot
	 * @param state - RouterStateSnapshot
	 */
	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}
}
