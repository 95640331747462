import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
/** Preloading modules, based on the data value assigned in router */
@Injectable()
export class CustomPreloadStrategy implements PreloadingStrategy {
	/**
	 * implementing pre-load strategy
	 * @param route - Angular Route Object
	 * @param load - Load function
	 */
	preload(route: Route, load: () => any): Observable<any> {
		return route.data && route.data.preload ? load() : of(null);
	}
}
