import { Directive, HostListener, Input, ElementRef, OnChanges } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AuthService } from 'src/app/services/core/auth.service';
import { Environment } from '../../../config/environment';
import { HttpClient } from '@angular/common/http';
import { JwtHelper } from '../../../services/core/jwt-helper.service';
import {
	AnalyticsBean,
	AnalyticsButtonClickBean,
	AuthEventValueBean,
	AnalyticsUserBean,
	AnalyticsButtonClickEventValueBean,
} from 'src/app/beans/analytics/analytics-module.bean';
import { DataCarrierService } from 'src/app/services/core/data-carrier.service';
import { CookieService } from 'ngx-cookie-service';

/**
 * Directive for analytics on button clicks
 */
@Directive({
	selector: '[appEheAnalytics]',
})
export class EheAnalyticsDirective implements OnChanges {
	/** Data passing with directive */
	@Input() data: AnalyticsBean;
	/** Analytics Service */
	analyticsService: AnalyticsService;
	/** Matched user flag */
	userMatched: boolean;
	/** Eligible for PE flag */
	eligibleForPE: any;
	/** Eligible for PV flag */
	eligibleForPV: any;
	/** User employer */
	company: any;
	/** Persona Type */
	personaType: string;
	/** Analytics button click bean */
	analyticsButtonClickBean: AnalyticsButtonClickBean;

	/**
	 * EHE Analytics Service Constructor
	 * @param el - Element Reference
	 * @param authService - Auth Service
	 * @param environment - Environment service
	 * @param http - Angular Http Client
	 */
	constructor(
		private el: ElementRef,
		private authService: AuthService,
		private environment: Environment,
		private http: HttpClient,

		private cookieService: CookieService,
		private jwtService: JwtHelper,
		private dataCarrierService: DataCarrierService
	) {
		this.analyticsService = new AnalyticsService(
			this.authService,
			this.environment,
			this.cookieService,
			this.http,
			this.jwtService
		);
		this.analyticsButtonClickBean = new AnalyticsButtonClickBean();
	}

	/**
	 * Host listener to track click event on directive element
	 * @param $event
	 */
	@HostListener('click', ['$event']) onClick($event) {
		setTimeout(() => {
			this.sendData();
		}, 10);
	}

	/**
	 * On Changes, tracking the data passed from the directive element
	 * @param changes - SimpleChanges object
	 */
	ngOnChanges(changes) {
		this.data = changes.data.currentValue;
	}

	/**
	 * Retrieve profile information of user
	 * Employer, Eligible for PE, User matched
	 */
	getProfileInfo() {
		const profile = this.dataCarrierService.getChannel().getValue().profile;
		if (profile && profile.profileInfo) {
			const profileInfo = profile.profileInfo;
			this.company = profileInfo.client.name;
			this.eligibleForPE = profileInfo.eligibleForPE;
			this.eligibleForPV = profileInfo.eligibleForPV;
			this.userMatched = profileInfo.userMatched;
			this.personaType = profile.personaType;
		}
	}

	/** Capturing Dashboard adaptive card click analytics data */
	public captureAdaptiveCardButtonData(buttonData: any) {
		if (buttonData) {
			const utm = JSON.parse(localStorage.getItem('utm'));
			this.getProfileInfo();
			const ssoType = localStorage.getItem('sso-type');
			let email, epmsPID;
			if (ssoType) {
				epmsPID = this.jwtService.getEpmsId();
			} else {
				const auth = sessionStorage.getItem('auth');
				if (auth) {
					email = JSON.parse(sessionStorage.getItem('auth')).idToken.payload.email;
					epmsPID = JSON.parse(sessionStorage.getItem('auth')).idToken.payload['custom:epms-p-id'];
				}
			}
			const user = new AnalyticsUserBean();
			user.email = email;
			user.uuid = epmsPID;
			user.company = this.company;
			user.eligibleForPE = this.eligibleForPE;
			user.eligibleForPV = this.eligibleForPV;
			user.userMatched = this.userMatched;
			user.personaType = this.personaType;
			if (ssoType) {
				user.ssoType = ssoType;
			}
			this.analyticsButtonClickBean.eventLabel = 'buttonClick';
			this.analyticsButtonClickBean.eventComponent = '/dashboard';
			this.analyticsButtonClickBean.elementName = this.el.nativeElement.textContent;
			this.analyticsButtonClickBean.fullURL = window.location.href;
			this.analyticsButtonClickBean.deviceSize = window.innerWidth + ',' + window.innerHeight;
			this.analyticsButtonClickBean.analyticsData = buttonData;
			const authEventValueBean = new AuthEventValueBean();
			authEventValueBean.user = user;
			authEventValueBean.utm = utm;
			authEventValueBean.status = JSON.parse(localStorage.getItem('status'));
			this.analyticsButtonClickBean.eventValue = authEventValueBean;
			this.analyticsService.pushData(this.analyticsButtonClickBean);
		}
	}

	/* Capture Modal button data */
	public captureModalButtonData(buttonData: any) {
		const url = window.location.pathname;
		const utm = JSON.parse(localStorage.getItem('utm'));
		if (buttonData) {
			try {
				// Sending unauthorised user related analytics data to 'ANALYTICS_UNAUTH_BUCKET'
				if (
					url === '/' ||
					url === '/registration' ||
					url === '/login' ||
					url === '/forgot/password' ||
					url === '/reset/password' ||
					url === '/request/email'
				) {
					this.analyticsButtonClickBean.eventLabel = 'popup';
					this.analyticsButtonClickBean.eventComponent = url;
					this.analyticsButtonClickBean.elementName = this.el.nativeElement.textContent;
					this.analyticsButtonClickBean.fullURL = window.location.href;
					this.analyticsButtonClickBean.deviceSize = window.innerWidth + ',' + window.innerHeight;
					this.analyticsButtonClickBean.analyticsData = buttonData;
					const unAuthEventValueBean = new AuthEventValueBean();
					unAuthEventValueBean.user = new AnalyticsUserBean();
					unAuthEventValueBean.utm = utm;
					this.analyticsButtonClickBean.eventValue = unAuthEventValueBean;
					this.analyticsService.pushData(this.analyticsButtonClickBean);
					// Sending authorised user related analytics data to 'ANALYTICS_AUTH_BUCKET'
				} else {
					this.getProfileInfo();
					const ssoType = localStorage.getItem('sso-type');
					let email, epmsPID;
					if (ssoType) {
						epmsPID = this.jwtService.getEpmsId();
					} else {
						const auth = sessionStorage.getItem('auth');
						if (auth) {
							email = JSON.parse(sessionStorage.getItem('auth')).idToken.payload.email;
							epmsPID = JSON.parse(sessionStorage.getItem('auth')).idToken.payload['custom:epms-p-id'];
						}
					}
					const user = new AnalyticsUserBean();
					user.email = email;
					user.uuid = epmsPID;
					user.company = this.company;
					user.eligibleForPE = this.eligibleForPE;
					user.eligibleForPV = this.eligibleForPV;
					user.userMatched = this.userMatched;
					user.personaType = this.personaType;
					if (ssoType) {
						user.ssoType = ssoType;
					}
					this.analyticsButtonClickBean.eventLabel = 'popup';
					this.analyticsButtonClickBean.eventComponent = url;
					this.analyticsButtonClickBean.elementName = this.el.nativeElement.textContent;
					this.analyticsButtonClickBean.fullURL = window.location.href;
					this.analyticsButtonClickBean.deviceSize = window.innerWidth + ',' + window.innerHeight;
					this.analyticsButtonClickBean.analyticsData = buttonData;
					const authEventValueBean = new AuthEventValueBean();
					authEventValueBean.user = user;
					authEventValueBean.utm = utm;
					authEventValueBean.status = JSON.parse(localStorage.getItem('status'));
					this.analyticsButtonClickBean.eventValue = authEventValueBean;
					this.analyticsService.pushData(this.analyticsButtonClickBean);
				}
			} catch (e) {}
		}
	}

	/**
	 * Sending data to analytics Service based auth and unauth.
	 * @param overrideEventObj
	 */
	public sendData(overrideEventObj: AnalyticsButtonClickBean = null) {
		// console.log('analytics:\nElement:', this.el, '\noverrideEventObj:', overrideEventObj);
		const utm = JSON.parse(localStorage.getItem('utm'));
		try {
			const url = window.location.pathname;
			// Sending unauthorized user related analytics data to 'ANALYTICS_UNAUTH_BUCKET'
			if (
				url === '/' ||
				url === '/registration' ||
				url === '/login' ||
				url === '/forgot/password' ||
				url === '/reset/password' ||
				url === '/request/email' ||
				url === '/bridge/verification/code' ||
				url === '/bridge/verification/email' ||
				url === '/embed/signup' ||
				url === '/embed/login' ||
				url === '/embed/forgot-password' ||
				url === '/embed/reset-password'
			) {
				this.analyticsButtonClickBean.eventLabel = 'buttonClick';
				this.analyticsButtonClickBean.eventComponent = url;
				this.analyticsButtonClickBean.elementName = this.el.nativeElement.textContent;
				this.analyticsButtonClickBean.fullURL = window.location.href;
				this.analyticsButtonClickBean.deviceSize = window.innerWidth + ',' + window.innerHeight;
				this.analyticsButtonClickBean.analyticsData = this.data;
				const unAuthEventValueBean = new AnalyticsButtonClickEventValueBean();
				unAuthEventValueBean.user = new AnalyticsUserBean();
				unAuthEventValueBean.utm = utm;
				if (overrideEventObj) {
					Object.assign(unAuthEventValueBean, overrideEventObj.eventValue);
					Object.assign(overrideEventObj.eventValue, unAuthEventValueBean);
				}
				this.analyticsButtonClickBean.eventValue = unAuthEventValueBean;
				if (overrideEventObj) {
					Object.assign(this.analyticsButtonClickBean, overrideEventObj);
				}
				this.analyticsService.pushData(this.analyticsButtonClickBean);
			} else {
				// Sending authorized user related analytics data to 'ANALYTICS_AUTH_BUCKET'
				this.getProfileInfo();
				const ssoType = localStorage.getItem('sso-type');
				let email, epmsPID;
				if (ssoType) {
					epmsPID = this.jwtService.getEpmsId();
				} else {
					const auth = sessionStorage.getItem('auth');
					if (auth) {
						email = JSON.parse(sessionStorage.getItem('auth')).idToken.payload.email;
						epmsPID = JSON.parse(sessionStorage.getItem('auth')).idToken.payload['custom:epms-p-id'];
					}
				}
				const user = new AnalyticsUserBean();
				user.email = email;
				user.uuid = epmsPID;
				user.company = this.company;
				user.eligibleForPE = this.eligibleForPE;
				user.eligibleForPV = this.eligibleForPV;
				user.userMatched = this.userMatched;
				user.personaType = this.personaType;
				if (ssoType) {
					user.ssoType = ssoType;
				}
				this.analyticsButtonClickBean.eventLabel = 'buttonClick';
				this.analyticsButtonClickBean.eventComponent = url;
				this.analyticsButtonClickBean.elementName = this.el.nativeElement.textContent;
				this.analyticsButtonClickBean.fullURL = window.location.href;
				this.analyticsButtonClickBean.deviceSize = window.innerWidth + ',' + window.innerHeight;
				this.analyticsButtonClickBean.analyticsData = this.data;
				const authEventValueBean = new AnalyticsButtonClickEventValueBean();
				authEventValueBean.user = user;
				authEventValueBean.utm = utm;
				authEventValueBean.status = JSON.parse(localStorage.getItem('status'));
				if (overrideEventObj) {
					Object.assign(authEventValueBean, overrideEventObj.eventValue);
					Object.assign(overrideEventObj.eventValue, authEventValueBean);
				}
				this.analyticsButtonClickBean.eventValue = authEventValueBean;
				if (overrideEventObj) {
					Object.assign(this.analyticsButtonClickBean, overrideEventObj);
				}
				this.analyticsService.pushData(this.analyticsButtonClickBean);
				if (localStorage.getItem('isMatchedUser') != null) {
					if (localStorage.getItem('isMatchedUser') === 'false') {
					}
				}
			}
		} catch (e) {}
	}
}
