import { Component, OnInit } from '@angular/core';
import { AnalyticsBean } from 'src/app/beans/analytics/analytics-module.bean';
import { FormControl } from '@angular/forms';
import { VaxPassService } from '../../../services/dashboard/vax-pass.service';
import { ActivatedRoute } from '@angular/router';
import { Environment } from '../../../config/environment';
import { WordPressOverlayComponent } from '../../shared/modals/word-press-overlay/word-press-overlay.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-vax-pass',
	templateUrl: './vax-pass.component.html',
	styleUrls: ['./vax-pass.component.scss'],
})
export class VaxPassComponent implements OnInit {
	/** analytics data bean */
	analyticsData: AnalyticsBean;
	vaccineConfirmation: FormControl = new FormControl();
	showGetMyVaccineBtn: boolean = false;
	showRadioButtons: boolean = false;
	isChecked: boolean;
	isPartiallyVaccinated = false;
	isFullyVaccinated = false;
	profile: any;
	loader = false;
	step2 = false;
	errorMessage = '';
	checkBackError = false;
	maxRetry = 3;
	attemptedRetries = 0;
	vaxpassEnabled = false;
	vaxpassFAQUrl = 'https://ehe.health/vaxstatusfaq/';

	constructor(
		private vaxPassService: VaxPassService,
		private activatedRoute: ActivatedRoute,
		private environment: Environment,
		private dialog: MatDialog
	) {
		this.analyticsData = new AnalyticsBean();
		this.analyticsData.componentName = 'vax-pass';
		this.analyticsData.pageUrl = '/vaxstatus';

		// Hide vaxstatus onboarding form
		// if(this.environment.getEnvironment().VAXPASS_TOGGLE === "ON"){
		//   this.vaxpassEnabled = true;
		// }
	}

	ngOnInit() {
		this.loader = true;
		this.activatedRoute.queryParams.subscribe(params => {
			if (params['profile']) {
				this.profile = Buffer.from(JSON.parse(params['profile']), 'base64');
				this.initializeProfile();
			}
		});
	}

	onCancel() {
		this.vaccineConfirmation.setValue(false);
		this.isChecked = false;
		this.step2 = false;
		this.isFullyVaccinated = false;
	}

	hideGetMyVaccineButton() {
		this.analyticsData.id = 'Get My Vaccine Passport';
		this.showGetMyVaccineBtn = false;
		this.showRadioButtons = true;
		this.checkBackError = false;
	}

	/* get user input - whether they're fully vaccinated or not */
	getVaccineConfirmation(event) {
		this.isChecked = event.source.checked;
		this.showRadioButtons = false;
	}

	initializeProfile(cancel = false) {
		if (this.profile.status === 'NOT_VACCINATED' || this.profile.message === 'RECORD_NOT_FOUND') {
			this.checkBackError = true;
			const state = this.profile.states.vaccineStates;
			if (!this.profile.eheAdministered) {
				this.errorMessage =
					'We located your record with the state of ' +
					state +
					', but it does not include a COVID-19 vaccination event. If your vaccination was recent, please check back in a few days. Sorry for the inconvenience.';
				this.showGetMyVaccineBtn = true;
			} else {
				this.showGetMyVaccineBtn = false;
				this.errorMessage =
					'We located your record but it does not include a COVID-19 vaccination event. If your vaccination was recent, please check back in a few days. Sorry for the inconvenience.';
			}
		} else if (!this.profile.consentAccepted && !this.profile.eheAdministered) {
			this.showGetMyVaccineBtn = true;
			// redirecting to step 2 if user logs back in after editing email
			this.activatedRoute.queryParams.subscribe(params => {
				if (!cancel && params['display'] === 'consent-form') {
					this.showGetMyVaccineBtn = false;
					this.step2 = true;
				}
			});
		} else {
			if (this.profile.status === 'FULLY_VACCINATED') {
				this.isFullyVaccinated = true;
			} else if (this.profile.status === 'PARTIALLY_VACCINATED') {
				if (!this.profile.eheAdministered) {
					this.isPartiallyVaccinated = true;
				} else {
					this.isFullyVaccinated = true;
				}
			} else {
				this.showGetMyVaccineBtn = true;
			}
		}
		this.loader = false;
	}

	showFaq(event?: MouseEvent) {
		if (event && event.preventDefault) {
			event.preventDefault();
		}
		this.dialog.open(WordPressOverlayComponent, {
			width: '100vw',
			data: { url: this.vaxpassFAQUrl },
			autoFocus: false,
			panelClass: 'wp-overlay',
		});
	}
}
