<div class="content-box form-wrapper align-center migration-wrapper">
	<div class="pad-10">
		<span [translate]="'registration.migration.password-entry-header-one'">
			Welcome to an improved EHE Health digital experience!
		</span>
	</div>
	<div class="pad-10">
		<span [translate]="'registration.migration.password-entry-header-two'">
			A six-digit verification code was sent to
		</span>
		<strong>
			{{ user?.maskedEmail }}.
			<div [translate]="'registration.migration.password-entry-header-three'">
				Please use this email next time you sign in.
			</div>
		</strong>
	</div>

	<div>
		<div class="resend-link small-text link" (click)="requestVerificationCode()">
			<span
				[translate]="'registration.migration.resend-verification-code'"
				appEheAnalytics
				[data]="{
					pageUrl: '/migration/password',
					componentName: 'migration-password',
					id: 'Migration Password',
					placement: 'Migration Password Resend Code'
				}"
				appGoogleAnalytics
				[eventData]="{
					eventCategory: 'Link',
					eventAction: 'Migration-ResendCode - Link',
					eventLabel: 'Migration - PasswordEntryComponent'
				}">
				Resend Code?
			</span>
		</div>
	</div>
	<div class="pad-10">
		<span [translate]="'registration.migration.password-entry-header-four'">
			Enter the code below and create a new password.
		</span>
	</div>

	<div class="pad-10">
		<span
			class="font-red"
			*ngIf="verificationCodeError || ifLimitExceededError || ifAnyError"
			innerHTML="{{ errorMsg }}"></span>
	</div>

	<div [formGroup]="newPasswordForm" aria-autocomplete="none">
		<div class="align-center">
			<mat-form-field class="form-group">
				<mat-label>{{ 'registration.migration.verification-code' | translate }}</mat-label>
				<input matInput formControlName="code" type="text" autocomplete="off" maxlength="6" />
				<mat-error>
					<app-error-message [control]="'code'"></app-error-message>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="align-center password-wrapper">
			<mat-form-field class="form-group">
				<mat-label>{{ 'registration.migration.password' | translate }}</mat-label>
				<input
					#password
					matInput
					formControlName="password"
					type="password"
					appShowHidePassword
					maxlength="32"
					(focusin)="hidePasswordStrengthBlock = false" />
			</mat-form-field>
			<div class="strength-wrapper {{ hidePasswordStrengthBlock ? 'hide' : '' }}">
				<mat-hint class="hint" align="end" aria-live="polite"> {{ password.value.length }} / 32 </mat-hint>
				<mat-password-strength
					#passwordComponent
					[password]="password.value"
					[enableLengthRule]="true"
					[enableSpecialCharRule]="false"
					[customValidator]="passwordSpecialCharsValidatorRegExp"></mat-password-strength>
				<password-strength-info
					[passwordComponent]="passwordComponent"
					digitsCriteriaMsg="{{ 'shared.password.digits-rule' | translate }}"
					upperCaseCriteriaMsg="{{ 'shared.password.uppercase-rule' | translate }}"
					lowerCaseCriteriaMsg="{{ 'shared.password.lowercase-rule' | translate }}"
					specialCharsCriteriaMsg="{{ 'shared.password.special-character-rule' | translate }}">
				</password-strength-info>
			</div>
		</div>
		<br />
		<div class="align-center">
			<mat-form-field class="form-group">
				<mat-label>{{ 'registration.migration.password-confirm' | translate }}</mat-label>
				<input
					matInput
					formControlName="confirmPassword"
					type="password"
					appShowHidePassword
					maxlength="32"
					(focusin)="hidePasswordStrengthBlock = true" />
				<mat-error>
					<app-error-message [control]="'confirmPassword'"></app-error-message>
				</mat-error>
			</mat-form-field>
		</div>
	</div>

	<div>
		<button
			class="button button-medium"
			mat-button
			(click)="resetPassword(); hidePasswordStrengthBlock = true"
			appEheAnalytics
			[data]="{
				pageUrl: '/migration/password',
				redirectedTo: '/dashboard',
				componentName: 'migration-password',
				id: 'Migration Password',
				placement: 'Migration Password'
			}"
			appGoogleAnalytics
			[eventData]="{
				eventCategory: 'Button',
				eventAction: 'Migration-PasswordEntry - Button',
				eventLabel: 'Migration - PasswordEntryComponent'
			}">
			<span [translate]="'registration.reset-password.form.submit-button'">Submit</span>
		</button>
	</div>
</div>
