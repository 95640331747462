import * as moment from 'moment';

interface PreBookConditionMessage_Button {
	displayName: string;
	name: 'optionA' | 'optionB';
	value: 'a' | 'b';
}

export interface PreBookConditionMessage {
	messageID: number;
	message1: string;
	message2?: string;
	buttons: [PreBookConditionMessage_Button, PreBookConditionMessage_Button?];
}

export interface ISlotRequest {
	/** Primary Procedure ID */
	procedureID?: number;

	/** Sub-procedure IDs */
	subProcedureIDs?: number[];

	/** Appointment Date */
	date?: Date | moment.Moment;

	/** Method for Generating Query Params using All the Provided Parameter values */
	getParamsAsQueryString?: () => string;
}

export interface IProvidersRequest extends ISlotRequest {
	/** City for Facility Search. `state` is required with this parameter. */
	city?: string;

	/** State for Facility Search. `city` is required with this parameter. */
	state?: string;

	/** Zip code for Facility Search. `city` & `state` are not required if this parameter is provided. */
	zip?: number | string;
}

export interface IProviderSlotsRequest extends ISlotRequest {
	/** Facility ID */
	facilityID?: number;
	/** Required number of days with slots */
	requiredDaysWithSlots?: number;
	/** How many days in future to search from the given `date` in order to find slots that satisfy `requiredDaysWithSlots` */
	noOfDaysToSearch?: number;
}

export interface IMoreAvailabilityRequest extends ISlotRequest {
	/** Facility ID */
	facilityID?: number;
}

/** Schedular Providers API Request */
export class ProvidersRequest implements IProvidersRequest {
	procedureID: number;
	subProcedureIDs?: number[];
	date?: Date | moment.Moment;
	city?: string;
	state?: string;
	zip?: number | string;
	fillFacilityDistance?: boolean;
	filterSubProcedures?: boolean;

	constructor(pr?: Partial<IProvidersRequest>) {
		if (pr.procedureID) {
			this.procedureID = pr.procedureID;
		}
		if (pr.subProcedureIDs?.length) {
			this.subProcedureIDs = pr.subProcedureIDs;
		}
		if (pr.date) {
			this.date = pr.date;
		}
		if (pr.city) {
			this.city = pr.city;
		}
		if (pr.state) {
			this.state = pr.state;
		}
		if (pr.procedureID) {
			this.zip = pr.zip;
		}
		this.filterSubProcedures = false;
	}

	getParamsAsQueryString?(): string {
		if (!this.procedureID) {
			throw new Error('Missing `procedureID` param in ProvidersRequest');
		}
		if (this.zip || this.city || this.state) {
			if (!this.zip) {
				// if (!this.city && !this.state) {
				// 	throw new Error('Missing facility search param `zip` or (`city` and `state`) in ProvidersRequest');
				// } else
				if (!this.city) {
					throw new Error('Missing facility search param `zip` or (`city`) in ProvidersRequest');
				} else if (!this.state) {
					throw new Error('Missing facility search param `zip` or (`state`) in ProvidersRequest');
				}
			}
		}
		let queryParam = `procedureID=${this.procedureID}`;
		if (this.subProcedureIDs?.length) {
			queryParam += '&' + this.subProcedureIDs.map((id: any) => `subProcedureIDs=${id}`).join('&');
		}
		if (this.date) {
			if (this.date instanceof Date) {
				this.date = moment(this.date);
			}
			let dateQueryParam = '&date=';
			if (moment.isMoment(this.date)) {
				dateQueryParam += this.date.format('MM/DD/YYYY');
			} else {
				dateQueryParam += this.date;
			}
			queryParam += dateQueryParam;
		}
		if (this.zip) {
			queryParam += '&zip=' + this.zip;
		}
		if (this.city && this.state) {
			queryParam += '&city=' + this.city;
		}
		if (this.city && this.state) {
			queryParam += '&state=' + this.state;
		}
		if (this.fillFacilityDistance) {
			queryParam += '&fillFacilityDistance=' + this.fillFacilityDistance;
		}
		queryParam += '&filterSubProcedures=' + this.filterSubProcedures;
		return queryParam;
	}
}

/** Schedular ProviderSlots API Request */
export class ProviderSlotsRequest implements IProviderSlotsRequest {
	procedureID: number;
	facilityID?: number;
	subProcedureIDs?: number[];
	date?: Date | moment.Moment;
	requiredDaysWithSlots?: number;
	noOfDaysToSearch?: number;
	filterSubProcedures?: boolean;

	constructor(psr?: Partial<IProviderSlotsRequest>) {
		if (psr.procedureID) {
			this.procedureID = psr.procedureID;
		}
		if (psr.subProcedureIDs?.length) {
			this.subProcedureIDs = psr.subProcedureIDs;
		}
		if (psr.date) {
			this.date = psr.date;
		}
		if (psr.requiredDaysWithSlots) {
			this.requiredDaysWithSlots = psr.requiredDaysWithSlots;
		}
		if (psr.noOfDaysToSearch) {
			this.noOfDaysToSearch = psr.noOfDaysToSearch;
		}
		this.filterSubProcedures = false;
	}

	getParamsAsQueryString?(): string {
		if (!this.procedureID) {
			throw new Error('Missing `procedureID` param in ProviderSlotsRequest');
		}
		if (!this.facilityID) {
			throw new Error('Missing `facilityID` param in ProviderSlotsRequest');
		}
		let queryParam = `procedureID=${this.procedureID}&facilityID=${this.facilityID}`;
		if (this.subProcedureIDs?.length) {
			queryParam += '&' + this.subProcedureIDs.map((id: any) => `subProcedureIDs=${id}`).join('&');
		}
		if (this.date) {
			if (this.date instanceof Date) {
				this.date = moment(this.date);
			}
			let dateQueryParam = '&date=';
			if (moment.isMoment(this.date)) {
				dateQueryParam += this.date.format('MM/DD/YYYY');
			} else {
				dateQueryParam += this.date;
			}
			queryParam += dateQueryParam;
		}
		if (this.requiredDaysWithSlots) {
			queryParam += '&requiredDaysWithSlots=' + this.requiredDaysWithSlots;
		}
		if (this.noOfDaysToSearch) {
			queryParam += '&noOfDaysToSearch=' + this.noOfDaysToSearch;
		}
		queryParam += '&filterSubProcedures=' + this.filterSubProcedures;
		return queryParam;
	}
}

export class MoreAvailabilityRequest implements IMoreAvailabilityRequest {
	procedureID: number;
	subProcedureIDs?: number[];
	date?: Date | moment.Moment;
	facilityID?: number;
	filterSubProcedures?: boolean;

	constructor(mar?: Partial<IMoreAvailabilityRequest>) {
		if (mar.procedureID) {
			this.procedureID = mar.procedureID;
		}
		if (mar.subProcedureIDs?.length) {
			this.subProcedureIDs = mar.subProcedureIDs;
		}
		if (mar.date) {
			this.date = mar.date;
		}
		if (mar.procedureID) {
			this.facilityID = mar.facilityID;
		}
		this.filterSubProcedures = false;
	}

	getParamsAsQueryString?(): string {
		if (!this.procedureID) {
			throw new Error('Missing `procedureID` param in ProvidersSlotsRequest');
		}
		let queryParam = `procedureID=${this.procedureID}`;
		if (this.subProcedureIDs?.length) {
			queryParam += '&' + this.subProcedureIDs.map((id: any) => `subProcedureIDs=${id}`).join('&');
		}
		if (this.date) {
			if (this.date instanceof Date) {
				this.date = moment(this.date);
			}
			let dateQueryParam = '&date=';
			if (moment.isMoment(this.date)) {
				dateQueryParam += this.date.format('MM/DD/YYYY');
			} else {
				dateQueryParam += this.date;
			}
			queryParam += dateQueryParam;
		}
		if (this.facilityID) {
			queryParam += '&facilityID=' + this.facilityID;
		}
		queryParam += '&filterSubProcedures=' + this.filterSubProcedures;
		return queryParam;
	}
}
