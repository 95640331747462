<div class="tutorial-container">
	<div class="tutorial-wrapper">
		<div class="close-btn">
			<button (click)="skip()" mat-button class="arrow-left">
				<i class="fas fa-times"></i>
			</button>
		</div>

		<div class="arrow arrow-l" (click)="plusSlides(-1)" *ngIf="slideIndex > 0">
			<button mat-button class="arrow-left">
				<i class="fas fa-angle-left"></i>
			</button>
		</div>

		<div class="tutorial-content" [ngSwitch]="slideIndex">
			<div class="slider-1" *ngSwitchCase="0">
				<div class="t-col">
					<div class="col-content">
						<h4 [translate]="'tutorial.slide-1.header-title'"></h4>
						<p [translate]="'tutorial.slide-1.header-content-1'"></p>
						<p><b [translate]="'tutorial.slide-1.header-content-2'"></b></p>
					</div>
				</div>
				<div class="t-col">
					<div
						class="background-block welcome-block"
						[ngStyle]="{
							'background-image': 'url(' + webImages[slideIndex] + ')'
						}"></div>
				</div>
			</div>

			<div class="slider-2" *ngSwitchCase="1">
				<div class="t-col">
					<div class="col-content">
						<h4 [translate]="'tutorial.slide-2.header-title'"></h4>
						<span [translate]="'tutorial.slide-2.header-content-1.part-1'"> </span>
						<span class="highlight" [translate]="'tutorial.slide-2.header-content-1.part-2'"></span>
						<span [translate]="'tutorial.slide-2.header-content-1.part-3'"></span>
					</div>
				</div>
				<div class="t-col">
					<div class="background-block normal" [ngStyle]="{ 'background-image': 'url(' + webImages[1] + ')' }"></div>
				</div>
			</div>
			<ng-container *ngxPermissionsExcept="[USER_LEVEL.CATALENT_CLIENT]">
				<ng-template [ngxPermissionsOnly]="[MODULE_LEVEL.HM, MODULE_LEVEL.PW]">
					<div class="slider-3" *ngSwitchCase="2">
						<div class="t-col">
							<div class="col-content">
								<h4 [translate]="'tutorial.slide-3.header-title'"></h4>
								<span [translate]="'tutorial.slide-3.header-content-1.part-1'"></span>
								<span class="highlight" [translate]="'tutorial.slide-3.header-content-1.part-2'"></span>
								<span [translate]="'tutorial.slide-3.header-content-1.part-3'"></span>
								<span class="highlight" [translate]="'tutorial.slide-3.header-content-1.part-4'"></span>
								<span [translate]="'tutorial.slide-3.header-content-1.part-5'"></span>
							</div>
						</div>
						<div class="t-col">
							<div
								class="background-block normal"
								[ngStyle]="{ 'background-image': 'url(' + webImages[2] + ')' }"></div>
						</div>
					</div>
				</ng-template>
			</ng-container>

			<div class="slider-4" *ngSwitchCase="3">
				<div class="t-col">
					<div class="col-content">
						<h4 [translate]="'tutorial.slide-4.header-title'"></h4>
						<span class="highlight" [translate]="'tutorial.slide-4.header-content-1.part-1'"></span>
						<span [translate]="'tutorial.slide-4.header-content-1.part-2'"></span>
					</div>
				</div>
				<div class="t-col">
					<div class="background-block normal" [ngStyle]="{ 'background-image': 'url(' + webImages[3] + ')' }"></div>
				</div>
			</div>

			<div class="slider-5" *ngSwitchCase="4">
				<div class="t-col">
					<div class="col-content">
						<h4 [translate]="'tutorial.slide-5.header-title'"></h4>
						<span class="highlight" [translate]="'tutorial.slide-5.header-content-1.part-1'"></span>
						<span [translate]="'tutorial.slide-5.header-content-1.part-2'"></span>
					</div>
				</div>
				<div class="t-col">
					<div class="background-block normal" [ngStyle]="{ 'background-image': 'url(' + webImages[4] + ')' }"></div>
				</div>
			</div>

			<div class="slider-6" *ngSwitchCase="5">
				<div class="t-col">
					<div class="col-content">
						<h4 [translate]="'tutorial.slide-6.header-title'"></h4>
						<p>
							<span class="highlight" [translate]="'tutorial.slide-6.header-content-1.part-1'"></span>
							<span [translate]="'tutorial.slide-6.header-content-1.part-2'"></span>
						</p>
						<p [translate]="'tutorial.slide-6.header-content-1.part-3'"></p>
					</div>
				</div>
				<div class="t-col">
					<div
						class="background-block appt-block"
						[ngStyle]="{ 'background-image': 'url(' + webImages[5] + ')' }"></div>
				</div>
			</div>
		</div>

		<div class="tutorial-action">
			<div class="action-1 button-group {{ slideIndex !== 0 ? 'hide-section' : '' }}" *ngIf="slideIndex === 0">
				<button
					class="button t-button-blue"
					mat-button
					(click)="plusSlides(1)"
					appEheAnalytics
					[data]="{
						pageUrl: '/dashboard',
						redirectedTo: '/dashboard',
						componentName: 'Tutorial',
						id: 'Take the tour',
						placement: 'Tutorial modal'
					}">
					<span [translate]="'tutorial.slide-1.button-text'"></span>
				</button>
				<div
					class="skip"
					(click)="skip()"
					[translate]="'tutorial.slide-1.skip'"
					appEheAnalytics
					[data]="{
						pageUrl: '/dashboard',
						redirectedTo: '/dashboard',
						componentName: 'Tutorial',
						id: 'Skip',
						placement: 'Tutorial modal'
					}"></div>
			</div>
			<div
				class="action-2 {{ slideIndex === 0 || slideIndex === 5 ? 'hide-section' : '' }}"
				*ngIf="slideIndex !== 0 && slideIndex !== 5">
				<ul class="pagination">
					<li class="{{ slideIndex === 1 ? 'active' : '' }}" (click)="currentSlide(1)">
						<i class="dot"></i>
					</li>
					<ng-template [ngxPermissionsExcept]="[USER_LEVEL.CATALENT_CLIENT]" [ngxPermissionsOnly]="[MODULE_LEVEL.HM, MODULE_LEVEL.PW]">
						<li class="{{ slideIndex === 2 ? 'active' : '' }}" (click)="currentSlide(2)">
							<i class="dot"></i>
						</li>
					</ng-template>
					<li class="{{ slideIndex === 3 ? 'active' : '' }}" (click)="currentSlide(3)">
						<i class="dot"></i>
					</li>
					<li class="{{ slideIndex === 4 ? 'active' : '' }}" (click)="currentSlide(4)">
						<i class="dot"></i>
					</li>
					<li class="{{ slideIndex === 5 ? 'active' : '' }}" (click)="currentSlide(5)">
						<i class="dot"></i>
					</li>
				</ul>
			</div>

			<div class="action-3 button-group {{ slideIndex !== 5 ? 'hide-section' : '' }}" *ngIf="slideIndex === 5">
				<button
					class="button t-button-blue"
					mat-button
					(click)="finish()"
					appEheAnalytics
					[data]="{
						pageUrl: '/dashboard',
						redirectedTo: '/dashboard',
						componentName: 'Tutorial',
						id: 'Get Started',
						placement: 'Tutorial modal'
					}">
					<span [translate]="'tutorial.slide-6.button-text'"></span>
				</button>
			</div>
		</div>

		<div class="arrow arrow-r" id="right-arrow" (click)="plusSlides(1)" *ngIf="slideIndex > 0 && slideIndex !== 5">
			<button mat-button class="arrow-right">
				<i class="fas fa-angle-right"></i>
			</button>
		</div>
	</div>
</div>
