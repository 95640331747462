import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { INCENTIVE_CONSTANTS } from 'src/app/constants/scheduling.constants';
import { AppUtil } from 'src/app/utils/app.util';
import { FindAppointmentService } from '../find-appointment/find-appointment.service';

@Injectable({
	providedIn: 'root',
})
export class PromoCodeUtilsService {
	constructor(
		private readonly findAppointmentService: FindAppointmentService,
		private readonly activatedRoute: ActivatedRoute
	) {}

	static defaultNullResponse = {
		codeType: null,
		isCodeValid: null,
		promoMessage: null,
		teaserMessage: null,
		referralMessage: null,
	};

	/**
	 * Hits the actual teaser api by dynamically selecting the service, if user is coming from simplified booking,
	 * the provider in findAppointmentService points to simplifiedFindingService class otherwise normal findAppointmentService class.
	 * @param  {number} procedureID
	 * @param  {number} locationID
	 * @param  {string} promoCode
	 * @returns Observable
	 */
	getIncentiveTeaser(procedureID: number, locationID?: number, extraNavigationCodes = null): Observable<any> {
		const validKeysForPromoAndReferral = extraNavigationCodes || {};
		const fromObject = {
			procedureID: procedureID.toString(),
			locationID: locationID ? locationID.toString() : '0',
			...(validKeysForPromoAndReferral || {}),
		};
		const params = new HttpParams({
			fromObject,
		});
		return this.findAppointmentService.getIncentiveTeaser(params.toString()).pipe(
			catchError(_teaserError => {
				// sending default invalidated response
				console.error(`[${PromoCodeUtilsService.name}][${this.getIncentiveTeaser.name}][1]`, 'error from api to get collective incentive banners', _teaserError.toString());
				if (_teaserError === null) {
					// promo code was not supplied, it was a malformed api hit
					console.error(`[${PromoCodeUtilsService.name}][${this.getIncentiveTeaser.name}][2]`, 'api was hit without a non empty promo code, malformed request for get incentive teaser');
				}
				return of(PromoCodeUtilsService.defaultNullResponse);
			})
		);
	}

	pullRequiredCodesForPromoAndReferral(objectToParse: any) {
		return AppUtil.extractValidKeys({
			[INCENTIVE_CONSTANTS.REFERRAL_QUERY_KEY]: objectToParse[INCENTIVE_CONSTANTS.REFERRAL_QUERY_KEY] || null,
			[INCENTIVE_CONSTANTS.PROMO_QUERY_KEY]: objectToParse[INCENTIVE_CONSTANTS.PROMO_QUERY_KEY] || null,
			[INCENTIVE_CONSTANTS.MANUAL_CODE_KEY]: objectToParse[INCENTIVE_CONSTANTS.MANUAL_CODE_KEY] || null,
		});
	}

	/**
	 * Hits the actual promo code validation api by dynamically selecting the service, if user is coming from simplified booking,
	 * the provider in findAppointmentService points to simplifiedFindingService class otherwise normal findAppointmentService class.
	 * @param  {number} selectedProcedureId
	 * @param  {any} selectedLocation
	 * @param  {string} promoCode=''
	 * @returns Observable of response / defaultNullResponse
	 */
	validatePromoCode(
		selectedProcedureId: number,
		selectedLocation: { id: string | number },
		promoOrReferralCode = '',
		apptID = null,
		rescheduleApptID = null
	) {
		if (promoOrReferralCode) {
			// dynamically selected based on module type
			const params = new HttpParams({
				fromObject: {
					procedureID: selectedProcedureId.toString(),
					locationID: selectedLocation.id.toString(),
					[INCENTIVE_CONSTANTS.PAYLOAD_KEY]: promoOrReferralCode.toUpperCase(),
					withTeaser: `${true}`,
					...(apptID !== null && typeof apptID === 'number' ? { apptID: apptID.toString() } : {}),
					...(rescheduleApptID !== null && typeof rescheduleApptID === 'number'
						? { rescheduleApptID: rescheduleApptID.toString() }
						: {}),
				},
			});
			return this.findAppointmentService.validatePromoCodeOrReferral(params.toString()).pipe(
				catchError(_promoError => {
					console.error(`[${PromoCodeUtilsService.name}][${this.validatePromoCode.name}]`, 'error received from api to validate incentive code ', _promoError.toString());
					return of(PromoCodeUtilsService.defaultNullResponse);
				})
			);
		}
		return of(PromoCodeUtilsService.defaultNullResponse);
	}

	/**
	 *
	 * @param textToParse
	 * @returns formatted string which will not include mark tags
	 */
	parseMessageTemplate(textToParse: string) {
		if (textToParse) {
			// not a bottleneck since its not going to run too many times
			return textToParse.split('<mark>').join('').split('</mark>').join('');
		}
		return textToParse;
	}
}
