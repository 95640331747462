import { Component, Input, Host } from '@angular/core';
import { FormGroupDirective, FormGroup } from '@angular/forms';
import { ValidationUtilityService } from '../utils/validation.util/validation-utility.service';
/*** Error message component */
@Component({
	selector: 'app-error-message',
	templateUrl: './error-message.component.html',
	styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {
	/** form control name */
	@Input() control: string;
	/** style 'top' to adjust text alignment */
	@Input() top: number;
	/** style 'left'to adjust text alignment */
	@Input() left: number;
	/** form group name */
	@Input() formGroup: FormGroup;
	/** form array name */
	@Input() isFormArray: boolean;

	/**
	 * Error message component constructor
	 * @param formGroupDirective - Angular formGroup Directive
	 * @param validationService - Validation Service object
	 */
	constructor(
		@Host() private formGroupDirective: FormGroupDirective,
		private validationService: ValidationUtilityService
	) {}

	/**
	 * based on the errors in formGroup, fetching the error message using validation service
	 */
	getError() {
		if (
			this.control !== undefined &&
			this.control !== null &&
			this.formGroupDirective.form !== undefined &&
			this.formGroupDirective.form.controls[this.control] !== undefined &&
			this.formGroupDirective.form.controls[this.control] !== null
		) {
			const error = this.formGroupDirective.form.controls[this.control].errors;
			if (this.control === 'privacy' && null !== error) {
				error.privacy = true;
			}

			/** throwing error only if the control touched */
			if (
				null !== error &&
				(this.formGroupDirective.form.controls[this.control].touched || this.formGroupDirective.submitted)
			) {
				const eMessage = this.validationService.get(error);
				// console.log(eMessage);
				return eMessage;
			} else {
				return '';
			}
		}
	}

	/**
	 * throwing form control errors in the case of form array
	 */
	getFormErrors() {
		if (
			this.control !== undefined &&
			this.control !== null &&
			this.formGroup.get(this.control) !== undefined &&
			this.formGroup.get(this.control) !== null
		) {
			const error = this.formGroup.get(this.control).errors;
			/** throwing error only if the control touched */
			if (null !== error && this.formGroup.get(this.control).touched) {
				return this.validationService.get(error);
			} else {
				return '';
			}
		}
	}
}
