<app-ehe-header></app-ehe-header>
<div class="reset-section-wrapper general-wrapper activate-account-wrapper">
	<section class="container">
		<div class="row justify-content-md-center">
			<div class="col-md-8 content-box">
				<div class="auth-content-wrapper">
					<div class="auth-content-wrapper">
						<div class="auth-content">
							<app-value-proposition></app-value-proposition>
						</div>
					</div>
					<div class="content-box form-wrapper align-center" *ngIf="!accountCreationFailed && !accountExists">
						<div class="pad-10">
							<strong>
								<div [translate]="'registration.activate-account.password-entry-header-one'">Create a new password</div>
							</strong>
						</div>
						<div class="pad-10">
							<span [translate]="'registration.activate-account.password-entry-header-two'">
								Your username is your email
								<br />
								<strong>{{ getMaskedEmail() }}</strong>
							</span>
						</div>
						<div [formGroup]="activateAccountForm" aria-autocomplete="none">
							<div class="align-center password-wrapper">
								<mat-form-field class="form-group">
									<mat-label>{{ 'registration.activate-account.password' | translate }}</mat-label>
									<input
										#password
										matInput
										formControlName="password"
										type="password"
										appShowHidePassword
										maxlength="32"
										(focusin)="hidePasswordStrengthBlock = false" />
								</mat-form-field>
								<div class="strength-wrapper {{ hidePasswordStrengthBlock ? 'hide' : '' }}">
									<mat-hint class="hint" align="end" aria-live="polite"> {{ password.value.length }} / 32 </mat-hint>
									<mat-password-strength
										#passwordComponent
										[password]="password.value"
										[enableLengthRule]="true"
										[enableSpecialCharRule]="false"
										[customValidator]="passwordSpecialCharsValidatorRegExp"></mat-password-strength>
									<password-strength-info
										[passwordComponent]="passwordComponent"
										digitsCriteriaMsg="{{ 'shared.password.digits-rule' | translate }}"
										upperCaseCriteriaMsg="{{ 'shared.password.uppercase-rule' | translate }}"
										lowerCaseCriteriaMsg="{{ 'shared.password.lowercase-rule' | translate }}"
										specialCharsCriteriaMsg="{{ 'shared.password.special-character-rule' | translate }}">
									</password-strength-info>
								</div>
							</div>
							<div class="align-center mt-3">
								<mat-form-field class="form-group">
									<mat-label>{{ 'registration.activate-account.password-confirm' | translate }}</mat-label>
									<input
										matInput
										formControlName="confirmPassword"
										type="password"
										appShowHidePassword
										maxlength="32"
										(focusin)="hidePasswordStrengthBlock = true" />
									<mat-error>
										<app-error-message [control]="'confirmPassword'"></app-error-message>
									</mat-error>
								</mat-form-field>
							</div>
						</div>
						<div>
							<button
								class="button button-medium"
								mat-button
								(click)="createAccount(); hidePasswordStrengthBlock = true"
								appEheAnalytics
								[data]="{
									pageUrl: '/activate',
									redirectedTo: '/dashboard',
									componentName: 'activate-account',
									id: 'Activate Account',
									placement: 'Activate Account'
								}"
								appGoogleAnalytics
								[eventData]="{
									eventCategory: 'Button',
									eventAction: 'ActivateAccount - Button',
									eventLabel: 'Activate Account - SetPassword'
								}">
								<span [translate]="'registration.activate-account.submit-button'">Submit</span>
							</button>
						</div>
					</div>
					<div class="content-box form-wrapper align-center" *ngIf="accountCreationFailed || accountExists">
						<div tabindex="0" class="pt-3 align-center" [innerHTML]="errorMessage"></div>
						<div>
							<button
								class="button button-medium"
								mat-button
								appEheAnalytics
								[data]="{
									pageUrl: '/activate',
									redirectedTo: '/login',
									componentName: 'activate-account',
									id: 'Activate Account',
									placement: 'Activate Account'
								}"
								appGoogleAnalytics
								[eventData]="{
									eventCategory: 'Button',
									eventAction: 'ActivateAccount - Button',
									eventLabel: 'Activate Account - Login'
								}">
								<span [translate]="'registration.activate-account.login-button'">Login</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
<app-ehe-spinner></app-ehe-spinner>
<app-ehe-footer [socialNetwork]="false"></app-ehe-footer>
