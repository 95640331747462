import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationUtilityService } from 'src/app/modules/shared/utils/validation.util/validation-utility.service';
import { AppUtil } from 'src/app/utils/app.util';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { SpinnerComponent } from 'src/app/modules/shared/spinner/spinner.component';
import { ErrorMessages } from 'src/app/config/error-messages';
import { MigrationStatusService } from 'src/app/services/migration/migration-status.service';

/**
 * MigrationEmailEntryComponent - collects email from user when migration status is failed
 */
@Component({
	selector: 'app-migration-email-entry',
	templateUrl: './migration-email-entry.component.html',
	styleUrls: ['./migration-email-entry.component.scss'],
})
export class MigrationEmailEntryComponent implements OnInit, OnDestroy {
	/** Holds list of subscriptions */
	subscriptions: Subscription[] = [];

	/** Email form */
	enterEmailForm: FormGroup;

	/** user migration object */
	user: any;

	/** Error message */
	errorMsg: any;

	/** Holds return url value */
	returnUrl: string = '';

	/** Flag indicates that the control came from signup flow */
	fromSignup: boolean = false;

	/**
	 * Migration email component constructor
	 * @param activatedRoute - holds details about the route
	 * @param spinner - spinner component
	 * @param authService - holds cognito related services
	 * @param errorMessages - holds possible error messages
	 * @param router - angular router
	 * @param config - config for url's
	 * @param migrationStatusService - holds services related to migration service
	 */
	constructor(
		private activatedRoute: ActivatedRoute,
		private spinner: SpinnerComponent,
		private errorMessages: ErrorMessages,
		private router: Router,
		private migrationStatusService: MigrationStatusService
	) {}

	/**
	 * On component initialization
	 */
	ngOnInit(): void {
		this.subscriptions.push(
			this.activatedRoute.queryParams.subscribe(params => {
				this.user = JSON.parse(atob(params['user']));
				if (params['fromSignup'] === 'true') {
					this.fromSignup = true;
				}
				this.returnUrl = params['returnUrl'];
			})
		);
		this.setUpEmailAddressForm();
	}

	/**
	 * Initializes email form
	 */
	private setUpEmailAddressForm(): void {
		this.enterEmailForm = new FormGroup({
			email: new FormControl(
				this.fromSignup ? this.user.email : '',
				Validators.compose([Validators.required, ValidationUtilityService.emailValidator])
			),
		});
	}

	/**
	 * Checks if email exists in cognito - if not updates email on file in EPMS
	 */
	updateEmailAndNavigate(): void {
		this.spinner.startLoader();
		this.errorMsg = undefined;
		this.enterEmailForm.get('email').setErrors(null);

		const updatedEmail = AppUtil.getFormattedEmail(this.enterEmailForm.get('email').value);
		if (AppUtil.isValidEmail(updatedEmail)) {
			// Checks if email is present in cognito
			this.migrationStatusService.checkExistingUser(updatedEmail).subscribe(
				response => {
					// If not stores email in EPMS
					if (response === false) {
						const body = {
							epmsPID: this.user.epmsPID,
							uuid: this.user.uuid,
							email: updatedEmail,
						};
						this.user.email = updatedEmail;
						this.migrationStatusService.migrate(body).subscribe(
							res => {
								const navigationExtras: NavigationExtras = {
									queryParams: {
										user: btoa(JSON.stringify(this.user)),
										signup: true,
										returnUrl: this.returnUrl,
									},
								};
								this.router.navigate(['/migration/password'], navigationExtras);
								this.spinner.stopLoader();
							},
							err => {
								this.errorMsg = 'There was an error in the migration process to myEHE';
								this.spinner.stopLoader();
							}
						);
					} else {
						// Else throws error
						this.spinner.stopLoader();
						this.errorMsg = 'Email is already in use. Please enter another email address.';
						this.enterEmailForm.controls.email.setErrors({ invalid: true });
					}
				},
				error => {
					// console.log(error);
					this.spinner.stopLoader();
				}
			);
		} else {
			this.enterEmailForm.controls.email.setErrors({ invalid: true });
			this.errorMsg = 'Please enter a valid email address';
			this.spinner.stopLoader();
		}
	}

	/**
	 * On component destruction - removes subscriptions
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
		this.subscriptions = [];
	}
}
