import { Injectable } from '@angular/core';
import { ServiceRouter } from 'src/app/services/core/http-service-router.service';
import { Environment } from 'src/app/config/environment';
import { Config } from 'src/app/config/config';
import { Observable } from 'rxjs';
import { METHOD_TYPE } from 'src/app/constants/service.constants';

@Injectable({
	providedIn: 'root',
})
export class MigrationStatusService {
	constructor(
		private serviceRouter: ServiceRouter,
		private environment: Environment,
		private config: Config
	) {}

	getMigrationStatus(queryParam): Observable<any> {
		const self = this;
		return self.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${self.config.url.migration.status}?user=${queryParam}`,
			METHOD_TYPE.GENERAL_GET,
			''
		);
	}

	updateMigrationStatus(body): Observable<any> {
		const self = this;
		return self.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${self.config.url.migration.status}`,
			METHOD_TYPE.GENERAL_POST,
			body
		);
	}

	checkExistingUser(email): Observable<any> {
		const self = this;
		return self.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${self.config.url.migration.checkExistingUser}?email=${email}`,
			METHOD_TYPE.GENERAL_GET,
			''
		);
	}

	updateAccount(epmsPID: any, uuid: string, email: string, password: string): Observable<any> {
		const self = this;
		return self.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${self.config.url.registrationApi.createAccount}/${epmsPID}`,
			METHOD_TYPE.GENERAL_PUT,
			{
				uuid: uuid,
				email: email,
			}
		);
	}

	migrate(body): Observable<any> {
		const self = this;
		return self.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			self.config.url.registrationApi.migrateAccount,
			METHOD_TYPE.GENERAL_POST,
			body
		);
	}
}
