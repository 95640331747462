import { Injectable, Injector, InjectionToken } from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ToasterComponent } from '../../modules/shared/toaster/toaster.component';
import { Overlay } from '@angular/cdk/overlay';
import { ToastData, TOAST_DATA } from '../../modules/shared/toaster/toaster.model';

/**
 * Service to show or hide toaster
 */
@Injectable({
	providedIn: 'root',
})
export class ToasterService {
	/** Over lay reference */
	overlayRef: any;

	/**
	 * Toaster service constructor
	 * @param overlay Overlay
	 */
	constructor(
		private overlay: Overlay,
		private injector: Injector
	) {
		this.overlayRef = this.overlay.create();
	}

	/**
	 * Shows the toaster
	 */
	show(data: ToastData, timeToFade: any = 8000) {
		this.hide();
		const toastPortal = new ComponentPortal(ToasterComponent, null, this.createInjector(data));
		this.overlayRef = this.overlay.create();
		this.overlayRef.attach(toastPortal);
		// Hides the toaster after 8 seconds
		setTimeout(() => {
			this.overlayRef.dispose();
		}, timeToFade);
	}

	/**
	 * Hides the toaster on click
	 */
	hide() {
		try {
			this.overlayRef.dispose();
		} catch (exception) {
			console.error(`[${ToasterService.name}][${this.hide.name}]`, 'Error occurred while hiding toast');
		}
	}

	/**
	 * To inject data into component portal
	 * @param dataToPass
	 */
	createInjector(dataToPass): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(TOAST_DATA, dataToPass);
		return new PortalInjector(this.injector, injectorTokens);
	}
}
