<div id="header">
	<div class="headercol" id="EheLogo">
		<img src="/assets/images/EHE.svg" alt="EHE" />
	</div>
	<!-- <div class="headercol" id="middle"></div> -->
	<div class="headercol" id="PwcLogo">
		<img src="/assets/images/PwC.svg" alt="PwC" />
	</div>
</div>
<div class="splash gradient" id="splash">
	<div class="splash container">
		<div class="splash content" id="form">
			<div id="ssoBackground">
				<div id="sso">
					<h4>Have a PwC Email?</h4>
					<p>Use your PwC email to log in with just one click</p>
					<!-- <%= button_tag "Let's Go!" , onclick: "allowCancel = true;window.location.href='#{sso_path(account_id: @client.sso_account.id )}';return false", class: 'button-emph option' %> -->
					<button class="button-emph option" onclick="location.href='/sso/saml;'">Let's Go!</button>
				</div>
			</div>
			<app-login></app-login>
		</div>
		<div class="splash content" id="copy">
			<h2>EHE Health provides personalized prevention</h2>
			<p>We're here to help you eat, think, and move for better health, productivity, and longevity.</p>
			<p>Get your preventive exam at no additional cost if you are enrolled in a PwC UnitedHealthcare medical plan.*</p>
		</div>
	</div>
</div>
<div class="small-12">
	<h3 class="ProximaNova-Bold text-center">What they're saying about EHE Health.</h3>
</div>
<div class="grid-x grid-padding-x landing-client-review" id="clientReview">
	<section class="large-4">
		<div class="grid-padded-right">
			<img src="/assets/images/client_photo1.jpg" alt="Client Photo" class="sec-img" />
			<p class="section-copy section-text">
				"I was extremely impressed. This was my first visit. I wish I had scheduled it sooner!"
			</p>
		</div>
	</section>
	<section class="large-4">
		<div class="grid-padded-right">
			<img src="/assets/images/client_photo2.jpg" alt="Client Photo" class="sec-img" />
			<p class="section-copy section-text">
				"This was the friendliest, most attentive … in-depth medical appointment I've ever had in my life!"
			</p>
		</div>
	</section>
	<section class="large-4">
		<div class="grid-padded-right">
			<img src="/assets/images/client_photo3.jpg" alt="Client Photo" class="sec-img" />
			<p class="section-copy section-text">
				"The staff, the doctors, the food, the facility is amazing … The best thing my company has given to its
				employees."
			</p>
		</div>
	</section>
</div>
<div class="vid-container" id="video">
	<iframe
		src="https://player.vimeo.com/video/334022196"
		style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
		frameborder="0"
		allow="autoplay; fullscreen"
		allowfullscreen>
	</iframe>
</div>
<div class="landing-comparison-table" id="comparisonChart">
	<div class="chartIntro">
		<h2>Get more than the standard "doctor's visit".</h2>
		<p>
			The EHE Health exam goes far beyond a typical "physical". It gives you personalized insight so you can take action
			and reach your goals. It is the only exam to offer this full range of preventive services. You receive exactly
			what you need based on your age, gender, and risk factors.
		</p>
	</div>
	<div id="chartCont">
		<div class="chartHead">
			<div class="colTitle" id="ehe">
				<h4>EHE Health Experience</h4>
			</div>
			<div class="colTitle" id="Regular">
				<h4>Regular Physical</h4>
			</div>
		</div>
		<div class="chartBody">
			<div class="section">
				<div class="sectTitle">
					<h5>Health Coaching</h5>
				</div>
				<div class="sectComp">
					<div class="comp">
						<p>Unlimited</p>
					</div>
					<div class="comp">
						<p>Not included</p>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="sectTitle">
					<h5>Preventive Services</h5>
				</div>
				<div class="sectComp">
					<div class="comp">
						<p>100% of recommended screenings</p>
					</div>
					<div class="comp">
						<p>On average, just 54% of recommend screenings</p>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="sectTitle">
					<h5>Physician Consultation</h5>
				</div>
				<div class="sectComp">
					<div class="comp">
						<p>30-60 minutes</p>
					</div>
					<div class="comp">
						<p>Avg. 17-24 minutes</p>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="sectTitle">
					<h5>Cardiovascular Health</h5>
				</div>
				<div class="sectComp">
					<div class="comp">
						<p>Complete consultation with certified staff, full inoculations</p>
					</div>
					<div class="comp">
						<p>None</p>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="sectTitle">
					<h5>Referral Services</h5>
				</div>
				<div class="sectComp">
					<div class="comp">
						<p>Full</p>
					</div>
					<div class="comp">
						<p>Limited</p>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="sectTitle">
					<h5>Vaccines</h5>
				</div>
				<div class="sectComp">
					<div class="comp">
						<p>10 vaccines offered</p>
					</div>
					<div class="comp">
						<p>> 50% of physicians refer adult patients elsewhere for vaccinations</p>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="sectTitle">
					<h5>Measurements for Mental Wellbeing</h5>
				</div>
				<div class="sectComp">
					<div class="comp">
						<p>11 risk-based assessments provided</p>
					</div>
					<div class="comp">
						<p>5% of office-based primary care physicians provide depression screenings</p>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="sectTitle">
					<h5>Work-Life Balance</h5>
				</div>
				<div class="sectComp">
					<div class="comp">
						<p>13-question assessment</p>
					</div>
					<div class="comp">
						<p>Varies by clinician</p>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="sectTitle">
					<h5>Travel Medicine</h5>
				</div>
				<div class="sectComp">
					<div class="comp">
						<p>11 risk-based screenings offered</p>
					</div>
					<div class="comp">
						<p>Varies by clinician</p>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="sectTitle">
					<h5>Musculoskeletal Assessment</h5>
				</div>
				<div class="sectComp">
					<div class="comp">
						<p>Included</p>
					</div>
					<div class="comp">
						<p>Not Included</p>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="sectTitle">
					<h5>Nutritional Assessment</h5>
				</div>
				<div class="sectComp">
					<div class="comp">
						<p>Included</p>
					</div>
					<div class="comp">
						<p>Varies by clinician</p>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="sectTitle">
					<h5>Stage of Change Assessment</h5>
				</div>
				<div class="sectComp">
					<div class="comp">
						<p>Included</p>
					</div>
					<div class="comp">
						<p>Not Included</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="chartFooter">
		<div class="footerTitle">
			<h4>Additional services provided by EHE Health that are not included in your regular physical:</h4>
		</div>
		<div class="footerCont">
			<div class="footContSect">
				<p>CT Calcium Score</p>
				<p>Abdominal Aorta</p>
				<p>Ultrasound</p>
				<p>DEXA Scan</p>
			</div>
			<div class="footContSect">
				<p>Lung CT Scan</p>
				<p>Peripheral Artery</p>
				<p>Disease Assessment</p>
				<p>Mammography</p>
			</div>
			<div class="footContSect">
				<p>Colonoscopy</p>
				<p>FIT Test</p>
				<p>Pap Test</p>
			</div>
		</div>
	</div>
</div>
<div id="disclaimer">
	<p>
		*If at the time of your exam you are eligible, and you are enrolled in a PwC UnitedHealthcare plan.
		<br />
		<br />
		Who is eligible: Partners, staff and their spouse/domestic partner with primary coverage under the PwC
		UnitedHealthcare medical plan can use EHE Health for an annual preventive exam at no additional cost (10 months must
		elapse between EHE Health exams). If under age 30, you may use EHE Health once every three years. Coordination of
		benefits with other group plans will apply. For those enrolled in the Cigna Global Health Benefits Plan, or if you
		are eligible to participate in a PwC medical plan but not enrolled, you and your spouse/domestic partner may still
		utilize EHE Health; however, you must pay the full cost of the exam. EHE Health is not available to residents of
		Puerto Rico. For information, contact EHE Health at 800.362.8671 to speak to an Appointment Specialist.
	</p>
</div>

<script src="https://player.vimeo.com/api/player.js"></script>

<app-ehe-footer> </app-ehe-footer>
