import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, timer } from 'rxjs';
import { USER_LEVEL } from './../../constants/user.level.constants';
import { DataCarrierService } from './../../services/core/data-carrier.service';
import { Environment } from '../../config/environment';
import { APP_USER_PERMISSIONS } from 'src/app/constants/app-user-permissions.constants';
import { AuthService } from 'src/app/services/core/auth.service';

/**
 * Tutorial Component
 */
@Component({
	selector: 'app-tutorial',
	templateUrl: './tutorial.component.html',
	styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent implements OnInit {
	/** Images used in Tutorial */
	webImages = [
		'/assets/images/tutorial/DashboardTutorial_01.png',
		'/assets/images/tutorial/WelcomePanel_02.png',
		'/assets/images/tutorial/MentorshipPanel_01.png',
		'/assets/images/tutorial/HealthSnapshotPanel_01.png',
		'/assets/images/tutorial/FeedTutorial_01.png',
		'/assets/images/tutorial/SchedulingTutorial_01.png',
	];

	/** Tutorial slide timer subscription object */
	slidingTimerSubscription: Subscription;
	/** Slide index */
	slideIndex = 0;
	/** User level constants */
	USER_LEVEL = USER_LEVEL;
	MODULE_LEVEL = APP_USER_PERMISSIONS;
	catalentClient = false;

	/**
	 * Tutorial Constructor
	 * @param dialogRef - Material Dialog Reference
	 * @param cdr - Angular Change detection
	 */
	constructor(
		public dialogRef: MatDialogRef<TutorialComponent>,
		private cdr: ChangeDetectorRef,
		private dataCarrierService: DataCarrierService,
		private environment: Environment,
		private readonly authService: AuthService
	) {}

	/**
	 * Component initialization
	 */
	ngOnInit() {
		const profile = this.dataCarrierService.getChannel().getValue().profile;
		if (profile && profile.profileInfo != null) {
			if (profile.profileInfo.client.id === this.environment.getEnvironment().CATALENT_CLIENTS) {
				this.catalentClient = true;
			}
		}
	}

	/**
	 * current slide number
	 * @param n
	 */
	currentSlide(n: number): void {
		this.slideIndex = n;
		if (this.slideCheck(5)) {
			this.stopSliding();
		} else {
			this.startSliding();
		}
		this.cdr.detectChanges();
		this.cdr.reattach();
	}

	/**
	 * Add n to the slide number
	 * to start or stop sliding
	 * @param n
	 */
	async plusSlides(n: number): Promise<any> {
		this.slideIndex += n;
		const permissionForHM = await this.authService.hasPermission(this.MODULE_LEVEL.HM);
		const permissionForPW = await this.authService.hasPermission(this.MODULE_LEVEL.PW);
		if ((this.catalentClient || !permissionForHM || !permissionForPW)  && this.slideCheck(2)) {
			if (n % 2 > 0) {
				this.slideIndex += 1;
			} else {
				this.slideIndex -= 1;
			}
		}
		if (this.slideCheck(1)) {
			this.startSliding();
		} else if (this.slideCheck(5)) {
			this.stopSliding();
		}
		this.cdr.detectChanges();
		this.cdr.reattach();
	}

	/**
	 * Check slide number
	 * If slide 1 start sliding
	 * If slide 5 stop sliding
	 * @param n
	 */
	slideCheck(n: number): boolean {
		return this.slideIndex === n;
	}

	/**
	 * Skips the tutorial slides
	 */
	skip(): void {
		this.dialogRef.close(0);
	}

	/**
	 * On finishing the tutorial dialog is cloed
	 */
	finish(): void {
		this.dialogRef.close(1);
	}

	/**
	 * Starts tutorial sliding
	 */
	startSliding(): void {
		this.stopSliding();
		this.slidingTimerSubscription = timer(10000, 10000).subscribe(t => this.plusSlides(1));
	}

	/**
	 * Stops tutorial sliding
	 */
	stopSliding(): void {
		if (this.slidingTimerSubscription) {
			this.slidingTimerSubscription.unsubscribe();
		}
	}
}
