import { NativeDateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

/** Custom Date adapter setting the Date format as MM/DD/YYYY */
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
	/**
	 *Formatting date input in material date picker to MM/DD/YYYY format
	 * @param date Date object
	 * @param displayFormat - Date format
	 */
	override format(date: Date, displayFormat: object): string {
		moment.locale('en'); // Choose the locale
		return moment(date).format(displayFormat.toString());
	}
}
