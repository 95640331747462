/** Profile info bean */
export class ProfileInfoBean {
	/** holds PatientID of user */
	patientID = '';
	/** holds firstname of user */
	firstName = '';
	/** holds last name of user */
	lastName = '';
	/** holds preferred name of user */
	preferredName = '';
	/** holds date of birth of user */
	dateOfBirth = '';
	/** holds user gender */
	gender = '';
	/** holds user cellphone info */
	cellPhone = '';
	/** holds address details of user - address 1 */
	address = '';
	/** holds address details of user - address 2 */
	address2 = '';
	/** holds address details of user - zip code */
	zipCode = '';
	/** holds address details of user - city */
	city = '';
	/** holds address details of user - state */
	state = '';
	/** holds user uuid - unique id details */
	uuid = '';
	/** holds flag to check if user is matched */
	userMatched = false;
	/** holds flag to check if user is eligible to book pe - hubbell users */
	eligibleForPE = false;
	/** holds flag to check if user is eligible to book pv */
	eligibleForPV? = false;
	/** holds flag to check is ssn verification is necessary - hubbell users */
	ssnVerificationRequired = false;
	/** holds count of failure attempts for ssn verification */
	ssnFailureAttempts: number;
	/** holds client info - employer */
	client: Client;
	email? = '';
}

/** Employer Info Bean */
export class Client {
	/** name of the employer */
	name: string;
	/** employer id */
	id: string;
}

/** Email information bean */
export class EmailInfoBean {
	/** holds id */
	id = '';
	/** holds email details */
	email = '';
	/** to check if email is primary */
	primary = false;
	/** to check if email is being added for first time */
	isNew = true;
	/** to check if email is verified or not */
	emailVerified = false;
	/** uuid of the user */
	uuid = '';
	/** email type - either primary or additional */
	emailType = '';
}

/** Password Info Bean */
export class SecurityInfoBean {
	/** holds password */
	password = '';
}

/** Account preferences bean */
export class AccountPreferencesBean {
	/** to check if email notification has to be enabled or not */
	emailNotification: boolean;
	/** to check if sms notification has to be enabled or not */
	smsNotification: boolean;
	/** to check if notification to receive news has to be enabled or not */
	receiveHealthNews: boolean;
}

/** Email Verification Bean */
export class VerificationBean {
	/** 6-digit verification code */
	verificationCode: string;
	/** email to be verified */
	emailAddress: string;
	/** uuid of the user */
	uuid: string;
	/** email type - either primary or additional */
	emailType: string;
	/** Additional data that holds info about utm & return url */
	additionalData: any;
	/** Flag to say if request comes from registration flow or not */
	registration: boolean;
}

/** Holds profile related information */
export class ProfileRelatedInfoBean {
	/** profile info */
	profileInfo: ProfileInfoBean;
	/** number of upcoming appointments */
	confirmedAppointmentCount?: number;
	/** number of unread messaged */
	unreadMessageCount?: number;
	/** reports availability status */
	examResultAvailable?: boolean;
	/** user match status */
	noMatch?: boolean;
	/** Persona type */
	personaType?: string;
}
