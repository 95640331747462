<div *ngIf="loader" class="loader">
	<i class="fas fa-spinner fa-pulse"></i>
	<p [translate]="'vax-pass.loading'">Pulling up your vaccine record. Hang tight!</p>
</div>
<div class="vax-pass-consent-container" *ngIf="step2 && !loader">
	<div *ngIf="error">
		<div class="errorMsg">
			<div>
				{{ errorMessage }}
				<span [translate]="'vax-pass.banner.additional-details'"
					>For additional details and guidance please visit
					<a class="faq" (click)="showFaq($event)" [translate]="'vax-pass.banner.vaxstatus-faq'" [href]="vaxpassFAQUrl"
						>VaxStatus FAQ.</a
					></span
				>
			</div>
		</div>
	</div>
	<ng-container>
		<h3 [translate]="'vax-pass.form.step-2-heading'">Step 2 of 2: My Details</h3>
		<div class="read-only">
			<div class="row">
				<div class="col-md-6">
					<span [translate]="'vax-pass.form.name'">Name</span><br />
					<span>{{ profile?.firstName }} {{ profile?.lastName }}</span>
				</div>
				<div class="col-md-6">
					<span [translate]="'vax-pass.form.dob'">Date of birth</span><br />
					<span>{{ profile?.dateOfBirth }}</span>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<span [translate]="'vax-pass.form.email-address'">Email Address</span>
					<span
						class="link"
						[routerLink]="['/account']"
						[queryParams]="{ action: 'edit-email' }"
						[translate]="'vax-pass.form.edit'"
						appEheAnalytics
						[data]="{
							pageUrl: '/vaxstatus',
							redirectedTo: '/account',
							componentName: 'vax pass consent form',
							id: 'update email',
							placement: 'consent form'
						}">
						(Edit)</span
					><br />
					<span>{{ profile?.email }}</span>
					<ng-container *ngIf="!profile.email">
						<span class="required-field" [translate]="'vax-pass.form.required'">This field is required</span>
					</ng-container>
				</div>
				<div class="col-md-6">
					<span [translate]="'vax-pass.form.cellphone'">Cellphone</span>
					<span
						class="link"
						(click)="editProfileInfo()"
						[translate]="'vax-pass.form.edit'"
						appEheAnalytics
						[data]="analyticsData"
						>(Edit)</span
					><br />
					<span>{{ profile?.cellPhone | maskPhone }}</span>
					<ng-container *ngIf="!profile.cellPhone">
						<span class="required-field" [translate]="'vax-pass.form.required'">This field is required</span>
					</ng-container>
				</div>
			</div>
		</div>

		<form [formGroup]="consentForm">
			<div class="row">
				<div class="col-sm-8">
					<h5 [translate]="'vax-pass.form.vaccine-state-selection'">Where did you receive the COVID-19 vaccine?</h5>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-8">
					<mat-form-field class="select-width">
						<mat-label>{{ 'account.profile.sections.personal-information.form.state' | translate }}</mat-label>
						<mat-select id="vaxpass-states" appAutofocus formControlName="vaccineState" required>
							<ng-container *ngFor="let state of states | keyvalue; last as isLast">
								<mat-option *ngIf="!isLast" [value]="state">{{ state.value }}</mat-option>
							</ng-container>
						</mat-select>
						<mat-error>
							<app-error-message [control]="'state'"></app-error-message>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="col-sm-10">
					<i>
						<b><span [translate]="'vax-pass.form.note'">Note:</span></b>
						<span [translate]="'vax-pass.form.vaccine-state-description'">
							Please select the state where you received your COVID-19 vaccination. If you received vaccine doses in
							multiple states, we may not be able to retrieve your full record at this time.</span
						>
						<a
							class="faq"
							(click)="showFaq($event)"
							[translate]="'vax-pass.banner.vaxstatus-faq'"
							[href]="vaxpassFAQUrl"
							>VaxStatus FAQ.</a
						>
					</i>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<div class="consent-message-container">
						<div class="consent-message" [innerHTML]="consent"></div>
					</div>

					<div class="consent-checkbox">
						<mat-checkbox formControlName="isConsented" (change)="consentChanged($event)">
							<span [translate]="'vax-pass.form.consent-text'">I consent to the above terms</span>
						</mat-checkbox>
					</div>
				</div>
			</div>
		</form>

		<div class="button-wrapper">
			<div class="row">
				<div class="col-md-6">
					<button
						class="button"
						mat-button
						[disabled]="!consentForm.valid || !enableNextButton"
						(click)="submitConsent()"
						appEheAnalytics
						[data]="analyticsData"
						appGoogleAnalytics
						[eventData]="{
							eventCategory: 'Button',
							eventAction: 'Click - Next',
							eventLabel: 'VaxStatus - Next'
						}">
						<span [translate]="'vax-pass.form.next-btn'">Next</span>
					</button>
					<button
						class="button"
						mat-stroked-button
						(click)="cancelConsentForm()"
						appEheAnalytics
						[data]="analyticsData">
						<span [translate]="'vax-pass.form.cancel-btn'">Cancel</span>
					</button>
				</div>
			</div>
		</div>
	</ng-container>
</div>
