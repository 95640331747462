<div class="{{ modalWrapperClass }}" id="ehe-modal">
	<div class="ehe-modal">
		<div class="title-top-border"></div>
		<div class="ehe-modal-header text-18">
			{{ title }}
			<!-- <div class="close" (click)="closeDialog()"><i class="fa fa-times close" style="color:black" aria-hidden="true"></i></div> -->
		</div>
		<div class="ehe-modal-body">
			<mat-dialog-content>
				<ng-content></ng-content>
				<p *ngIf="content" [innerHTML]="content"></p>
			</mat-dialog-content>
		</div>
		<div class="ehe-modal-footer">
			<div class="button-group d-flex justify-content-center" *ngIf="!alignCenterButtonCenter">
				<button class="button button-medium" mat-button (click)="submit()">{{ button1 }}</button>
				<button class="button secondary-btn button-medium mat-stroked-button" mat-button (click)="cancel()">
					{{ button2 }}
				</button>
			</div>

			<div class="d-flex justify-content-center" *ngIf="alignCenterButtonCenter">
				<button class="button button-medium" mat-button matDialogClose (click)="submit()">{{ button1 }}</button>
			</div>
		</div>
	</div>
</div>
