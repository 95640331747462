<app-ehe-header></app-ehe-header>
<div class="reset-section-wrapper general-wrapper">
	<section class="container">
		<div class="row justify-content-md-center">
			<div class="col-md-8 content-box">
				<div class="auth-content-wrapper">
					<div class="auth-content-wrapper">
						<div class="auth-content">
							<app-value-proposition></app-value-proposition>
						</div>
					</div>
					<router-outlet></router-outlet>
				</div>
			</div>
		</div>
	</section>
</div>
<app-ehe-spinner></app-ehe-spinner>
<app-ehe-footer [socialNetwork]="false"></app-ehe-footer>
