/** Class to check Client type and SSN requirement */
export class ClientUtil {
	/** Function to check if a specific client is associated with SSN */
	public static checkClientType(clientName, ssnAssociatedClients): boolean {
		let clients = [];
		if (typeof ssnAssociatedClients === 'string' && clientName) {
			clients = ssnAssociatedClients.split(',') || [];
			return clients.includes(clientName.name.toUpperCase());
		} else {
			return false;
		}
	}
}
