import { Injectable } from '@angular/core';
import { ServiceRouter } from '../../core/http-service-router.service';
import { METHOD_TYPE } from 'src/app/constants/service.constants';
import { Config } from '../../../config/config';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { Environment } from '../../../config/environment';
import * as moment from 'moment-timezone';
import { MoreAvailabilityRequest, PreBookConditionMessage, ProviderSlotsRequest, ProvidersRequest } from 'src/app/beans/schedular/schedular.bean';
import { Procedure, ProvidersResponse, SchedulingResponse, SelectableTimeSlot, SlotsList } from 'src/app/modules/shared/types';
import { Location } from 'src/app/beans/dashboard/dashboard-module.bean';
import { SchedulingAppointmentBean } from 'src/app/modules/scheduling/types.scheduling';

/**
 * Find appointment service
 */
@Injectable()
export class FindAppointmentService {
	private proceduresSource: BehaviorSubject<Procedure[]>;
	procedures$: Observable<Procedure[]>;

	private nextEligibleDateSource: BehaviorSubject<Date | null>;
	nextEligibleDate$: Observable<Date | null>;

	private selectedProcedureSource: BehaviorSubject<Procedure | null>;
	selectedProcedure$: Observable<Procedure | null>;

	private selectedTimeSlotSource: BehaviorSubject<SelectableTimeSlot | null>;
	selectedTimeSlot$: Observable<SelectableTimeSlot | null>;

	private providersSlotsRequestSource: BehaviorSubject<ProvidersRequest | null>;
	providersSlotsRequest$: Observable<ProvidersRequest | null>;

	private providersResponseSource: BehaviorSubject<ProvidersResponse | null>;
	providersResponse$: Observable<ProvidersResponse | null>;

	private mapLocationsSource: BehaviorSubject<Location[] | null>;
	mapLocations$: Observable<Location[] | null>;

	private mapFocusedLocationSource: BehaviorSubject<Location | null>;
	mapFocusedLocation$: Observable<Location | null>;

	/**
	 * Find appointment constructor
	 * @param config - Url configuration
	 * @param serviceRouter - Service router
	 * @param environment - Environment
	 */
	constructor(
		private config: Config,
		private serviceRouter: ServiceRouter,
		private environment: Environment
	) {
		this.proceduresSource = new BehaviorSubject<Procedure[]>([]);
		this.procedures$ = this.proceduresSource.asObservable();

		this.nextEligibleDateSource = new BehaviorSubject<Date | null>(null);
		this.nextEligibleDate$ = this.nextEligibleDateSource.asObservable();

		this.selectedProcedureSource = new BehaviorSubject<Procedure | null>(null);
		this.selectedProcedure$ = this.selectedProcedureSource.asObservable();

		this.selectedTimeSlotSource = new BehaviorSubject<SelectableTimeSlot | null>(null);
		this.selectedTimeSlot$ = this.selectedTimeSlotSource.asObservable();

		this.providersSlotsRequestSource = new BehaviorSubject<ProvidersRequest | null>(null);
		this.providersSlotsRequest$ = this.providersSlotsRequestSource.asObservable();

		this.providersResponseSource = new BehaviorSubject<ProvidersResponse | null>(null);
		this.providersResponse$ = this.providersResponseSource.asObservable();

		this.mapLocationsSource = new BehaviorSubject<Location[] | null>(null);
		this.mapLocations$ = this.mapLocationsSource.asObservable();

		this.mapFocusedLocationSource = new BehaviorSubject<Location | null>(null);
		this.mapFocusedLocation$ = this.mapFocusedLocationSource.asObservable();
	}

	/**
	 * Update All Available Procedures that are available for the Patient
	 * @param procedures All Available Procedures for the Patient
	 */
	updateProcedures(procedures: Procedure[]): void {
		this.proceduresSource.next(procedures);
	}

	/**
	 * Update Eligible Date
	 * @param date Eligible Date
	 */
	updateNextEligibleDate(date: Date | null): void {
		this.nextEligibleDateSource.next(date);
	}

	/**
	 * Update Selected Procedure Object
	 * @param selectedProcedure Selected Procedure Object
	 */
	updateSelectedProcedure(selectedProcedure: Procedure): void {
		this.selectedProcedureSource.next(selectedProcedure);
	}

	/**
	 * Update Selected Time Slot Object
	 * @param selectedInventorySlot Selected Time Slot Object
	 */
	updateSelectedTimeSlot(selectedInventorySlot: SelectableTimeSlot): void {
		this.selectedTimeSlotSource.next(selectedInventorySlot);
	}

	/**
	 * Send updated search criteria event with updated parameters
	 * @param providersSlotsRequest Updated criteria parameters
	 */
	updateProvidersSlotsRequest(providersSlotsRequest: ProvidersRequest): void {
		this.providersSlotsRequestSource.next(providersSlotsRequest);
	}

	/**
	 * Send updated providers slots response
	 * @param providersResponse Updated providers slots response
	 */
	updateProvidersResponse(providersResponse: ProvidersResponse): void {
		this.providersResponseSource.next(providersResponse);
	}

	/**
	 * Send updated map locations
	 * @param mapLocations Updated map locations
	 */
	updateMapLocations(mapLocations: Location[]): void {
		this.mapLocationsSource.next(mapLocations);
	}

	/**
	 * Send focused map location
	 * @param mapLocation Focused map location
	 */
	updateMapFocusedLocation(mapLocation: Location): void {
		this.mapFocusedLocationSource.next(mapLocation);
	}

	/**
	 * get locations service call
	 */
	getLocations(queryParam): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.schedulingApi.getLocation + queryParam,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * get procedures service call
	 */
	getProcedures(selectedProcedure: any = ''): Observable<Procedure[]> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.getProcedure}?simplifiedProcedure=${selectedProcedure}`,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * get available slots service call
	 * @param url
	 */
	getAvailableSlots(queryParam): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.schedulingApi.getAvailableSlots + queryParam,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * get available pulse virtual slots service call
	 * @param url
	 */
	getAvailablePVSlots(queryParam, consultId: string, timezone): Observable<any> {
		return this.serviceRouter
			.makeRequest(
				this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
				this.config.url.pulseVirtualApi.consultation + `/${consultId}/timeslots?${queryParam}`,
				METHOD_TYPE.SECURED_GET,
				''
			)
			.pipe(
				switchMap(response => {
					//to match the structure of the response from the /default api
					const slotObject = {
						locationInventory: {
							location: { id: 0 },
							slots: [{ date: '', timeSlots: [] }],
						},
					};

					if (response.slots.length > 0) {
						slotObject.locationInventory.slots[0].timeSlots = response.slots.map(slot => {
							return {
								time: moment(slot.start).tz(timezone).format('HH:mm'),
							};
						});
						slotObject.locationInventory.slots[0].date = moment(response.slots[0].start)
							.tz(timezone)
							.format('MM/DD/YYYY');
					}
					return of(slotObject);
				})
			);
	}

	updatePVConsult(consultId, body): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.pulseVirtualApi.consultation + `/${consultId}`,
			METHOD_TYPE.SECURED_PUT,
			body
		);
	}

	getPVConsult(): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.pulseVirtualApi.consultation,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * choose a slot service call
	 * @param data
	 */
	selectSlot(body: SchedulingAppointmentBean, newUI: boolean = false): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.selectSlot}?fillFacilityDistance=true&newUI=${newUI}`,
			METHOD_TYPE.SECURED_POST,
			body
		);
	}

	/**
	 * get booking pre-check message from service
	 * @param procedureId - selected procedure Id
	 */
	getBookingPreCheckMessage(queryParam): Observable<PreBookConditionMessage> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.schedulingApi.getBookingPreCheckMsg + queryParam,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * based on pre-check condition, showing modal for user response
	 * @param messageId - selected message id from modal
	 * @param selectedButton - selected button from modal
	 */
	sendModalResponse(pathParam: string, body: string): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.messageResponse}${pathParam}`,
			METHOD_TYPE.SECURED_POST,
			body
		);
	}

	/**
	 * request appointment service call
	 */
	requestAppointment(newUi: boolean = false): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.requestSlot}?newUi=${newUi}`,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * request slots service call
	 * @param facilityID
	 * @param reschedApptID
	 * @param procedureID
	 * @param subProcedureIDs
	 */
	getSlotChoice(queryParam): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.schedulingApi.requestSlot + queryParam,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * get procedures list in the case of rescheduling
	 * @reschedApptID - appointment id
	 */
	getRescheduleProcedures(queryParam: string): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.schedulingApi.getRescheduleProcedures + queryParam,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * Gets all data for booking simplified
	 */
	getAllDataForBookingSimplified(interval: number = 7, isCovid19: boolean = false, selectedProcedure: any = '') {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.getBookingSimplified
			}?interval=${interval}&isCovid19=${isCovid19}&simplifiedProcedure=${encodeURIComponent(selectedProcedure)}`,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * Gets all data for Providers/Facilities and Slots based on search criteria
	 */
	getAppointmentProvidersSlots(params: ProvidersRequest): Observable<SchedulingResponse> {
		if (!(params instanceof ProvidersRequest)) {
			params = new ProvidersRequest(params);
		}
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.getProvidersSlots}?${params.getParamsAsQueryString()}`,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * Gets all Providers/Facilities based on search criteria
	 */
	getAppointmentProviders(params: ProvidersRequest): Observable<ProvidersResponse> {
		if (!(params instanceof ProvidersRequest)) {
			params = new ProvidersRequest(params);
		}
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.getProviders}?${params.getParamsAsQueryString()}&fillFacilityDistance=true`,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * Gets initial Slots for a Providers/Facilities based on search criteria
	 */
	getAppointmentProviderSlots(params: ProviderSlotsRequest): Observable<SlotsList> {
		if (!(params instanceof ProviderSlotsRequest)) {
			params = new ProviderSlotsRequest(params);
		}
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.getProviderSlots}?${params.getParamsAsQueryString()}`,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * Gets more available Slots based on search criteria
	 */
	getMoreAvailability(params: MoreAvailabilityRequest): Observable<SlotsList> {
		if (!(params instanceof MoreAvailabilityRequest)) {
			params = new MoreAvailabilityRequest(params);
		}
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.getMoreAvailability}?${params.getParamsAsQueryString()}`,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * Gets appointment data for toaster
	 */
	getAppointmentDataForToaster(interval: number = 1) {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.getAppointmentDataForToaster}?interval=${interval}`,
			METHOD_TYPE.GENERAL_GET,
			''
		);
	}

	covid19PreScreening(body: string): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.simplifiedSchedulingApi.covid19PreScreening}`,
			METHOD_TYPE.SECURED_POST,
			body
		);
	}

	/**
	 * get alternate available slots service call
	 * @param url
	 */
	getAlternateAvailableSlots(queryParam): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.schedulingApi.getAlternateAvailableSlots + queryParam,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * validate promocode / referral code from backend
	 * @param  {number} procedureID
	 * @param  {number} locationID
	 * @param  {string} promoCode
	 * @param {number} apptID
	 * @param {number} rescheduleApptID
	 * @returns Observable
	 */
	validatePromoCodeOrReferral(queryParamsString: string): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.verifyReferalCode}?${queryParamsString.toString()}`,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * Get currently active teaser / promo message for current user
	 *
	 * @param  {string} queryParamsString
	 * @returns Observable
	 */
	getIncentiveTeaser(queryParamsString: string): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.incentiveTeaserInfo}?${queryParamsString}`,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}
}
