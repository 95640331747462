import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Config } from 'src/app/config/config';

/**
 * Language Translate Service
 */
@Injectable({
	providedIn: 'root',
})
export class LanguageTranslateService {
	/**
	 * Language Translate Service Constructor
	 * @param config - App Config
	 * @param translate - Translate Service Object
	 */
	constructor(
		private config: Config,
		private translate: TranslateService
	) {
		let defaultLanguage = 'en-US';
		if (navigator.language !== undefined) {
			defaultLanguage = navigator.language.split('-')[0];
		}
		if (!this.config.availableTranslationLanguages.includes(defaultLanguage)) {
			defaultLanguage = 'en';
		}
		this.translate.setDefaultLang(defaultLanguage);
	}
}
