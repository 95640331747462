import { Component, Input, Output, EventEmitter, Inject, SimpleChanges, OnChanges } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';

@Component({
	selector: 'app-ehe-verification-modal',
	templateUrl: './ehe-verification-modal.component.html',
	styleUrls: ['./ehe-verification-modal.component.scss'],
})
export class EheVerificationModalComponent implements OnChanges {
	@Input() title: string;
	@Input() button1: string;
	@Input() button2: string;
	@Input() modalWrapperClass = 'ehe-verification-modal-wrapper';
	@Input() modalwidth;
	@Input() content: string;
	@Input() alignCenterButtonCenter: boolean;
	@Output() action = new EventEmitter();
	fromCreateAccount: boolean;
	showSecondPopUp: boolean;
	@ViewChild('ehe-modal', { static: false }) eheModal: ElementRef;
	constructor(
		private dialogRef: MatDialogRef<EheVerificationModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private matDialogModule: MatDialogModule
	) {
		this.button1 = this.data && this.data.button1;
		this.button2 = this.data && this.data.button2;
		this.alignCenterButtonCenter = this.data && this.data.alignCenterButtonCenter;
		this.content = this.data && this.data.content;
		this.fromCreateAccount = this.data && this.data.fromCreateAccount;
		this.showSecondPopUp = this.data && this.data.showSecondPopUp;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.modalWrapperClass) {
			this.modalWrapperClass = changes.modalWrapperClass.currentValue;
		}
	}

	cancel() {
		const output = { value: 'cancel' };
		if (this.fromCreateAccount) {
			this.dialogRef.close(output);
		} else {
			this.action.emit(output);
		}
	}

	submit() {
		const output = { value: 'submit' };
		if (this.fromCreateAccount) {
			this.dialogRef.close(output);
		} else {
			this.action.emit(output);
		}
	}

	closeDialog() {
		const output = { value: 'cancel' };
		this.action.emit(output);
	}
}
