import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { DataCarrierService } from './data-carrier.service';
import { FindAppointmentService } from '../scheduling/find-appointment/find-appointment.service';
import { JwtHelper } from './jwt-helper.service';
import { Observable, of, take, switchMap, catchError } from 'rxjs';

/**
 * Guard specific to simplified booking flow
 */
@Injectable()
export class PulseVirtualGuard implements CanActivate, CanActivateChild {
	/**
	 * Auth Guard Service Constructor
	 * @param router - Angular Router
	 * @param authService - Auth Service Object
	 */
	constructor(
		private authService: AuthService,
		private dataCarrierService: DataCarrierService,
		private findAppointmentService: FindAppointmentService,
		private jwtService: JwtHelper,
		private router: Router
	) {}

	/**
	 * Implementing canActivate interface
	 * @param route - ActivatedRouteSnapshot
	 * @param state - RouterStateSnapshot
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const params = state.root.queryParams;
		if (params && params.u) {
			sessionStorage.setItem('token', params.u);
		}
		if (params && Object.keys(params).length !== 0) {
			this.authService.setUtmParameters(params);
		}

		// check if the token is present in session storage
		if (sessionStorage.getItem('token')) {
			this.dataCarrierService.pushValue('isPulseVirtual', true);
			return of(true);
		}

		// check if the user is already logged in and is eligible to view the registration page
		if (this.jwtService.getEpmsId()) {
			return this.findAppointmentService.getAllDataForBookingSimplified().pipe(
				take(1),
				switchMap(response => {
					if (response.pulsVirtualRegistratedClient) {
						return of(true);
					} else {
						return of(false);
					}
				}),
				catchError(error => {
					console.log(error.message);
					if (this.dataCarrierService.getChannel().getValue().eligibleToBookPV) {
						return of(true);
					} else {
						return of(false);
					}
				})
			);
		} else {
			// redirect the user to login screen
			this.router.navigate(['login'], {
				queryParams: { returnUrl: encodeURI(state.url) },
			});
			return of(false);
		}
	}

	/**
	 * Checking condition for enabling the child routes
	 * @param route - ActivatedRouteSnapshot
	 * @param state - RouterStateSnapshot
	 */
	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.canActivate(route, state);
	}
}
