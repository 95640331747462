<div class="outline-button-wrapper">
	<button
		class="rectangle"
		(click)="onClick()"
		appEheAnalytics
		[data]="analyticsData"
		appGoogleAnalytics
		[eventData]="googleAnalyticsData">
		<span class="label">{{ buttonTextKey | translate }}</span>
	</button>
</div>
