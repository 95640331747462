import { Component, Input } from '@angular/core';
import { AppUtil } from '../../../utils/app.util';

/*** Footer Component */
@Component({
	selector: 'app-ehe-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
	/** Footer Type */
	@Input() type: string;
	/** denote whether to show social network links or not */
	@Input() socialNetwork: boolean = false;
	/** Style 'TOP' value */
	@Input() top: string;
	/** Holds current year value */
	currentYear: any = AppUtil.getCurrentYear();

	openCookiesPreferences($event) {
		$event.preventDefault();
		console.log('Open Cookies Preferences');
		window['Osano']?.cm?.showDrawer('osano-cm-dom-info-dialog-open');
	}
}
