import { Component, OnInit } from '@angular/core';

/**
 * Base reset password component
 *
 * The reset password component will be displayed inside this component and
 * this will not be there in the case of Marketo
 */
@Component({
	selector: 'app-base-reset-password',
	templateUrl: './base-reset-password.component.html',
})
export class BaseResetPasswordComponent {}
