<mat-card @list class="info-card">
	<mat-card-content>
		<div class="info-row" @items *ngIf="passwordComponent.enableLowerCaseLetterRule">
			<div *ngIf="passwordComponent.containAtLeastOneLowerCaseLetter; then done; else error" @flipY></div>
			<ng-template #done>
				<mat-icon @positiveState color="primary">done</mat-icon>
			</ng-template>
			<ng-template #error>
				<mat-icon @negativeState color="warn">error</mat-icon>
			</ng-template>
			<span [innerHTML]="lowerCaseCriteriaMsg"></span>
		</div>

		<div class="info-row" @items *ngIf="passwordComponent.enableUpperCaseLetterRule">
			<div *ngIf="passwordComponent.containAtLeastOneUpperCaseLetter; then done; else error"></div>
			<ng-template #done>
				<mat-icon @positiveState color="primary">done</mat-icon>
			</ng-template>
			<ng-template #error>
				<mat-icon @negativeState color="warn">error</mat-icon>
			</ng-template>
			<span [innerHTML]="upperCaseCriteriaMsg"></span>
		</div>

		<div class="info-row" @items *ngIf="passwordComponent.enableDigitRule">
			<div *ngIf="passwordComponent.containAtLeastOneDigit; then done; else error"></div>
			<ng-template #done>
				<mat-icon @positiveState color="primary">done</mat-icon>
			</ng-template>
			<ng-template #error>
				<mat-icon @negativeState color="warn">error</mat-icon>
			</ng-template>
			<span [innerHTML]="digitsCriteriaMsg"></span>
		</div>

		<div class="info-row" @items *ngIf="passwordComponent.enableSpecialCharRule">
			<div *ngIf="passwordComponent.containAtLeastOneSpecialChar; then done; else error"></div>
			<ng-template #done>
				<mat-icon @positiveState color="primary">done</mat-icon>
			</ng-template>
			<ng-template #error>
				<mat-icon @negativeState color="warn">error</mat-icon>
			</ng-template>
			<span [innerHTML]="specialCharsCriteriaMsg"></span>
		</div>

		<div class="info-row" @items *ngIf="passwordComponent.enableLengthRule">
			<div *ngIf="passwordComponent.containAtLeastMinChars; then done; else error"></div>
			<ng-template #done>
				<mat-icon @positiveState color="primary">done</mat-icon>
			</ng-template>
			<ng-template #error>
				<mat-icon @negativeState color="warn">error</mat-icon>
			</ng-template>
			<span [innerHTML]="minCharsCriteriaMsg"></span>
		</div>

		<div class="info-row" @items *ngIf="passwordComponent.customValidator">
			<div *ngIf="passwordComponent.containAtCustomChars; then done; else error"></div>
			<ng-template #done>
				<mat-icon @positiveState color="primary">done</mat-icon>
			</ng-template>
			<ng-template #error>
				<mat-icon @negativeState color="warn">error</mat-icon>
			</ng-template>
			<span [innerHTML]="customCharsCriteriaMsg"></span>
		</div>

		<div *ngIf="enableScoreInfo" class="info-row" @items>
			<div *ngIf="passwordComponent.strength === 100; then done; else error"></div>
			<ng-template #done>
				<mat-icon @positiveState color="primary">done</mat-icon>
			</ng-template>
			<ng-template #error>
				<mat-icon @negativeState color="warn">error</mat-icon>
			</ng-template>
			<span [innerHTML]="'Password strength: ' + passwordComponent.strength + '%'"></span>
		</div>
	</mat-card-content>
</mat-card>
