import { Injectable } from '@angular/core';
import { Appointment } from '../../../beans/dashboard/dashboard-module.bean';
import { Observable, BehaviorSubject } from 'rxjs';
import { Config } from '../../../config/config';
import { ServiceRouter } from '../../core/http-service-router.service';
import { METHOD_TYPE } from '../../../constants/service.constants';
import { Environment } from '../../../config/environment';
import { AppUtil } from '../../../utils/app.util';
import { MatDialog } from '@angular/material/dialog';
import { WordPressOverlayComponent } from '../../../modules/shared/modals/word-press-overlay/word-press-overlay.component';

/**
 * Appointment Service
 */
@Injectable({
	providedIn: 'root',
})
export class AppointmentsService {
	/** Observable to check the upcoming appointments count */
	upcomingAppointmentsCount: any = 0;
	/** Observable to check the if the user has upcoming PW appointments */
	hasPW: BehaviorSubject<boolean>;
	/** Observable to check whether user has any Physical Exam scheduled */
	hasPE: BehaviorSubject<boolean>;
	/** boolean to decide if full width has to be applied */
	fullWidth: BehaviorSubject<boolean>;
	/** boolean to decide if my health has to be shown or not */
	myhealth: BehaviorSubject<boolean>;
	/** boolean to decide if phone no has to be shown or not */
	showPhone: BehaviorSubject<boolean>;

	/**
	 * Appointment Service Constructor
	 * @param serviceRouter - Service Router Object
	 * @param config - Url Configuration Object
	 * @param environment - Environment Configurations
	 */
	constructor(
		private serviceRouter: ServiceRouter,
		private config: Config,
		private environment: Environment,
		public dialog: MatDialog
	) {
		this.upcomingAppointmentsCount = new BehaviorSubject<any>({});
		this.hasPE = new BehaviorSubject<boolean>(false);
		this.hasPW = new BehaviorSubject<boolean>(false);
		this.fullWidth = new BehaviorSubject<boolean>(false);
		this.myhealth = new BehaviorSubject<boolean>(false);
		this.showPhone = new BehaviorSubject<boolean>(false);
	}

	/**
	 * Service call for getting the list of upcoming appointments
	 */
	getUpcomingAppointments(): Observable<Appointment[]> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.schedulingApi.getUpcomingAppointments,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * Service call for getting the list of past appointments
	 */
	getPastAppointments(): Observable<Appointment[]> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.schedulingApi.getPastAppointments,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * Service call for confirming the pre-booked appointment
	 * @param appointmentId - pre-booked appointment's id
	 */
	confirmPreBookedAppointment(pathParam): Observable<Appointment> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			`${this.config.url.schedulingApi.confirmPreBookingAppointment}${pathParam}`,
			METHOD_TYPE.SECURED_PUT,
			''
		);
	}

	/**
	 * Observable function returning the count of upcoming appointments
	 */
	getUpcomingAppointmentsCount(): BehaviorSubject<any> {
		return this.upcomingAppointmentsCount;
	}

	/**
	 * Set function for updating the upcoming appointment count
	 * @param upcomingAppointmentCount
	 */
	setUpcomingAppointmentCount(upcomingAppointmentCount) {
		this.upcomingAppointmentsCount.next(upcomingAppointmentCount);
	}

	/**
	 * get function for @hasPW
	 */
	hasUpcomingPWappointment(): BehaviorSubject<any> {
		return this.hasPW;
	}

	/**
	 * set function for @hasPW
	 */
	setUpcomingPWappointment(hasPW) {
		this.hasPW.next(hasPW);
	}

	/**
	 * get function for @hasPE
	 */
	hasUpcomingPE(): BehaviorSubject<any> {
		return this.hasPE;
	}

	/**
	 * set function for @hasPE
	 */
	setUpcomingPE(hasPE) {
		this.hasPE.next(hasPE);
	}

	/**
	 * Saves car service preferences
	 * @param pathParam path param
	 * @param body post body
	 */
	saveCarServicePreferences(pathParam, body): Observable<Appointment> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			AppUtil.constructUrlWithPathParams(this.config.url.schedulingApi.saveCarServicePreferences, [pathParam]),
			METHOD_TYPE.SECURED_POST,
			body
		);
	}

	/**
	 * gets appointment details for given appointment id
	 * @param pathParam appointmentId
	 */
	getAppointmentInfo(pathParam): Observable<Appointment> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			AppUtil.constructUrlWithPathParams(this.config.url.schedulingApi.getAppoinmentDetails, [pathParam]),
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	/**
	 * get function for @fullWidth
	 */
	showFullWidth(): BehaviorSubject<any> {
		return this.fullWidth;
	}

	/**
	 * set function for @fullWidth
	 */
	setFullWidth(fullWidth) {
		this.fullWidth.next(fullWidth);
	}

	/**
	 * get function for @myhealth
	 */
	showMyHealth(): BehaviorSubject<any> {
		return this.myhealth;
	}

	/**
	 * set function for @myhealth
	 */
	setShowMyHealth(myhealth) {
		this.myhealth.next(myhealth);
	}

	/**
	 * get function for @showPhone
	 */
	showPhoneNo(): BehaviorSubject<any> {
		return this.showPhone;
	}

	/**
	 * set function for @showPhone
	 */
	setShowPhoneNo(showPhone) {
		this.showPhone.next(showPhone);
	}

	/**
	 * Opens facility rating as overlay
	 */
	openFacilityRatingModal() {
		this.dialog.open(WordPressOverlayComponent, {
			width: '100vw',
			data: { url: 'https://covid19.ehe.health/member-reviews/' },
			autoFocus: false,
			panelClass: 'wp-overlay',
		});
	}

	/**
	 * Opens about exam overlay
	 */
	// DE-3698 This button opens COVID-19 FAQ, which is not required anymore
	// openAboutExamModal() {
	//   this.dialog.open(WordPressOverlayComponent, {
	//     width: '100vw',
	//     data: { url: 'https://covid19.ehe.health/exam-faqs/' },
	//     autoFocus: false,
	//     panelClass: 'wp-overlay'
	//   });
	// }

	/**
	 * Opens about clinic overlay
	 */
	openAboutClinicModal() {
		this.dialog.open(WordPressOverlayComponent, {
			width: '100vw',
			data: { url: 'https://covid19.ehe.health/overview/' },
			autoFocus: false,
			panelClass: 'wp-overlay',
		});
	}

	getCancelledAppointmentInfo(appointmentId) {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.schedulingApi.getCancelledAppointmentDetails + `/${appointmentId}/cancel`,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	getHealthHistoryStatus(apptID): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().DASHBOARD_BASE_URL,
			`${this.config.url.dashboard.getHaStatus}?apptId=${apptID}`,
			METHOD_TYPE.SECURED_GET,
			'',
			this.environment.getEnvironment().BASE_DOMAIN
		);
	}

	bookPVAppointment(body, consultId) {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.config.url.pulseVirtualApi.consultation + `/${consultId}/appointment`,
			METHOD_TYPE.SECURED_POST,
			body
		);
	}
}
