import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FormGroup, FormControl, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { passwordAllowedSpecialChars } from 'src/app/constants/app.constants';
import { DateUtil } from 'src/app/utils/date.util';

@Injectable({
	providedIn: 'root',
})
export class ValidationUtilityService {
	static dateFormat = 'MM/DD/YYYY';

	characterMinLength: string;
	characterMaxLength: string;

	validationErrorMessages: any = {
		invalidEmail: 'Please enter valid email format.',
		required: 'This field is required',
		passwordRequired: 'Required Field. Your password is case-sensitive and contains special character(s).',
		invalidName: 'Please enter valid name',
		minLength: '',
		maxLength: '',
		// eslint-disable-next-line max-len
		mismatchedPasswords: 'Passwords do not match. Please enter again.',
		invalidPhoneNumber: 'Please use valid phone number.',
		invalidZipCode: 'Please enter valid zip code',
		privacy: 'Please agree to Terms of Use and Privacy Policy.',
		invalidVerificationCode: 'The code you entered is invalid. Please enter again.',
		invalidCode: 'Invalid verification code',
		invalidEmployer: 'Please select valid employer',
		maxUserInputExceeded: 'Please add no more than five specialties.',
		invalidAddress: 'Please enter valid address.',
		invalidCity: 'Please enter valid city.',
		invalidCreditCardNumber: 'Your credit card information is invalid. Please enter again.',
		invalidCreditCardExpiryDate: 'Please enter valid expiry date',
		invalidCreditCardCVV: 'Please enter valid CVV',
		invalidPreferredDate: 'Select at least one preferred date',
		atleastOne: 'Please select at least one (1) option.',
		invalidAlphaNumeric: 'Please enter alpha-numeric characters only.',
		invalidNumber: 'Please enter numbers only',
		invalidDob: 'Please use MM/DD/YYYY format.',
		invalidDay: 'Please select valid day',
		invalidMonth: 'Please select valid month',
		invalidSsn: 'Please use valid SSN Format',
		unmatchedSSN: 'The number you entered does not match our records. Please try again.',
		duplicateEmail: 'Email is already in use. Please enter another email address.',
		invalidGroupNumber: 'Please enter valid group number.',
		invalidMemberId: 'Please enter valid member id.',
		invalidDate: 'Date must be a valid date',
		invalidDateDistantPast: 'Date cannot be before 01/01/1900',
		invalidDateFuture: 'Date cannot be in the future',
		enterValidPhone: 'Please enter a valid phone number.',
		enterValidEmail: 'Please enter a valid email address.',
		invalidIncentiveCode: '',
		customError: '',
		email: 'Invalid Email.',
		invalidNameOrEmployer:
			'Please enter your full name as it appears in your employer records. For employer, begin typing and then select directly from the dropdown menu.',
		invalidAge: 'Age should be above 18 to register for a kit',
		duplicateEmailInPV: 'The email you have entered is in use by another member, please enter another email',
		invalidKitId: 'Please enter 10 character activation code.',
	};

	static pvPhoneNumberValidator(control: FormControl): ValidationErrors | null {
		if (control.value && control.value.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/g)) {
			ValidationUtilityService.removeNonNumericCharacters(control);
			ValidationUtilityService.markControlUntouched(control);
			return null;
		}
		if (!control.value) {
			return null;
		} else {
			return { invalidPhoneNumber: true };
		}
	}

	static emailValidator(control: FormControl): ValidationErrors | null {
		if (
			null !== control.value &&
			control.value.length > 0 &&
			control.value.match(new RegExp('^[\\w\\.-]+@([\\w\\-]+\\.)+[a-zA-Z]{2,}$')) &&
			control.value.length <= 200
		) {
			return null;
		} else {
			return { invalidEmail: true };
		}
	}

	static kitValidator(control: FormControl): ValidationErrors | null {
		if (control.value?.length === 10) {
			return null;
		} else {
			return { invalidKitId: true };
		}
	}

	static optionalEmailValidator(control: FormControl): ValidationErrors | null {
		if (!control.value || control.value.length == 0) {
			return null;
		} else if (
			null !== control.value &&
			control.value.length > 0 &&
			control.value.match(new RegExp('^[\\w\\.-]+@([\\w\\-]+\\.)+[a-zA-Z]{2,}$')) &&
			control.value.length <= 200
		) {
			return null;
		} else {
			return { invalidEmail: true };
		}
	}

	static HaOptionalEmailValidator(control: FormControl): ValidationErrors | null {
		if (!control.value || control.value.length == 0) {
			return null;
		} else if (
			null !== control.value &&
			control.value.length > 0 &&
			control.value.match(new RegExp('^[\\w\\.-]+@([\\w\\-]+\\.)+[a-zA-Z]{2,}$')) &&
			control.value.length <= 200
		) {
			return null;
		} else {
			return { enterValidEmail: true };
		}
	}

	static nameValidator(control) {
		ValidationUtilityService.markControlUntouched(control);
		if (null !== control.value && control.value.length >= 2 && control.value.length <= 50) {
			return null;
		} else {
			return { invalidName: true };
		}
	}

	static cognitoUsernameLengthValidator(control) {
		ValidationUtilityService.markControlUntouched(control);
		// if the string contains email format, do not test it, only test when username is being entered
		// cognito limit for username is 128 characters but for consitency with epms we will keep 80 here as well
		if (control.value.includes('@')) return null;
		return control.value && control.value.length >= 2 && control.value.length <= 80 ? null : { invalidName: true };
	}

	static addressValidator(control: FormControl): ValidationErrors | null {
		ValidationUtilityService.markControlUntouched(control);
		if (null !== control.value && control.value.length > 0 && control.value.match(/^([a-zA-Z0-9\-.\s]){2,50}$/g)) {
			return null;
		} else {
			return { invalidAddress: true };
		}
	}

	static addressValidator2(control: FormControl): ValidationErrors | null {
		ValidationUtilityService.markControlUntouched(control);
		if (!control.value || (control.value.length > 0 && control.value.match(/^([a-zA-Z0-9\-.\s]){2,50}$/g))) {
			return null;
		} else {
			return { invalidAddress: true };
		}
	}

	static cityValidator(control: FormControl): ValidationErrors | null {
		ValidationUtilityService.markControlUntouched(control);
		if (null !== control.value && control.value.length > 0 && control.value.match(/^([a-zA-Z0-9\-.\s]){2,50}$/g)) {
			return null;
		} else {
			return { invalidCity: true };
		}
	}

	static passwordValidator(control) {
		const pwdRegex = new RegExp(
			'^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?' +
				passwordAllowedSpecialChars +
				')[A-z0-9?=.*?' +
				passwordAllowedSpecialChars +
				'{8,}$'
		);
		ValidationUtilityService.markControlUntouched(control);
		if (null !== control.value && control.value.length > 0 && pwdRegex.test(control.value)) {
			return null;
		} else {
			if (!control.value) {
				return { required: true };
			}
			return { invalidPassword: true };
		}
	}

	static matchingPasswords(formGroup: FormGroup) {
		const password = formGroup.controls.password.value;
		const repeatPassword = formGroup.controls.confirmPassword.value;
		if (repeatPassword.length <= 0) {
			return null;
		}
		if (!formGroup.controls.password.hasError('invalidPassword') && password && repeatPassword) {
			const error = repeatPassword !== password ? { mismatchedPasswords: true } : null;
			formGroup.controls.confirmPassword.setErrors(error);
			return error;
		}
	}

	static haOptionalPhoneNumberValidator(control) {
		ValidationUtilityService.removeNonNumericCharacters(control);
		ValidationUtilityService.markControlUntouched(control);
		if (control.value && control.value.match(/^(\+?\d{1,4}[\s-])?(?!0+\s+,?$)\d{10}\s*,?$/g)) {
			return null;
		} else if (!control.value) {
			return null;
		} else {
			return { enterValidPhone: true };
		}
	}

	static zipCodeValidator(control: FormControl): ValidationErrors | null {
		ValidationUtilityService.markControlUntouched(control);
		if (control.value && control.value.match(/^\d{5}$/) && control.value.match(/^(?!00000|99999)[0-9]{5}$/)) {
			return null;
		} else {
			return { invalidZipCode: true };
		}
	}

	static optionalPhoneNumberValidator(control) {
		ValidationUtilityService.removeNonNumericCharacters(control);
		ValidationUtilityService.markControlUntouched(control);
		if (control.value && control.value.match(/^(\+?\d{1,4}[\s-])?(?!0+\s+,?$)\d{10}\s*,?$/g)) {
			return null;
		} else if (!control.value) {
			return null;
		} else {
			return { invalidPhoneNumber: true };
		}
	}

	static phoneNumberValidator(control) {
		ValidationUtilityService.removeNonNumericCharacters(control);
		ValidationUtilityService.markControlUntouched(control);
		if (control.value && control.value.match(/^(\+?\d{1,4}[\s-])?(?!0+\s+,?$)\d{10}\s*,?$/g)) {
			return null;
		} else {
			return { invalidPhoneNumber: true };
		}
	}

	static haPhoneNumberValidator(control: FormControl): ValidationErrors | null {
		ValidationUtilityService.removeNonNumericCharacters(control);
		ValidationUtilityService.markControlUntouched(control);
		if (control.value && control.value.match(/^(\+?\d{1,4}[\s-])?(?!0+\s+,?$)\d{10}\s*,?$/g)) {
			return null;
		} else {
			return { invalidPhoneNumber: true };
		}
	}

	static ssnValidator(control: FormControl): ValidationErrors | null {
		ValidationUtilityService.removeNonNumericCharacters(control);
		ValidationUtilityService.markControlUntouched(control);
		if (control.value && control.value.match(/^[0-9]{9}$/)) {
			return null;
		} else {
			return { invalidSsn: true };
		}
	}

	static verificationCodeValidator(
		control: FormControl,
		invalidKey = 'invalidVerificationCode'
	): ValidationErrors | null {
		ValidationUtilityService.markControlUntouched(control);
		if (control.value && control.value.match(/^\d{6}$/) && control.value.match(/^(?!000)[0-9]{6}$/)) {
			return null;
		} else {
			return { [invalidKey]: true };
		}
	}

	static verificationCodeValidatorRegistration(control: FormControl): ValidationErrors | null {
		return ValidationUtilityService.verificationCodeValidator(control, 'invalidCode');
	}

	// regex source: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s20.html
	static creditCardValidator(control: FormControl): ValidationErrors | null {
		ValidationUtilityService.markControlUntouched(control);
		/**
		 * (?:4[0-9]{12}(?:[0-9]{3})?          # Visa
		 *|  (?:5[1-5][0-9]{2}                # MasterCard
		 *| 222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}
		 *|  3[47][0-9]{13}                   # American Express
		 *|  3(?:0[0-5]|[68][0-9])[0-9]{11}   # Diners Club
		 *|  6(?:011|5[0-9]{2})[0-9]{12}      # Discover
		 *|  (?:2131|1800|35\d{3})\d{11}      # JCB
		 */
		if (
			control.value &&
			control.value.match(
				// eslint-disable-next-line max-len
				/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
			) &&
			ValidationUtilityService.checkLuhn(control.value)
		) {
			return null;
		} else {
			return { invalidCreditCardNumber: true };
		}
	}

	static dateValidatorNonFuture(control: FormControl): ValidationErrors | null {
		const inputDate = moment(new Date(control.value));
		const todayDate = moment(new Date());
		if (inputDate.isAfter(todayDate)) {
			return { invalidDateFuture: true };
		}
		return null;
	}

	static dateValidator(control: FormControl): ValidationErrors | null {
		const monthDays = {
			1: 31,
			2: 29,
			3: 31,
			4: 30,
			5: 31,
			6: 30,
			7: 31,
			8: 31,
			9: 30,
			10: 31,
			11: 30,
			12: 31,
		};

		if (!control.value) {
			return null;
		}
		const [month, day, year] = control.value.split('/').map(num => parseInt(num, 10));
		if (month > 12 || month < 1 || day < 1 || monthDays[month] < day || isNaN(year)) {
			return { invalidDate: true };
		} else if (year < 1900) {
			return { invalidDateDistantPast: true };
		}
		return null;
	}

	/**
	 * To validate if the user is above 18 years of age
	 *
	 * @static
	 * @param {AbstractControl} control
	 * @return {*}  {({ [key: string]: any } | null)}
	 * @memberof ValidationUtilityService
	 */
	static ValidateDOB(control: AbstractControl): { [key: string]: any } | null {
		const dob = DateUtil.getMoment(control.value);
		const age = moment().diff(dob, 'years');

		if (age < 18 || (age == 18 && moment().date() == dob.date() && moment().month() == dob.month())) {
			return { invalidAge: true };
		}
		return null;
	}

	/**
	 * Helper method to zero-pad Date Control days and months
	 * when user enters input that can only be intepreted as
	 * a single digit day (4-9) or month (2-9)
	 *
	 * @param control control to act upon
	 * @param value optional value to check
	 */
	static zeroPadDateControl(control: FormControl, value: any = control.value()): void {
		const monthMatch = value.match(/^([2-9])(?:_|\s|$)/);
		const dayMatch = value.match(/^(\d{2})\/?([4-9])(?:_|\s|$)/);
		if (monthMatch) {
			control.setValue('0' + monthMatch[1]);
		} else if (dayMatch) {
			control.setValue(dayMatch[1] + 0 + dayMatch[2]);
		}
	}

	static creditCardExpiryDateValidator(control) {
		ValidationUtilityService.markControlUntouched(control);
		if (control.value && control.value.match(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/)) {
			return null;
		} else {
			return { invalidCreditCardExpiryDate: true };
		}
	}

	static creditCardCVVValidator(control: FormControl): ValidationErrors | null {
		ValidationUtilityService.markControlUntouched(control);
		if (control.value && control.value.match(/^[0-9]{3,4}$/)) {
			return null;
		} else {
			return { invalidCreditCardCVV: true };
		}
	}

	static numberValidator(control: FormControl): ValidationErrors | null {
		ValidationUtilityService.markControlUntouched(control);
		if (control.value && control.value.length > 0 && control.value.match(/^([0-9]){2,50}$/g)) {
			return null;
		} else {
			return { invalidNumber: true };
		}
	}

	static getUpdatingAlphaNumbericValidator(): ValidatorFn {
		return this.alphaNumbericValidator.bind({ updateValue: true });
	}

	static alphaNumbericValidator(control: FormControl): ValidationErrors | null {
		ValidationUtilityService.markControlUntouched(control);
		if (control.value && control.value.length > 0 && !control.value.match(/^[a-zA-Z0-9]+$/)) {
			if (this && (this as any).updateValue) {
				control.setValue((control.value as string).replace(/[^a-zA-Z0-9]/g, ''));
				return null;
			} else {
				return { invalidNumber: true };
			}
		}
		return null;
	}

	// implementation of group number and memberId validation
	private static foreignIdValidator(control: FormControl, invalidKey: string): ValidationErrors | null {
		ValidationUtilityService.markControlUntouched(control);
		if (control.value && control.value.length > 0 && control.value.match(/^([a-zA-Z0-9_ ]){2,50}$/g)) {
			return null;
		} else {
			return { [invalidKey]: true };
		}
	}

	static groupNumberValidator(control: FormControl): ValidationErrors | null {
		return ValidationUtilityService.foreignIdValidator(control, 'invalidGroupNumber');
	}

	static memberIdValidator(control: FormControl): ValidationErrors | null {
		return ValidationUtilityService.foreignIdValidator(control, 'invalidMemberId');
	}

	static markControlUntouched(control: any): void {
		if (control.touched) {
			control._touched = false;
		}
	}

	private static removeNonNumericCharacters(control: any): void {
		if (control && control.value) {
			// we're modifying a read-only property on a FormControl here, which is a violation of the API
			control.value = control.value.replace(/\D+/g, '');
		}
	}

	private static generateLuhn(nonChecksummedCardNumber: string): number {
		return nonChecksummedCardNumber.split('').reduceRight(function (z, c, i, a) {
			return (z + ((((a.length - i - 1) % 2 === 0 ? 2 : 1) * parseInt(c, 10)) % 9)) % 10;
		}, 0);
	}

	private static checkLuhn(wholeCardNumber: string): boolean {
		const nonChecksummedCardNumber = wholeCardNumber.substring(0, wholeCardNumber.length - 1);
		return (
			ValidationUtilityService.generateLuhn(nonChecksummedCardNumber) ===
			parseInt(wholeCardNumber[wholeCardNumber.length - 1], 10)
		);
	}

	public get(error): any {
		if (error.minlength) {
			this.characterMinLength = String(error.minlength['requiredLength']);
			this.validationErrorMessages.minlength = 'Entry must be at least ' + this.characterMinLength + ' characters';
		}
		if (error.maxlength) {
			this.characterMaxLength = String(error.maxlength['requiredLength']);
			this.validationErrorMessages.maxlength =
				'Entry must have maximum of ' + this.characterMaxLength + ' characters only';
		}
		if (error.invalidIncentiveCode) {
			this.validationErrorMessages.invalidIncentiveCode = error.invalidIncentiveCode + ' is not a valid code';
		}

		if (error.customError) {
			this.validationErrorMessages.customError = error.customError;
		}
		if (error.privacy) {
			return this.validationErrorMessages.privacy;
		}
		return this.validationErrorMessages[Object.keys(error)[0]];
	}
}
