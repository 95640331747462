/** Analytics session handler key constant */
export const SESSION_HANDLER_KEY = 'event-traking';
export const UNAUTH_STATIC_PATH = 'partitioned-anon-analytics/';
export const AUTH_STATIC_PATH = 'partitioned-auth-analytics/';

/** HVA event type constants */
export enum HVA_EVENT_TYPE {
	SIGN_IN = 'Sign In',
	BOOK_EXAM = 'Book Exam',
	CREATE_ACCOUNT = 'Create-Account',
}

/** HVA event status constants */
export enum HVA_EVENT_STATUS {
	SUCCESS = 'Success',
	FAIl = 'Fail',
}
