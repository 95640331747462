import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { suppressingFlags } from './../../config/config';

interface FlaggedFeature {
	[name: string]: boolean;
}

@Injectable()
export class FeatureFlagGuardService implements CanActivate {
	public flagSuppressed: FlaggedFeature;

	constructor(private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		// Get the name from route data
		const featureFlag = route.data['featureFlag'];
		// Get the features listed in the config file
		this.flagSuppressed = suppressingFlags;

		this.featureFlagCheck(featureFlag);
		if (featureFlag && this.featureFlagCheck(featureFlag) === false) {
			this.router.navigate(['/dashboard']);
		} else {
			return true;
		}
		return;
	}

	/**
	 * @param {string} featureName The string used as reference within the suppressingFlags object.
	 * @example <caption>Example usage of featureFlagCheck.</caption>
	 *  // suppressedFeature is set to true within the config.ts file
	 * // returns false
	 * featureFlagCheck(suppressedFeature);
	 * @returns {boolean}
	 * // Returns the inverse boolean value to be used with CanActivate guard
	 */

	public featureFlagCheck(featureName: string) {
		const flagPresent = Object.prototype.hasOwnProperty.call(suppressingFlags, featureName);
		if (flagPresent) {
			return !suppressingFlags[featureName];
		}
		return true;
	}
}
