import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
	EmergencyContact,
	LivingWill,
	PersonalInfo,
	PrimaryCarePhysician,
	Specialists,
} from 'src/app/beans/health-history/general-info.bean';
import { Config } from 'src/app/config/config';
import { Environment } from 'src/app/config/environment';
import { METHOD_TYPE } from 'src/app/constants/service.constants';
import { ServiceRouter } from '../core/http-service-router.service';
import { JwtHelper } from '../core/jwt-helper.service';

/**
 * contains api calls related to general info section of health history
 */
@Injectable()
export class GeneralInfoService {
	/**
	 * constructor
	 * @param environment
	 * @param serviceRouter
	 * @param config
	 */
	constructor(
		private jwtService: JwtHelper,
		private environment: Environment,
		private serviceRouter: ServiceRouter,
		private config: Config
	) {}

	/**
	 * Gets personal info
	 */
	getPersonalInfo(apptID, haID): Observable<any> {
		if (!haID || haID == 0) {
			return new Observable(observer => {
				observer.next({ data: {} });
				observer.complete();
			});
		}
		const epmsPID = this.jwtService.getEpmsId();
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().HA_BASE_URL,
			`${this.config.url.newHealthAssessmentApi.personalInfo}?epmsPID=${epmsPID}&apptID=${apptID}&healthAssessmentID=${haID}`,
			METHOD_TYPE.SECURED_GET,
			'',
			this.environment.getEnvironment().HA_BASE_DOMAIN
		);
	}

	/**
	 * gets emergency contact info
	 */
	getEmergencyContactInfo(apptID, haID): Observable<any> {
		if (!haID || haID == 0) {
			return new Observable(observer => {
				observer.next({ data: {} });
				observer.complete();
			});
		}
		const epmsPID = this.jwtService.getEpmsId();
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().HA_BASE_URL,
			`${this.config.url.newHealthAssessmentApi.emergencyContact}?epmsPID=${epmsPID}&apptID=${apptID}&healthAssessmentID=${haID}`,
			METHOD_TYPE.SECURED_GET,
			'',
			this.environment.getEnvironment().HA_BASE_DOMAIN
		);
	}

	/**
	 * gets living will info
	 */
	getLivingWillInfo(apptID, haID): Observable<any> {
		if (!haID || haID == 0) {
			return new Observable(observer => {
				observer.next({ data: {} });
				observer.complete();
			});
		}
		const epmsPID = this.jwtService.getEpmsId();
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().HA_BASE_URL,
			`${this.config.url.newHealthAssessmentApi.livingWill}?epmsPID=${epmsPID}&apptID=${apptID}&healthAssessmentID=${haID}`,
			METHOD_TYPE.SECURED_GET,
			'',
			this.environment.getEnvironment().HA_BASE_DOMAIN
		);
	}

	/**
	 * gets primary care physician info
	 */
	getPrimaryCarePhysicianInfo(apptID, haID): Observable<any> {
		if (!haID || haID == 0) {
			return new Observable(observer => {
				observer.next({ data: {} });
				observer.complete();
			});
		}
		const epmsPID = this.jwtService.getEpmsId();
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().HA_BASE_URL,
			`${this.config.url.newHealthAssessmentApi.primaryCarePhysician}?epmsPID=${epmsPID}&apptID=${apptID}&healthAssessmentID=${haID}`,
			METHOD_TYPE.SECURED_GET,
			'',
			this.environment.getEnvironment().HA_BASE_DOMAIN
		);
	}

	/**
	 * gets specialists info
	 */
	getSpecialistsInfo(apptID, haID): Observable<any> {
		if (!haID || haID == 0) {
			return new Observable(observer => {
				observer.next({ data: {} });
				observer.complete();
			});
		}
		const epmsPID = this.jwtService.getEpmsId();
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().HA_BASE_URL,
			`${this.config.url.newHealthAssessmentApi.specialists}?epmsPID=${epmsPID}&apptID=${apptID}&healthAssessmentID=${haID}`,
			METHOD_TYPE.SECURED_GET,
			'',
			this.environment.getEnvironment().HA_BASE_DOMAIN
		);
	}

	/**
	 * saves personal info
	 * @param data
	 */
	savePersonalInfo(apptID, data: PersonalInfo): Observable<any> {
		const epmsPID = this.jwtService.getEpmsId();
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().HA_BASE_URL,
			`${this.config.url.newHealthAssessmentApi.personalInfo}?epmsPID=${epmsPID}&apptID=${apptID}`,
			METHOD_TYPE.SECURED_POST,
			data,
			this.environment.getEnvironment().HA_BASE_DOMAIN
		);
	}

	/**
	 * saves emergency contact info
	 * @param data
	 */
	saveEmergencyContactInfo(apptID, data: EmergencyContact): Observable<any> {
		const epmsPID = this.jwtService.getEpmsId();
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().HA_BASE_URL,
			`${this.config.url.newHealthAssessmentApi.emergencyContact}?epmsPID=${epmsPID}&apptID=${apptID}`,
			METHOD_TYPE.SECURED_POST,
			data,
			this.environment.getEnvironment().HA_BASE_DOMAIN
		);
	}

	/**
	 * saves living will info
	 * @param data
	 */
	saveLivingWillInfo(apptID, data: LivingWill): Observable<any> {
		const epmsPID = this.jwtService.getEpmsId();
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().HA_BASE_URL,
			`${this.config.url.newHealthAssessmentApi.livingWill}?epmsPID=${epmsPID}&apptID=${apptID}`,
			METHOD_TYPE.SECURED_POST,
			data,
			this.environment.getEnvironment().HA_BASE_DOMAIN
		);
	}

	/**
	 * saves primary care physician info
	 */
	savePrimaryCarePhysicianInfo(apptID, data: PrimaryCarePhysician): Observable<any> {
		const epmsPID = this.jwtService.getEpmsId();
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().HA_BASE_URL,
			`${this.config.url.newHealthAssessmentApi.primaryCarePhysician}?epmsPID=${epmsPID}&apptID=${apptID}`,
			METHOD_TYPE.SECURED_POST,
			data,
			this.environment.getEnvironment().HA_BASE_DOMAIN
		);
	}

	/**
	 * saves specialists info
	 */
	saveSpecialistsInfo(apptID, data: Specialists): Observable<any> {
		const epmsPID = this.jwtService.getEpmsId();
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().HA_BASE_URL,
			`${this.config.url.newHealthAssessmentApi.specialists}?epmsPID=${epmsPID}&apptID=${apptID}`,
			METHOD_TYPE.SECURED_POST,
			data,
			this.environment.getEnvironment().HA_BASE_DOMAIN
		);
	}

	getPulseDigitalEligiblity(): Observable<any> {
		return of({
			isEligible: false,
		});

		// Disable pulse digital banner https://jira.ehe.exechealthgroup.com/browse/DE-3314
		// return this.serviceRouter.makeRequest(
		//   this.environment.getEnvironment().BASE_DOMAIN,
		//   this.config.url.digitalBanner.getDigitalBannerEligibility,
		//   METHOD_TYPE.SECURED_GET,
		//   ''
		// );
	}
}
