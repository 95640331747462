<div class="toaster-wrapper" *ngIf="!showNewToaster && show">
	<div class="left-border"></div>
	<div class="content">
		<div class="title">
			<span>{{ count }}</span>
			<span [translate]="'shared.appointment-toaster.exams-booked'">exams booked within the last 7 days!</span>
		</div>
		<div class="message" [translate]="'shared.appointment-toaster.message'">
			Thank you for being proactive about your health. It's your health. Take good care of it.
		</div>
		<div class="time">{{ getCurrentTime() }}<i class="fas fa-check-circle"></i></div>
	</div>
</div>

<div class="new-toaster-wrapper" *ngIf="showNewToaster && show">
	<div class="close" (click)="close()"></div>
	<div class="left-border"></div>
	<div class="content">
		<div class="title">
			<span>{{ count }}</span>
			<span [translate]="'shared.appointment-toaster.exams-booked-new'">members booked within the last 7 days!</span>
		</div>
		<div class="message" [translate]="'shared.appointment-toaster.message-new'">
			Thank you for being proactive about your health.
		</div>
		<div class="time">{{ getCurrentTime() }}<i class="fas fa-check-circle"></i></div>
	</div>
</div>
