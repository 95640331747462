import { Directive, OnChanges, Input, HostListener } from '@angular/core';
import { EventTrackingBean } from '../../../beans/analytics/analytics-module.bean';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../../services/core/auth.service';
import { Environment } from '../../../config/environment';
import { HttpClient } from '@angular/common/http';
import { JwtHelper } from '../../../services/core/jwt-helper.service';
/**
 * Directive to track button click events in google analytics
 */
@Directive({
	selector: '[appGoogleAnalytics]',
})
export class GoogleAnalyticsDirective implements OnChanges {
	/** Data passing with directive */
	@Input() eventData: EventTrackingBean;
	/** Analytics Service */
	analyticsService: AnalyticsService;
	/**
	 * Host listener to track click event on directive element
	 * @param $event
	 */
	@HostListener('click', ['$event']) onClick($event) {
		setTimeout(() => {
			this.sendData();
		}, 10);
	}

	/**
	 * Host listener to track datechange event on directive element - mat datepicker
	 * @param $event
	 */
	@HostListener('dateChange', ['$event']) onDateChange($event) {
		setTimeout(() => {
			this.sendData();
		}, 10);
	}

	/**
	 * Host listener to track calander open event on directive element
	 * @param $event
	 */
	@HostListener('opened', ['$event']) onOpen($event) {
		setTimeout(() => {
			this.sendData();
		}, 10);
	}

	/**
	 * Google Analytics Service Constructor
	 * @param authService - Auth Service
	 * @param environment - Environment service
	 * @param http - Angular Http Client
	 */
	constructor(
		private authService: AuthService,
		private environment: Environment,
		private http: HttpClient,
		private cookieService: CookieService,
		private jwtService: JwtHelper
	) {
		this.analyticsService = new AnalyticsService(
			this.authService,
			this.environment,
			this.cookieService,
			this.http,
			this.jwtService
		);
	}

	ngOnChanges(changes) {
		if (changes && changes.data) {
			this.eventData = changes.data.currentValue;
		}
	}

	sendData() {
		this.analyticsService.sendGaEvent(this.eventData);
	}
}
