import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-consent-only-form',
	templateUrl: './consent-only-form.component.html',
	styleUrls: ['./consent-only-form.component.scss'],
})
export class ConsentOnlyFormComponent {
	isConsented: boolean = false;

	/**
	 * Constructor
	 * @param dialogRef - mat dialog reference
	 * @param data - input data
	 */
	constructor(
		private dialogRef: MatDialogRef<ConsentOnlyFormComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	/**
	 * Action dialog
	 * @param event - input event
	 */
	actionFunction(event) {
		if (event === 'SUBMIT') {
			this.dialogRef.close(this.isConsented);
		} else {
			this.dialogRef.close(false);
		}
	}
}
