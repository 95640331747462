import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-update-provider-video-modal',
	templateUrl: './update-provider-video-modal.component.html',
	styleUrls: ['./update-provider-video-modal.component.scss'],
})
export class UpdateProviderVideoModalComponent implements OnInit {
	public videoUrl: any;
	constructor(
		private sanitizer: DomSanitizer,
		private dialogRef: MatDialogRef<UpdateProviderVideoModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit() {
		this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url + '?autoplay=1');
	}

	close(): void {
		this.dialogRef.close();
	}
}
