import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PrintService {
	constructor() {}

	printDocument(header: string, content: string, printComponent: any, title: string) {
		const printHTML =
			'<html><head><body><div>' +
			`<h3>${title}</h3>` +
			'<table class="table table-striped">' +
			'<thead">' +
			`<tr>${header}</tr>` +
			'</thead>' +
			`<tbody>${content}</tbody>` +
			'</table></body></head></html>';

		printComponent.print(printHTML);
	}
}
