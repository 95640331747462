<section class="immunization-wrapper general-wrapper enable-wellness">
	<div class="container">
		<div class="row">
			<div class="col-md-12 g-right">
				<div class="i-c-wrapper">
					<p class="desc mt-3" *ngIf="!upcomingTravelAppt">
						<span
							class="text-url"
							[translate]="'wellness.immunization.description-link'"
							[routerLink]="['/scheduling/create/appointment']"
							appEheAnalytics
							[data]="{
								pageUrl: '/wellness/immunization',
								redirectedTo: '/scheduling/create/appointment',
								componentName: 'immunization',
								id: 'Schedule ehe travel consultation',
								placement: 'immunization'
							}"
							>Click here</span
						>
						<span [translate]="'wellness.immunization.description'"
							>to schedule an EHE travel consultation (available at our clinics in the following locations: New York,
							NY; Stamford, CT; Morristown, NJ; Chicago, IL; Houston, TX; Boston, MA; and McLean, VA).
						</span>
					</p>
					<div id="print_div" #print_div></div>

					<div class="i-c-actions">
						<div class="row">
							<div class="col-md-12">
								<div class="float-left"></div>
								<div class="float-right" *ngIf="isRecords()">
									<e-ngx-print
										#print2
										[mode]=""
										[popTitle]="' '"
										[showBtn]="false"
										[printHTML]="print_div"
										[printStyle]="printStyle"
										[printCSS]="printCSS">
									</e-ngx-print>
									<i class="fas fa-print" (click)="customPrint()" appEheAnalytics [data]="analyticsData"></i>
									<!-- <i class="far fa-trash-alt" (click)="deleteImmunization()"></i> -->
								</div>
							</div>
						</div>
					</div>
					<div class="i-c-header">
						<div class="row">
							<div class="col-md-12">
								<table matSort class="table-header" matSortActive="date" matSortDirection="desc" matSortDisableClear>
									<div class="table-header-desc">
										<div class="desc">
											<div mat-sort-header="name" [translate]="'wellness.immunization.table.type'">
												<span class="text-center">Vaccine</span>
											</div>
										</div>
									</div>
									<div class="table-header-desc">
										<div class="desc">
											<div mat-sort-header="date" [translate]="'wellness.immunization.table.date'">
												<span class="text-center">Date</span>
											</div>
										</div>
									</div>
									<div class="table-header-desc last">
										<div class="desc" [translate]="'wellness.immunization.table.source'">Source</div>
									</div>
								</table>
							</div>
						</div>
					</div>
					<div class="text-center" *ngIf="!isRecords()">No records to display</div>
					{{ profile ? updateVaccinesList() : '' }}
					<ng-container *ngFor="let data of immunizationData">
						<mat-accordion>
							<mat-expansion-panel [hideToggle]="true" class="p-e-n">
								<mat-expansion-panel-header>
									<mat-panel-description class="mat-cell">{{
										data.name === getCovid19VaccineName() ? 'COVID-19 (' + data.name + ')' : data.name
									}}</mat-panel-description>
									<mat-panel-description class="mat-cell">{{ data.date }}</mat-panel-description>
									<mat-panel-description class="mat-cell">
										<div class="">
											<span>{{ data.source }}</span>
											<a
												(click)="tryDownloadVaxPassPDF()"
												class="link"
												*ngIf="covid19VaccineOptions.showPdfDownloadLink && data.name === getCovid19VaccineName()">
												<span class="pl-1" [translate]="'vax-pass.card.download-pdf'">(Download PDF)</span>
											</a>
										</div>
									</mat-panel-description>
								</mat-expansion-panel-header>
								<mat-expansion-panel-header
									*ngIf="covid19VaccineOptions.showQRCodesAndClearTile && data.name === getCovid19VaccineName()"
									class="save-covid-records">
									<mat-panel-description class="mat-cell covid-links">
										<div class="">
											<div class="col-md-6 third-col">
												<div class="qr-code">
													<p [translate]="'vax-pass.card.save-covid-record'">
														Save your COVID-19 vaccination record to your Apple Wallet or Google Pay.
													</p>
													<p [translate]="'vax-pass.card.scan-qr-code'">
														<b>Scan below with your phone camera.</b>
													</p>
													<div class="row qr-code-icon">
														<div *ngIf="getQRCodeUrl('APPLE')" class="col md-4 qr-code-col">
															<img [src]="getQRCodeUrl('APPLE')" alt="QRCODE" />
															<div class="apple">
																<span [translate]="'vax-pass.card.apple-users'">Apple Users</span>
															</div>
														</div>
														<div *ngIf="getQRCodeUrl('GOOGLE')" class="col md-4 qr-code-col">
															<img [src]="getQRCodeUrl('GOOGLE')" alt="QRCODE" />
															<div class="android">
																<span [translate]="'vax-pass.card.android-users'">Android Users</span>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-lg-1 second-col">
												<h5>OR</h5>
											</div>
											<div class="col first-col">
												<div class="clear">
													<img src="/assets/images/clear-logo.svg" alt="clear" />
													<p [translate]="'vax-pass.card.link-covid-record-to-clear'">
														Link your COVID-19 vaccination record to CLEAR Health Pass for easy entry to locations
														nationwide.
													</p>
													<button class="button" mat-button>
														<a
															href="https://clear.app.link/ehe_health"
															target="_blank"
															appGoogleAnalytics
															[eventData]="{
																eventCategory: 'Link',
																eventAction: 'Click - Link My Record',
																eventLabel: 'VaxStatus - Link My Record'
															}">
															<span [translate]="'vax-pass.card.clear-link'">Link My Record</span>
														</a>
													</button>
													<div>
														<button class="button" mat-stroked-button>
															<a
																href="https://ehe.health/clear"
																target="_blank"
																appGoogleAnalytics
																[eventData]="{
																	eventCategory: 'Link',
																	eventAction: 'Click - Learn more',
																	eventLabel: 'VaxStatus - Learn more Clear link'
																}"
																>Learn more</a
															>
														</button>
													</div>
												</div>
											</div>
										</div>
									</mat-panel-description>
								</mat-expansion-panel-header>
							</mat-expansion-panel>
						</mat-accordion>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</section>
<app-ehe-spinner></app-ehe-spinner>
