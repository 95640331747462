<div class="member-info-confirmation-modal-wrapper">
	<app-white-overlay>
		<div class="container">
			<div class="content">
				<div *ngIf="!editInfo">
					<p class="title">Hello! Is the below info correct?</p>
					<div class="account-info">
						<div class="left-row">
							<span [translate]="'dashboard.member-account-info.first-name'"></span>
							<br />
							<span [translate]="'dashboard.member-account-info.last-name'"></span>
							<br />
							<span [translate]="'dashboard.member-account-info.home-address'"></span>
							<br />
							<br />
							<span [translate]="'dashboard.member-account-info.preferred-email'"></span>
							<br />
							<span [translate]="'dashboard.member-account-info.cell-phone'"></span>
							<br />
						</div>
						<div class="right-row">
							<span>{{ profile?.firstName }}</span> <br />
							<span>{{ profile?.lastName }}</span> <br />
							<span>{{ profile?.address }}, {{ profile?.address2 }}</span
							><br />
							<span>{{ profile?.city }}, {{ profile?.state }} {{ profile?.zipCode }}</span
							><br />
							<span>{{ profile?.email }}</span
							><br />
							<span>{{ profile?.cellPhone | maskPhone }}</span>
						</div>
					</div>
					<div class="button-container">
						<div class="yes-btn">
							<button
								class="button"
								mat-button
								(click)="correctInfoConfirmation()"
								appGoogleAnalytics
								[eventData]="{
									eventCategory: 'Button',
									eventAction: 'Click - Yes',
									eventLabel: 'Member Details - Details Correct'
								}">
								<span class="btn-text">Yes, this is correct!</span>
							</button>
						</div>
						<div class="no-btn">
							<div class="stroked-btn">
								<button
									mat-stroked-button
									class="button center"
									(click)="incorrectInfoConfirmation()"
									appGoogleAnalytics
									[eventData]="{
										eventCategory: 'Button',
										eventAction: 'Click - No',
										eventLabel: 'Member Details - Need Changes'
									}"
									appEheAnalytics
									[data]="{
										pageUrl: '/dashboard',
										redirectedTo: '/account',
										componentName: 'member-info-confirmation-modal',
										id: 'Edit Personal Info',
										placement: 'Personal Info section'
									}">
									<span class="btn-text">No, I need to make edits</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="updateProfile">
				<div class="content">
					<div *ngIf="editInfo">
						<section>
							<form [formGroup]="updateProfileForm">
								<div class="row">
									<div class="col-md-12 title">
										<h3>My EHE Health Profile</h3>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<mat-form-field class="form-group">
											<mat-label>{{
												'account.profile.sections.personal-information.form.first-name' | translate
											}}</mat-label>
											<input matInput formControlName="firstName" required />
											<mat-error>
												<app-error-message [control]="'firstName'"></app-error-message>
											</mat-error>
										</mat-form-field>
									</div>

									<div class="col">
										<mat-form-field class="form-group">
											<mat-label>{{
												'account.profile.sections.personal-information.form.last-name' | translate
											}}</mat-label>
											<input matInput formControlName="lastName" required />
											<mat-error>
												<app-error-message [control]="'lastName'"></app-error-message>
											</mat-error>
										</mat-form-field>
									</div>
								</div>

								<div class="row">
									<div class="col">
										<mat-form-field class="form-group">
											<mat-label>{{
												'account.profile.sections.personal-information.form.address' | translate
											}}</mat-label>
											<input matInput formControlName="address" required />
											<mat-error>
												<app-error-message [control]="'address'"></app-error-message>
											</mat-error>
										</mat-form-field>
									</div>

									<div class="col">
										<mat-form-field class="form-group">
											<mat-label>Address Line 2</mat-label>
											<input matInput formControlName="address2" />
											<mat-error>
												<app-error-message [control]="'address2'"></app-error-message>
											</mat-error>
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<mat-form-field class="form-group">
											<mat-label>{{ 'account.profile.sections.personal-information.form.city' | translate }}</mat-label>
											<input matInput formControlName="city" required />
											<mat-error>
												<app-error-message [control]="'city'"></app-error-message>
											</mat-error>
										</mat-form-field>
									</div>

									<div class="col">
										<div class="row">
											<mat-form-field class="col-6">
												<mat-label>{{
													'account.profile.sections.personal-information.form.state' | translate
												}}</mat-label>
												<mat-select
													disableOptionCentering
													panelClass="custom-material-position"
													formControlName="state"
													required>
													<ng-container *ngFor="let state of states">
														<mat-option [value]="state">{{ state }}</mat-option>
													</ng-container>
												</mat-select>
												<mat-error>
													<app-error-message [control]="'state'"></app-error-message>
												</mat-error>
											</mat-form-field>

											<mat-form-field class="col-6">
												<mat-label>{{
													'account.profile.sections.personal-information.form.zip' | translate
												}}</mat-label>
												<input matInput formControlName="zipCode" required />
												<mat-error>
													<app-error-message [control]="'zipCode'"></app-error-message>
												</mat-error>
											</mat-form-field>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col">
										<mat-form-field class="form-group full-width">
											<mat-label>{{
												'account.profile.sections.personal-information.form.cellnumber' | translate
											}}</mat-label>
											<input matInput formControlName="cellPhone" required />
											<mat-error>
												<app-error-message [control]="'cellPhone'"></app-error-message>
											</mat-error>
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<mat-form-field class="form-group full-width">
											<mat-label>{{
												'account.profile.sections.email-addresses.form.preferred-email' | translate
											}}</mat-label>
											<input matInput formControlName="email" />
											<mat-error>
												<app-error-message [control]="'email'"></app-error-message>
											</mat-error>
										</mat-form-field>
									</div>
								</div>
								<div class="button-wrapper">
									<button
										class="button"
										mat-button
										appEheAnalytics
										[data]="{
											pageUrl: '/vaxstatus',
											redirectedTo: '/vaxstatus',
											componentName: 'member-info-confirmation-modal',
											id: 'Edit Personal Info',
											placement: 'Personal Info section'
										}"
										(click)="savePersonalInfo()">
										<span [translate]="'vax-pass.form.save-btn'">Save</span>
									</button>
									<button
										class="button"
										mat-stroked-button
										(click)="cancel()"
										[translate]="'vax-pass.form.cancel-btn'"
										appEheAnalytics
										[data]="{
											pageUrl: '/vaxstatus',
											redirectedTo: '/vaxstatus',
											componentName: 'member-info-confirmation-modal',
											id: 'Edit Personal Info',
											placement: 'Personal Info section'
										}">
										Cancel
									</button>
								</div>
							</form>
						</section>
					</div>
				</div>
			</div>
		</div>
	</app-white-overlay>
</div>
