import { Component, AfterViewInit, HostListener, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { ToasterService } from '../../services/core/toaster.service';
import { FindAppointmentService } from '../../services/scheduling/find-appointment/find-appointment.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { DOCUMENT } from '@angular/common';

declare let $: any; //  use of jQuery-not recommended though
const SIGN_IN_LIVE_CHAT_KEY = '91a19a66-056c-48b9-ab06-ad6de928edb3';

/**
 * Home component,
 *
 * Login component will be displayed inside home component in the case of normal flow
 * It's base component for login component and this will not be there in the case of Marketo
 */
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
})
export class HomeComponent implements AfterViewInit, OnInit, OnDestroy {
	simplified = true;
	disableToaster: boolean = false;
	applyVariation: boolean;
	inactivityLogoutDetected = false;
	inactivityErrorText = "You've been logged out due to inactivity.";

	private unsubscribe$ = new Subject<void>();

	private liveChatScript: any;
	private liveChatDivArr: any;

	/**
	 * When user clicks, closing toaster
	 */
	@HostListener('click') close() {
		this.toaster.hide();
	}

	constructor(
		public toaster: ToasterService,
		private findApptService: FindAppointmentService,
		private route: ActivatedRoute,
		private renderer2: Renderer2,
		@Inject(DOCUMENT) private _document
	) {
		route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
			if (params.clear) {
				this.disableToaster = true;
			}
			if (params.m && params.m === 'inactive') {
				this.inactivityLogoutDetected = true;
			}
		});
		route.data.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
			this.applyVariation = data.applyVariation;
			if (!this.applyVariation && !this.inactivityLogoutDetected) {
				window.history.pushState({ page: 1 }, '', '/');
			}
		});
		this.liveChatScript = this.renderer2.createElement('script');
	}

	ngOnInit() {
		// removeItem isSchedulerActivated on home view, fixes issue w/ A/B test booking simplified flow DE-2849
		sessionStorage.removeItem('isSchedulerActivated');
		if (!this.disableToaster) this.getAppointmentDataForToaster();
		this.appendLiveChatScript(SIGN_IN_LIVE_CHAT_KEY);
	}

	ngAfterViewInit() {
		if (window['dataLayer']) {
			window['dataLayer'].push({ event: 'optimize.activate-nonemail' });
			// window['dataLayer'].push({'event': 'optimize.activate-toaster'});
			window['dataLayer'].push({ event: 'optimize.activate-banner' });
		}
	}

	ngAfterViewChecked(): void {
		this.liveChatDivArr = this._document.getElementsByClassName('scs');
		if (this.liveChatDivArr?.length) {
			this.liveChatDivArr[0].style.display = 'block';
		}
	}

	ngOnDestroy(): void {
		this.renderer2.removeChild(this._document.body, this.liveChatScript);
		if (this.liveChatDivArr?.length) {
			this.liveChatDivArr[0].style.display = 'none';
		}
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	/**
	 * Append Live Chat script in the rendered DOM.
	 */
	appendLiveChatScript(apiKey: string) {
		this.liveChatScript.type = 'text/javascript';
		this.liveChatScript.id = 'scs';
		this.liveChatScript.src = `https://livechat.sentimentmetrics.com/javascripts/scs.embed.js?api-key=${apiKey}`;
		this.renderer2.appendChild(this._document.body, this.liveChatScript);
	}

	/**
	 * Gets appointment data for toaster
	 */
	getAppointmentDataForToaster() {
		this.findApptService
			.getAppointmentDataForToaster(7)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(
				res => {
					// Show toaster when optimize is enabled and toaster is not shown before
					if (res.count !== 0) {
						// disabling toaster, as it is overlapping with chat window
						// this.toaster.show(res);
					}
				},
				err => {
					console.error(`[${HomeComponent.name}][${this.getAppointmentDataForToaster.name}]`, 'An error occurred while getting appointment count', err);
				}
			);
	}

	getWrapperClass(): string {
		return this.applyVariation ? 'login-variation-wrapper' : 'login-wrapper';
	}
}
