import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class AppPermissionsGuard implements CanActivate, CanActivateChild {
	constructor(private readonly authService: AuthService) {}
	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		return this.canActivate(childRoute, state);
	}
	canActivate(
		_next: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (!this.authService.isTokenExpired()) {
			this.authService.loadAppLevelPermissions();
		}
		return true;
	}
}
