import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from 'src/app/services/core/profile.service';
import { DataCarrierService } from '../../../services/core/data-carrier.service';
import { JwtHelper } from '../../../services/core/jwt-helper.service';
import { Subscription } from 'rxjs';
import { Environment } from '../../../config/environment';
import { ServiceRouter } from '../../../services/core/http-service-router.service';
import { Config } from '../../../config/config';
import { METHOD_TYPE } from '../../../constants/service.constants';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationUtilityService } from '../utils/validation.util/validation-utility.service';
import * as moment from 'moment';
import { SpinnerComponent } from '../spinner/spinner.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-member-info-confirmation-modal',
	templateUrl: './member-info-confirmation-modal.component.html',
	styleUrls: ['./member-info-confirmation-modal.component.scss'],
})
export class MemberInfoConfirmationModalComponent implements OnInit {
	/* holds user profile info */
	profile: any;
	subscriptions: Subscription[] = [];
	editInfo = false;
	updateProfileForm: FormGroup;
	states = [];

	constructor(
		private dialogRef: MatDialogRef<MemberInfoConfirmationModalComponent>,
		private profileService: ProfileService,
		private dataCarrierService: DataCarrierService,
		private jwtService: JwtHelper,
		private serviceRouter: ServiceRouter,
		private config: Config,
		private environment: Environment,
		private router: Router,
		private spinner: SpinnerComponent,
		@Inject(MAT_DIALOG_DATA)
		public data: { editInfo: boolean; states: Array<string> }
	) {
		if (data) {
			this.states = data.states || [];
		}
		this.initializeProfileInfo();
		this.initializeForm();
	}

	ngOnInit(): void {
		if (!this.states || !Array.isArray(this.states) || (Array.isArray(this.states) && this.states.length === 0)) {
			this.getStates();
		}
	}

	initializeProfileInfo() {
		const profile = this.dataCarrierService.getChannel().getValue().profile;
		if (profile && profile.profileInfo != null) {
			this.profile = profile.profileInfo;
		} else {
			this.profileService.getProfileInfo().subscribe(response => {
				this.profile = response.profileInfo;
			});
		}
		this.getEmail();
	}
	initializeForm() {
		this.spinner.startLoader();
		this.updateProfileForm = new FormGroup({
			firstName: new FormControl(
				{ value: this.profile.firstName, disabled: true },
				Validators.compose([Validators.required])
			),
			lastName: new FormControl(
				{ value: this.profile.lastName, disabled: true },
				Validators.compose([Validators.required])
			),
			dateOfBirth: new FormControl(
				{ value: new Date(this.profile.dateOfBirth), disabled: true },
				Validators.compose([Validators.required])
			),
			gender: new FormControl(
				{ value: this.profile.gender, disabled: true },
				Validators.compose([Validators.required])
			),
			email: new FormControl({ value: '', disabled: true }, Validators.compose([Validators.required])),
			cellPhone: new FormControl(
				this.profile.cellPhone,
				Validators.compose([Validators.required, ValidationUtilityService.phoneNumberValidator])
			),
			address: new FormControl(
				this.profile.address,
				Validators.compose([Validators.required, ValidationUtilityService.addressValidator])
			),
			address2: new FormControl(this.profile.address2),
			zipCode: new FormControl(
				this.profile.zipCode,
				Validators.compose([Validators.required, ValidationUtilityService.zipCodeValidator])
			),
			city: new FormControl(
				this.profile.city,
				Validators.compose([Validators.required, ValidationUtilityService.cityValidator])
			),
			state: new FormControl(this.profile.state, Validators.compose([Validators.required])),
		});
		this.spinner.stopLoader();
	}

	/** closes modal once clicking yes button*/
	correctInfoConfirmation() {
		this.dialogRef.close(true);
	}

	/** closes modal and redirects user to edit their account info */
	incorrectInfoConfirmation() {
		this.editInfo = true;
		this.initializeForm();
		// this.dialogRef.close(false);
		// this.router.navigate(['/account'], { queryParams: { from: 'member-info-confirmation-modal'} });
	}
	getDate(date) {
		return moment(new Date(date)).format('MM/DD/YYYY');
	}
	savePersonalInfo() {
		const client = this.profile.client;
		this.profile = { ...this.profile, ...this.updateProfileForm.getRawValue() };
		this.profile.client = client;
		delete this.profile['email'];
		// Changing date format
		this.profile.dateOfBirth = this.getDate(this.profile.dateOfBirth);
		if (this.updateProfileForm.valid) {
			this.spinner.startLoader();
			const epmsPID = this.jwtService.getEpmsId();
			this.subscriptions.push(
				this.serviceRouter
					.makeRequest(
						this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
						this.config.url.profileApi.baseUrl + `/${epmsPID}/profile`,
						METHOD_TYPE.SECURED_POST,
						this.profile
					)
					.subscribe(
						(_response: any) => {
							const thisc = this;
							this.profileService.updateDataCarrier().subscribe(_res => {
								thisc.dialogRef.close(true);
								thisc.editInfo = false;
								this.spinner.stopLoader();
							});
						},
						err => {
							console.error(`[${MemberInfoConfirmationModalComponent.name}][${this.savePersonalInfo.name}]`, err);
						}
					)
			);
		}
	}

	cancel() {
		this.dialogRef.close();
	}

	getEmail() {
		const epmsPID = this.jwtService.getEpmsId();
		this.subscriptions.push(
			this.serviceRouter
				.makeRequest(
					this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
					this.config.url.profileEmailsApi.baseUrl + `/${epmsPID}/emails`,
					METHOD_TYPE.SECURED_GET,
					''
				)
				.subscribe(
					response => {
						response.forEach(email => {
							if (email.primary) {
								this.profile.email = email.email;
								this.updateProfileForm.get('email').setValue(this.profile.email);
							}
						});
					},
					_error => {}
				)
		);
	}

	getStates() {
		this.subscriptions.push(
			this.serviceRouter
				.makeRequest(
					this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
					this.config.url.schedulingApi.getStates,
					METHOD_TYPE.SECURED_GET,
					''
				)
				.subscribe(
					response => {
						this.states = response;
					},
					_error => {}
				)
		);
	}
}
