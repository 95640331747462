import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Directive which shows/hides password
 */
@Directive({
	selector: '[appShowHidePassword]',
})
export class ShowHidePasswordDirective {
	/**
	 * Marks the form as touched for all password fields
	 */
	@HostListener('input', ['$event'])
	onclick() {
		if (
			this.control &&
			(this.control.name === 'password' || this.control.name === 'confirmPassword') &&
			this.control.control
		) {
			this.control.control.markAsTouched();
		}
	}

	/** boolean to toggle between show/hide password */
	showPassword: boolean = false;

	/**
	 * Password directive constructor
	 * @param passwordElement
	 */
	constructor(
		private passwordElement: ElementRef,
		private control: NgControl
	) {
		setTimeout(() => {
			this.createShowOrHideElement();
		}, 1);
	}

	/**
	 * Toggles between show/hide
	 * @param showHideElement
	 */
	toggle(showHideElement: HTMLElement) {
		this.showPassword = !this.showPassword;
		if (this.showPassword) {
			this.passwordElement.nativeElement?.setAttribute('type', 'text');
			showHideElement.innerHTML = 'Hide';
		} else {
			this.passwordElement.nativeElement?.setAttribute('type', 'password');
			showHideElement.innerHTML = 'Show';
		}
	}

	/**
	 * Creates show/hide div and appends the same to password element
	 */
	createShowOrHideElement() {
		const parent = this.passwordElement.nativeElement.parentNode;
		const showHideElement = document.createElement('div');
		showHideElement.className = 'password-show-hide link';
		showHideElement.innerHTML = `Show`;
		showHideElement.addEventListener('click', event => {
			this.toggle(showHideElement);
		});
		parent.appendChild(showHideElement);
	}
}
