<div class="ehe-info-modal-wrapper" id="ehe-modal">
	<div class="ehe-modal">
		<div class="{{ data.type }}">
			<div class="ehe-modal-header text-18">{{ data.title }}</div>
		</div>
		<div class="ehe-modal-body" [innerHTML]="data.message"></div>
		<div class="ehe-modal-footer">
			<div class="right" *ngIf="isButtonActive('YESNO')">
				<span mat-dialog-close (click)="no()">NO</span>
				<span mat-dialog-close (click)="yes()">YES</span>
			</div>
			<div class="right" *ngIf="isButtonActive('OKAY')">
				<span mat-dialog-close (click)="ok()">OK</span>
			</div>
			<div class="center" *ngIf="isButtonActive('RESCHEDULE')">
				<button class="button" mat-button appEheAnalytics (click)="reschedule()">
					<span [translate]="'scheduling.appointment-cancellation.reschedule-button'">Reschedule Appointment</span>
				</button>
			</div>
		</div>
	</div>
</div>
