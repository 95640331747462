/** Configuration used for IMask plugin */
import IMask from 'imask/esm/index';
import { FactoryArg } from 'imask/esm/masked/factory';

const BLOCK_SPACE = '\u2007';
const IMASK_DEFAULT_OPTIONS: FactoryArg = {
	lazy: true,
	overwrite: 'shift',
	placeholderChar: BLOCK_SPACE,
};
const IMASK_DEFAULT_UNMASK = true;

const SIMPLE_MASKS = {
	NON_ZERO_DIGIT: {
		mask: IMask.MaskedRange,
		from: 1,
		to: 9,
	},
	ANY_DIGIT_4: '0000',
	ANY_DIGIT_5: '00000',
	DD: {
		mask: IMask.MaskedRange,
		from: 1,
		to: 31,
		autofix: 'pad',
	},
	MM: {
		mask: IMask.MaskedRange,
		from: 1,
		to: 12,
		autofix: 'pad',
	},
	YY: {
		mask: IMask.MaskedRange,
		from: parseInt(new Date().getFullYear().toString().substring(2)),
		to: 99,
		autofix: 'pad',
	},
	YYYY: {
		mask: IMask.MaskedRange,
		from: 1900,
		to: 9999,
		autofix: 'pad',
	},
	YYYY_BEFORE_5: {
		mask: IMask.MaskedRange,
		from: 1900,
		to: new Date().getFullYear() - 5,
		autofix: 'pad',
	},
};

type MaskConstantKeys =
	| 'ZIP'
	| 'YEAR'
	| 'MONTH_YEAR'
	| 'PHONE'
	| 'CELL_PHONE'
	| 'SIMPLIFIED_PHONE'
	| 'CREDIT_CARD_NUMBER'
	| 'CREDIT_CARD_CVV'
	| 'CREDIT_CARD_EXPIRY_DATE'
	| 'SSN'
	| 'DATE';
type MaskConstants = {
	[key in MaskConstantKeys]?: {
		iMaskArgs: FactoryArg;
		unmask?: boolean | 'typed';
	};
};

export const MASK_CONSTANTS: MaskConstants = {
	ZIP: {
		iMaskArgs: {
			mask: SIMPLE_MASKS.ANY_DIGIT_5,
			...IMASK_DEFAULT_OPTIONS,
		},
		unmask: IMASK_DEFAULT_UNMASK,
	},
	YEAR: {
		iMaskArgs: {
			...IMASK_DEFAULT_OPTIONS,
			mask: SIMPLE_MASKS.ANY_DIGIT_4,
		},
		unmask: IMASK_DEFAULT_UNMASK,
	},
	MONTH_YEAR: {
		iMaskArgs: {
			...IMASK_DEFAULT_OPTIONS,
			mask: 'MM/YYYY',
			format: str => str.trim(),
			blocks: {
				MM: SIMPLE_MASKS.MM,
				YYYY: SIMPLE_MASKS.YYYY,
			},
		},
		unmask: false,
	},
	PHONE: {
		iMaskArgs: {
			...IMASK_DEFAULT_OPTIONS,
			mask: 'D00.000.0000',
			blocks: { D: SIMPLE_MASKS.NON_ZERO_DIGIT },
		},
		unmask: IMASK_DEFAULT_UNMASK,
	},
	CELL_PHONE: {
		iMaskArgs: {
			...IMASK_DEFAULT_OPTIONS,
			mask: '(D00) 000-0000',
			blocks: { D: SIMPLE_MASKS.NON_ZERO_DIGIT },
		},
		unmask: IMASK_DEFAULT_UNMASK,
	},
	SIMPLIFIED_PHONE: {
		iMaskArgs: {
			...IMASK_DEFAULT_OPTIONS,
			mask: 'XXX.XXX.0000',
		},
		unmask: IMASK_DEFAULT_UNMASK,
	},
	CREDIT_CARD_NUMBER: {
		iMaskArgs: {
			...IMASK_DEFAULT_OPTIONS,
			mask: /^[1-9]\d{0,18}$/,
		},
		unmask: IMASK_DEFAULT_UNMASK,
	},
	CREDIT_CARD_CVV: {
		iMaskArgs: {
			...IMASK_DEFAULT_OPTIONS,
			mask: SIMPLE_MASKS.ANY_DIGIT_4,
		},
		unmask: IMASK_DEFAULT_UNMASK,
	},
	CREDIT_CARD_EXPIRY_DATE: {
		iMaskArgs: {
			...IMASK_DEFAULT_OPTIONS,
			mask: 'MM/YY',
			format: str => str.trim(),
			blocks: {
				MM: SIMPLE_MASKS.MM,
				YY: SIMPLE_MASKS.YY,
			},
		},
		unmask: false,
	},
	SSN: {
		iMaskArgs: {
			...IMASK_DEFAULT_OPTIONS,
			mask: '000.00.0000',
		},
		unmask: IMASK_DEFAULT_UNMASK,
	},
	DATE: {
		iMaskArgs: {
			...IMASK_DEFAULT_OPTIONS,
			mask: 'MM/DD/YYYY',
			lazy: true,
			format: str => str.trim(),
			blocks: {
				DD: SIMPLE_MASKS.DD,
				MM: SIMPLE_MASKS.MM,
				YYYY: SIMPLE_MASKS.YYYY,
			},
		},
		unmask: false,
	},
};
