import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ModalService } from './modal.service';
import { Config } from '../../config/config';
import { Router } from '@angular/router';
import { DataCarrierService } from './data-carrier.service';

/**
 * Common http service
 */
@Injectable()
export class HttpServices {
	/**
	 * Http Service constructor
	 * @param http - Angular Http Method object
	 * @param modalService - Notification modal Service Object
	 * @param config - Url Configuration
	 * @param router - Angular Router object
	 */
	constructor(
		private http: HttpClient,
		private modalService: ModalService,
		private config: Config,
		private router: Router,
		private dataCarrierService: DataCarrierService
	) { }

	/**
	 * Method to set X-auth-Token for secured calls
	 * */
	getHeaders(url) {
		let headers: any = new HttpHeaders().set('Content-type', 'application/json').set('Accept', 'application/json');

		if (
			!url.includes('public') &&
			((this.dataCarrierService.getChannel().getValue().isSimplified &&
				(url.includes('booking-simplified') || url.includes('pulse/wellness/api'))) ||
				(this.dataCarrierService.getChannel().getValue().isPulseVirtual && url.includes('pulse/wellness/api')))
		) {
			headers = headers.append('token', sessionStorage.getItem('token'));
		}
		return headers;
	}

	/**
	 * HTTP GET Method
	 * @param baseUrl - Service base URL
	 * @param requestUrl - Service request URL
	 */
	httpGet(baseUrl: string, requestUrl: string): Observable<any> {
		const finalUrl = `${baseUrl}${requestUrl}`;
		return this.http.get(finalUrl, { headers: this.getHeaders(finalUrl) }).pipe(
			map((res: any) => res),
			catchError(error => this.handleServerError(error, requestUrl, 'GET'))
		);
	}

	/**
	 * HTTP POST Method
	 * @param baseUrl - Service base URL
	 * @param requestUrl - Service request URL
	 * @param data - Data to send
	 */
	httpPost(baseUrl: string, requestUrl: string, data: any): Observable<any> {
		const finalUrl = `${baseUrl}${requestUrl}`;
		return this.http.post(finalUrl, data, { headers: this.getHeaders(finalUrl) }).pipe(
			map((res: any) => res),
			catchError(error => this.handleServerError(error, requestUrl, 'POST'))
		);
	}

	httpPostWithoutHeaders(baseUrl: string, requestUrl: string, data: any): Observable<any> {
		const finalUrl = `${baseUrl}${requestUrl}`;
		return this.http.post(finalUrl, data).pipe(
			map((res: any) => res),
			catchError(error => this.handleServerError(error, requestUrl, 'POST'))
		);
	}

	httpGetWithoutHeaders(baseUrl: string, requestUrl: string): Observable<any> {
		const finalUrl = `${baseUrl}${requestUrl}`;
		return this.http.get(finalUrl).pipe(
			map((res: any) => res),
			catchError(error => this.handleServerError(error, requestUrl, 'POST'))
		);
	}
	/**
	 * HTTP PUT Method
	 * @param baseUrl - Service base URL
	 * @param requestUrl - Service request URL
	 * @param data - Data to send
	 */
	httpPut(baseUrl: string, requestUrl: string, data: any): Observable<any> {
		const finalUrl = `${baseUrl}${requestUrl}`;
		return this.http.put(finalUrl, data, { headers: this.getHeaders(finalUrl) }).pipe(
			map((res: any) => res),
			catchError(error => this.handleServerError(error, requestUrl, 'PUT'))
		);
	}

	/**
	 * HTTP DELETE Method
	 * @param baseUrl - Service base URL
	 * @param requestUrl - Service request URL
	 */
	httpDelete(baseUrl: string, requestUrl: string): Observable<any> {
		const finalUrl = `${baseUrl}${requestUrl}`;
		return this.http.delete(finalUrl, { headers: this.getHeaders(finalUrl) }).pipe(
			map((res: any) => res),
			catchError(error => this.handleServerError(error, requestUrl, 'DELETE'))
		);
	}

	/**
	 * Common place to handle the errors throwing by HTTP Methods
	 * @param error - Error Object throwing by HTTP Method
	 * @param requestUrl - Request url which threw the error
	 * @param methodType - HTTP Method type
	 */
	handleServerError(error, requestUrl, methodType): Observable<never> | Promise<boolean> {
		/** Suppressing the notification toaster */
		console.error(`[${HttpServices.name}]`, error, requestUrl, methodType);
		if (
			methodType === 'GET' &&
			requestUrl.startsWith(`${this.config.url.registrationApi.getSystemEmail}/email?encodedId`)
		) {
			return throwError(error || 'Server error');
		}
		/**
		 * In the case of 401 or 403, showing session expiry modal
		 * clearing session & local storage and return back to login page
		 */
		if (error.status === 401 || error.status === 403) {
			if (requestUrl.includes('/epmsproxy/public/api/phr')) {
				return throwError(error || 'Server error');
			} else if (!this.dataCarrierService.getChannel().getValue().isSimplified) {
				this.modalService.openSessionExpireModal('SESSION EXPIRED', 'Please login to continue');
			}
			localStorage.clear();
			sessionStorage.clear();
			return this.router.navigate(['login']);
		} else if (error.status === 400) {
			// Password Update Call
			if (methodType === 'PUT' && requestUrl.startsWith(this.config.url.registrationApi.baseApi)) {
				this.modalService.showToaster(this.config.exceptions.service.ACCOUNT_NOT_VERIFIED);
			} else if (
				methodType === 'POST' &&
				requestUrl.startsWith(this.config.url.registrationApi.baseApi) &&
				((error.error &&
					error.error.message &&
					error.error.message === this.config.exceptions.service.USER_ALREADY_ACTIVATED_ON_EHE_ME) ||
					(error.error &&
						error.error.message &&
						error.error.message === this.config.exceptions.service.EMAIL_TAKEN_UP) ||
					(error.error &&
						error.error.message &&
						error.error.message === this.config.exceptions.service.EMAIL_DOES_NOT_MATCH) ||
					(error.error &&
						error.error.message &&
						error.error.message === this.config.exceptions.service.EMAIL_ALREADY_EXISTS) ||
					(error.error &&
						error.error.message &&
						error.error.message === this.config.exceptions.service.USER_ALREADY_AVAILABLE) ||
					(error.error && error.error.message && error.error.message === this.config.exceptions.service.NO_MATCH) ||
					(error.error &&
						error.error.message &&
						error.error.message === this.config.exceptions.service.INVALID_VERIFICATION_CODE) ||
					(error.error &&
						error.error.message &&
						error.error.message === this.config.exceptions.service.EMAIL_CLAIMED_BY_PRIMARY) ||
					(error.error &&
						error.error.message &&
						error.error.message === this.config.exceptions.service.EMPLOYER_NOT_ACTIVATED_IN_MYEHE) ||
					(error.error &&
						error.error.message &&
						error.error.message === this.config.exceptions.service.MATCH_NOT_FOUND) ||
					(error.error &&
						error.error.message &&
						error.error.message === this.config.exceptions.service.INVALID_REQUEST))
			) {
				// Hiding toaster for already activated user on EHE&me
			} else if (methodType === 'POST' && requestUrl === this.config.url.registrationApi.baseApi && error.error.data) {
				// Hiding toaster for user migration create account flow
			} else if (
				(methodType === 'GET' || methodType === 'PUT') &&
				requestUrl.includes('/epmsproxy/booking-simplified/api/appointments')
			) {
				// Hiding toaster for booking simplified flow
			} else if (
				methodType === 'GET' &&
				requestUrl.includes(this.config.url.simplifiedSchedulingApi.getProcedure) &&
				error.error.message &&
				error.error.message === this.config.exceptions.service.SIMPLIFIED_NO_PROCEDURE
			) {
				// Hiding toaster for booking simplified flow
			} else if (
				methodType === 'GET' &&
				(
					requestUrl.startsWith(this.config.url.simplifiedSchedulingApi.getProviders) ||
					requestUrl.startsWith(this.config.url.simplifiedSchedulingApi.getProviderSlots)
				)
			) {
				// Hiding toaster for booking simplified flow - for get providers & get providerSlots
			} else if (
				methodType === 'GET' &&
				requestUrl.includes('/epmsproxy/booking-simplified/api/locations/alternative')
			) {
				// Hiding toaster for booking simplified flow - get alternate locations
				return throwError(error.error || 'Server error');
			} else if (methodType === 'GET' && requestUrl.includes('/epmsproxy/public/api/patient/migration-status')) {
				return throwError(error.error || 'Server error');
			} else {
				this.modalService.showToaster(error.error.message);
			}
			return throwError(error || 'Server error');
		} else if (error.status === 500) {
			if ((methodType === 'POST' && requestUrl === this.config.url.profileApi.bookingSimplifiedToken)
				|| (requestUrl?.contains(this.config.url.profileApi.pwEligibility))) {
				// Hiding toaster for non-email booking simplified flow
				return throwError(error || 'Server error');
			}
			this.modalService.showToaster(error.error.message);
			return throwError(error || 'Server error');
		} else {
			this.modalService.showWarningToaster();
			return throwError(error || 'Server error');
		}
	}
}
