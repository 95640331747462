import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { GaugeComponent } from './gauge/gauge.component';

@NgModule({
	imports: [CommonModule],
	declarations: [ProgressBarComponent, GaugeComponent],
	exports: [ProgressBarComponent, GaugeComponent],
})
export class ChartsModule {}
