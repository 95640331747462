import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
/**
 * Spinner component
 */
@Component({
	selector: 'app-ehe-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
	/**
	 * Spinner component constructor
	 * @param spinner - NgxSpinner Service
	 */
	constructor(private spinner: NgxSpinnerService) {}

	loaderCount = 0;

	/** trigger the loader */
	startLoader() {
		this.spinner.show();
	}
	/** stop the loader */
	stopLoader() {
		this.spinner.hide();
	}
}
