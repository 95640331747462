import { Injectable } from '@angular/core';
import { Environment } from '../../config/environment';
import { ServiceRouter } from '../core/http-service-router.service';
import { Config } from '../../config/config';
import { METHOD_TYPE } from '../../constants/service.constants';
import { NewsFeed, PECount } from '../../beans/dashboard/dashboard-module.bean';
import { forkJoin, map, Observable } from 'rxjs';
import { JwtHelper } from '../core/jwt-helper.service';

@Injectable({
	providedIn: 'root',
})
export class FeedsService {
	constructor(
		private serviceRouter: ServiceRouter,
		private environment: Environment,
		private config: Config
	) {}

	getNewsFeeds(): Observable<any> {
		const self = this;
		return self.serviceRouter.makeRequest(
			self.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			self.config.url.dashboard.getNewsFeeds,
			METHOD_TYPE.SECURED_GET,
			''
		);
	}

	getPECount(): Observable<PECount> {
		const self = this;
		const getPECountRequest = self.serviceRouter.makeRequest(
			self.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			self.config.url.dashboard.getPECount,
			METHOD_TYPE.SECURED_GET,
			''
		);
		const pwEligibilityRequest = self.serviceRouter.makeRequest(
			this.environment.getEnvironment().PULSE_WELLNESS_URL + this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN,
			this.config.url.pulseWellnessApi.checkEligibility,
			METHOD_TYPE.SECURED_GET,
			'',
			this.environment.getEnvironment().PULSE_WELLNESS_URL,
			this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN
		);
		return forkJoin([getPECountRequest, pwEligibilityRequest]).pipe(
			map(([peCountResponse, pwEligibilityResponse]) => {
				const pulseVirtualStatusDetails = {
					isEligibleForPW: pwEligibilityResponse.data.isEligibleForPW,
					isPWAppointmentBooked: pwEligibilityResponse.data.isPWAppointmentBooked,
					isPWOnlyClientUser: pwEligibilityResponse.data.isPWOnlyClientUser,
					isRosterEligible: pwEligibilityResponse.data.isRosterEligible,
					isPWAppointmentBookable: pwEligibilityResponse.data.isAppointmentBookable
				};
				peCountResponse.pulseVirtualStatusDetails = pulseVirtualStatusDetails;
				return peCountResponse;
			})
		);
	}
}
