import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ValidationUtilityService } from '../../modules/shared/utils/validation.util/validation-utility.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { AuthService } from '../../services/core/auth.service';
import { ErrorMessages } from '../../config/error-messages';
import { SpinnerComponent } from '../../modules/shared/spinner/spinner.component';
import { AppUtil } from '../../utils/app.util';
import { AnalyticsBean } from '../../beans/analytics/analytics-module.bean';
import { Config } from '../../config/config';
import { Environment } from '../../config/environment';
import { Subscription } from 'rxjs';
import { MigrationStatusService } from '../../services/migration/migration-status.service';
import emailMask from 'email-mask';
import { MASK_CONSTANTS } from '../../constants/mask.constants';
import { ProfileService } from '../../services/core/profile.service';
import { INCENTIVE_CONSTANTS } from 'src/app/constants/scheduling.constants';

/**
 * forgot password component
 */
@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	/** forgot password form */
	requestVerificationCodeForm: FormGroup;
	/** flag denote error from cognito */
	ifAnyError = false;
	/** holding analytics data for the component */
	analyticsData: AnalyticsBean;
	/** error message from cognito */
	errorMsg: string;
	/** Subscription */
	subscription: Subscription[] = [];
	/** Return Url */
	returnUrl = '';
	/** flag to show error message */
	showErrorMsg = false;
	/** successMessage */
	successMessage = '';
	/** Flag to check if link has been expired */
	linkExpired = false;
	/* The radio button which is selected on the page */
	selectedRadio: FormControl = new FormControl();
	/* The number of times the user has submitted and failed */
	numOfFailures: number = 0;
	/** flag to determine if limit exceeded */
	limitExceededError = false;
	/** Holds user form */
	userForm: FormGroup;
	/** Flag to determine if password hint has to be shown or not */
	showHint = true;
	/** Date Format */
	dateFormat: string = ValidationUtilityService.dateFormat;
	/** Holds dob mask */
	dobMask = MASK_CONSTANTS.DATE;
	/** Flag to determine if email has been sent or not */
	forgotUserEmailSent: boolean = false;
	/** Number of attempts user tries to retrieve user email */
	invalidAttempt: number = 0;
	/** Flag to determine if user doesn't have valid email in file */
	invalidEmail: boolean = false;

	/**
	 * Forgot password component constructor
	 * @param router Angular Router
	 * @param authService Cognito related services
	 * @param fb Angular form builder
	 * @param errorMessages Error message config
	 * @param spinner Spinner services
	 */
	constructor(
		private router: Router,
		private authService: AuthService,
		private fb: FormBuilder,
		private errorMessages: ErrorMessages,
		private spinner: SpinnerComponent,
		private config: Config,
		private environment: Environment,
		private activatedParams: ActivatedRoute,
		private migrationService: MigrationStatusService,
		private profileService: ProfileService
	) {
		this.analyticsData = new AnalyticsBean();
		this.analyticsData.componentName = 'forgot-password';
		this.analyticsData.pageUrl = '/forgot/password';
		this.activatedParams.queryParams.subscribe(params => {
			if (params['linkExpired']) {
				this.linkExpired = true;
			}
		});
	}

	/**
	 * on component init, creating the forgot password form
	 */
	ngOnInit() {
		this.selectedRadio.setValue('forgot-password');
		this.generateVerificationCodeForm();
		this.subscription.push(
			this.activatedParams.queryParams.subscribe(param => {
				if (param.returnUrl) {
					this.returnUrl = param.returnUrl;
				}
			})
		);
		this.initializeUserForm();
	}

	/**
	 * Initializes user form
	 */
	initializeUserForm() {
		this.userForm = new FormGroup({
			firstName: new FormControl('', Validators.compose([Validators.required])),
			lastName: new FormControl('', Validators.compose([Validators.required])),
			dateOfBirth: new FormControl(
				'',
				Validators.compose([
					Validators.required,
					ValidationUtilityService.dateValidator,
					ValidationUtilityService.dateValidatorNonFuture,
				])
			),
		});
	}

	/**
	 * Constructing the forgot password form with field email
	 */
	generateVerificationCodeForm() {
		this.requestVerificationCodeForm = new FormGroup({
			email: new FormControl(
				'',
				Validators.compose(
					// 128 is hard limit for cognito email verification. 80 is kept to be in sync with epms
					[Validators.required, ValidationUtilityService.cognitoUsernameLengthValidator, Validators.maxLength(80)]
				)
			),
		});
	}

	/**
	 * Check user migration status and then proceed to forgot password flow
	 */
	validateUserStatus() {
		this.requestVerificationCodeForm.get('email').markAsTouched();
		const userEmail = this.requestVerificationCodeForm.getRawValue().email;
		if (this.requestVerificationCodeForm.get('email').invalid) {
			if (this.requestVerificationCodeForm.get('email').value.length == 0) {
				this.requestVerificationCodeForm.get('email').setErrors({ required: true });
			}
			return;
		}
		// EHE+Me Login succeeded, checking migration status of the user
		this.spinner.startLoader();
		this.migrationService.getMigrationStatus(userEmail).subscribe(
			response => {
				if (response.migrationStatus === 'Initiated') {
					// Initialized - Email exists on file - collect code and complete migration process
					const navigationExtras: NavigationExtras = {
						queryParams: {
							user: btoa(JSON.stringify(response)),
							returnUrl: this.returnUrl,
						},
					};
					this.spinner.stopLoader();
					this.router.navigate(['/migration/password'], navigationExtras);
				} else if (response.migrationStatus === 'Failed') {
					// Status is completed - so the user goes through Cognito reset password flow
					this.invalidEmail = true;
					this.spinner.stopLoader();
				} else if (response.migrationStatus === 'Completed' && !AppUtil.isValidEmail(userEmail)) {
					// Status is completed - so the user goes through Cognito reset password flow
					this.showErrorMsg = true;
					this.errorMsg = 'Please use your recently updated myEHE email to reset your password';
					this.spinner.stopLoader();
				} else {
					this.requestVerificationCode();
				}
			},
			err => {
				console.error(`[${ForgotPasswordComponent.name}][${this.validateUserStatus.name}]`, 'An error occurred while fetching migration status of the user ', err);
				this.requestVerificationCode();
			}
		);
	}

	/**
	 * Requesting verification code from cognito to the provided email id.
	 * on success redirecting to reset password page
	 */
	requestVerificationCode() {
		this.analyticsData.id = 'next-go to enter email';
		this.analyticsData.redirectedTo = 'request/email';
		this.analyticsData.placement = 'Login forgot password';
		let userEmail = this.requestVerificationCodeForm.getRawValue().email;
		userEmail = AppUtil.getFormattedEmail(userEmail);
		if (this.requestVerificationCodeForm.valid) {
			this.spinner.startLoader();
			if (AppUtil.isValidEmail(userEmail)) {
				// specifically encoding the return url if we have promo or referral related information
				// can be encoded for all types of urls but will have to test them
				if (
					this.returnUrl.includes(`${INCENTIVE_CONSTANTS.PROMO_QUERY_KEY}=`) ||
					this.returnUrl.includes(`${INCENTIVE_CONSTANTS.REFERRAL_QUERY_KEY}=`)
				) {
					this.returnUrl = encodeURIComponent(this.returnUrl);
				}
				this.authService.forgotPassword(userEmail, true, this.returnUrl);
				this.authService.getResult().subscribe(
					res => {
						this.spinner.stopLoader();
						if (res.success) {
							this.successMessage = `<p>Please check <b>${emailMask(
								userEmail,
								'*'
							)}</b> for an EHE Health reset password email. If you do not receive one shortly, please check the following and try again:</p>
 <ul>
  <li>Did you enter username/email correctly?</li>
  <li>Is the provided email associated with your EHE Health benefit?</li>
  <li>Do you have an account? <a class="link" href="/registration">Create an account</a></li>
 </ul>`;
						}
					},
					error => {
						this.spinner.stopLoader();
						this.ifAnyError = true;
						this.errorMsg = '';
						this.numOfFailures += 1;
						// handling errors from cognito
						if (error.object.code === this.config.exceptions.cognito.USER_NOT_FOUND) {
							this.errorMsg = this.errorMessages.text.userDoesntExistError;
						} else if (error.object.code === this.config.exceptions.cognito.LIMIT_EXCEEDED) {
							this.errorMsg = this.errorMessages.text.limitExceededError;
							this.limitExceededError = true;
						} else if (error.object.code === this.config.exceptions.cognito.INVALID_PARAMETER) {
							this.errorMsg = this.errorMessages.text.invalidParameterError;
						} else {
							this.errorMsg = error.object.message;
						}
					}
				);
			} else {
				this.ifAnyError = true;
				this.errorMsg = this.errorMessages.text.userDoesntExistError;
				this.spinner.stopLoader();
			}
		} else {
			this.requestVerificationCodeForm.get('email').markAsTouched();
			this.spinner.stopLoader();
		}
	}

	/* When the user has 2+ failures and clicks 'Try again', we will reset the page */
	clearPage(): void {
		this.numOfFailures = 0;
		this.errorMsg = undefined;
		this.requestVerificationCodeForm.get('email').setValue(' ');
		this.requestVerificationCodeForm.get('email').markAsUntouched();
	}

	encodePartsOfUrl(originalString: string, expressiontoUse: string) {
		let queryIdx = -1;
		if ((queryIdx = originalString.lastIndexOf(expressiontoUse)) > -1) {
			originalString =
				originalString.substring(0, queryIdx - 1) +
				encodeURIComponent(originalString[queryIdx - 1]) +
				originalString.substring(queryIdx, originalString.length);
		}
		return originalString;
	}

	/* Will clear out the errors and failures if the button is toggled */
	toggleRadio(): void {
		this.numOfFailures = 0;
		this.errorMsg = undefined;
		this.successMessage = undefined;
		this.invalidAttempt = 0;
		this.invalidEmail = false;
		this.ifAnyError = false;
		this.forgotUserEmailSent = false;
		this.initializeUserForm();
	}

	inputBlur(): void {
		this.requestVerificationCodeForm.get('email').markAsUntouched();
	}

	/* Called when the user wants to navigate back to the sign in screen, will route to appropriate tab if necesssary*/
	returnToLogin(): void {
		// Adding parameter to display the correct tab when returning to login page
		const navigationExtras: NavigationExtras = {
			queryParams: {
				t: btoa('1'),
			},
		};
		this.router.navigate(['login'], navigationExtras);
	}

	ngOnDestroy() {
		this.subscription.forEach(subscription => subscription.unsubscribe());
	}

	/**
	 * Shows mat hint when there is no error
	 */
	changeHint() {
		this.showHint = this.userForm.get('dateOfBirth').errors ? false : true;
	}

	/**
	 * Gets user details for forgot username
	 */
	getUserDetails() {
		// After three invalid attempts, redirecting to login page
		if (this.invalidAttempt > 2) {
			this.router.navigate(['/login'], {
				queryParams: { tab: 'login', showErrorMsg: true },
			});
		} else if (this.userForm.valid) {
			// If the form is valid - making a service call to fetch user details
			this.spinner.startLoader();
			this.subscription.push(
				this.profileService.forgotEmail(this.userForm.getRawValue()).subscribe(
					response => {
						this.spinner.stopLoader();
						/** Case 1 - Roaster match is found */
						// An email has been sent successfully - response block
						this.forgotUserEmailSent = true;
						// eslint-disable-next-line max-len
						this.successMessage = `We have sent a message to <b>${emailMask(
							response.email,
							'*'
						)}</b> to confirm your account. If you do not see the email in a few minutes, check your spam folder.<br><br>No longer have access to that email address?<br>Contact Member Services at 800.362.8671 or memberservices@ehe.health`;
					},
					error => {
						this.spinner.stopLoader();
						if (error.error.message === 'No match found') {
							/** Case 2 - Roaster match not found */
							// No roster match
							this.invalidAttempt += 1;
							this.ifAnyError = true;
							this.errorMsg = 'We couldn’t find your profile. Please try again.';
						} else {
							/** Case 3 - Duplicate email - i.e., roster match is found with an invalid email  */
							// err block - duplicate email/ migrated with no email case i.e., an invalid email associated with user details
							this.invalidEmail = true;
						}
					}
				)
			);
		} else {
			this.showHint = this.userForm.get('dateOfBirth').errors ? false : true;
		}
	}
}
