import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MigrationComponent } from './migration.component';
import { MigrationPasswordComponent } from './migration-password/migration-password.component';
import { MigrationEmailEntryComponent } from './migraton-email-entry/migration-email-entry.component';

const routes: Routes = [
	{
		path: '',
		component: MigrationComponent,
		data: {
			title: 'Migration',
		},
		children: [
			{
				path: 'password',
				component: MigrationPasswordComponent,
				data: {
					title: 'Migration Password',
				},
			},
			{
				path: 'email',
				component: MigrationEmailEntryComponent,
				data: {
					title: 'Migration Email Entry',
				},
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class MigrationRoutingModule {}
