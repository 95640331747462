import { PwcLandingPageComponent } from './modules/pwc-landing-page/pwc-landing-page.component';
import { FeatureFlagGuardService } from './services/core/feature-flag-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { AuthGuard } from './services/core/auth-guard.service';
import { MaintenanceComponent } from './modules/shared/maintenance/maintenance.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { USER_LEVEL } from './constants/user.level.constants';
import { ReportComponent } from './modules/shared/reports/report.component';
import { HomeComponent } from './views/home/home.component';
import { BaseForgotPasswordComponent } from './views/base-forgot-password/base-forgot-password.component';
import { BaseResetPasswordComponent } from './views/base-reset-password/base-reset-password.component';
import { CustomPreloadStrategy } from './services/core/preload-module.service';
import { SimplifiedSchedulingGuard } from './services/core/simplified-scheduling.guard.service';
import { ActivateAccountComponent } from './views/activate-account/activate-account.component';
import { VaxStatusComponent } from './modules/wellness/vax-pass/vax-status/vax-status.component';
import { VaxPassComponent } from './modules/wellness/vax-pass/vax-pass.component';
import { APP_USER_PERMISSIONS } from './constants/app-user-permissions.constants';
import { AppPermissionsGuard } from './services/core/app-permissions.guard';
import { PulseVirtualGuard } from './services/core/pulse-virtual-guard.service';

const routes: Routes = [
	{
		path: 'registration',
		loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule),
		data: { preload: true },
	},
	{
		path: 'vaxstatus/:id',
		component: VaxStatusComponent,
		data: { preload: true, title: 'VaxStatus' },
	},
	{
		path: 'vaxstatus',
		component: VaxPassComponent,
		canActivate: [AuthGuard],
		data: { preload: true, title: 'VaxPass' },
	},
	{
		path: 'wellness/vaxpass',
		redirectTo: '/vaxstatus',
		pathMatch: 'full',
	},

	{
		path: 'account',
		loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
		canActivate: [AuthGuard],
		data: { preload: true },
	},
	{
		path: 'scheduling',
		loadChildren: () => import('./modules/scheduling/scheduling.module').then(m => m.SchedulingModule),
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			preload: true,
			permissions: {
				except: [USER_LEVEL.HUBBELL_NOT_ELIGIBLE.valueOf(), USER_LEVEL.UNMATCHED.valueOf()],
				redirectTo: '/dashboard',
				pathMatch: 'full',
			},
		},
	},
	{
		path: 'refer',
		loadChildren: () =>
			import('./modules/referral-promotion/referral-promotion.module').then(m => m.ReferralPromotionModule),
		data: {
			title: 'Refer to MyEHE',
		},
	},
	{
		path: 'wellness/health/history',
		redirectTo: '/assessment/info',
		pathMatch: 'full',
	},
	{
		path: 'assessment',
		loadChildren: () => import('./modules/ha-new/ha-new.module').then(m => m.HaNewModule),
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			preload: true,
			permissions: {
				except: [USER_LEVEL.HUBBELL_NOT_ELIGIBLE.valueOf(), USER_LEVEL.UNMATCHED.valueOf()],
				redirectTo: '/dashboard',
				pathMatch: 'full',
			},
			title: 'Health Assessment',
		},
	},

	{
		path: 'appointment',
		loadChildren: () => import('./modules/scheduling/scheduling.module').then(m => m.SchedulingModule),
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			preload: true,
			permissions: {
				except: [USER_LEVEL.HUBBELL_NOT_ELIGIBLE.valueOf(), USER_LEVEL.UNMATCHED.valueOf()],
				redirectTo: '/dashboard',
				pathMatch: 'full',
			},
		},
	},
	{
		path: 'faq',
		data: { preload: true },
		loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'terms',
		data: { preload: true },
		loadChildren: () => import('./modules/terms/terms.module').then(m => m.TermsModule),
	},
	{
		path: 'privacy-policy',
		data: { preload: true },
		loadChildren: () => import('./modules/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
	},
	{
		path: 'notice-of-privacy-practices',
		data: { preload: true },
		loadChildren: () =>
			import('./modules/notice-of-privacy-practices/notice-of-privacy-practices.module').then(
				m => m.NoticeOfPrivacyPracticesModule
			),
	},
	{
		path: 'hipaa-notice',
		redirectTo: '/notice-of-privacy-practices',
		pathMatch: 'full',
	},
	{
		path: 'privacy-shield',
		canActivate: [FeatureFlagGuardService],
		data: {
			preload: false,
			featureFlag: 'privacyShield',
		},
		loadChildren: () => import('./modules/privacy-shield/privacy-shield.module').then(m => m.PrivacyShieldModule),
	},
	{
		path: 'results',
		loadChildren: () => import('./modules/wellness/wellness.module').then(m => m.WellnessModule),
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			preload: true,
			permissions: {
				except: [USER_LEVEL.HUBBELL_NOT_ELIGIBLE.valueOf(), USER_LEVEL.UNMATCHED.valueOf()],
				redirectTo: '/dashboard',
				pathMatch: 'full',
			},
		},
	},
	{
		path: 'wellness/my/health',
		redirectTo: '/results',
		pathMatch: 'full',
	},
	{
		path: 'dashboard',
		data: { preload: true },
		loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
		canActivate: [AuthGuard, AppPermissionsGuard],
	},
	{
		path: '',
		component: HomeComponent,
		pathMatch: 'full',
		data: {
			title: 'Login',
		},
	},
	{
		path: 'login',
		component: HomeComponent,
		data: {
			title: 'Login',
		},
	},
	{
		path: 'embed/login',
		component: LoginComponent,
		data: {
			title: 'Login',
		},
	},
	{
		path: 'embed/signup',
		redirectTo: 'registration/embed/signup',
		pathMatch: 'full',
	},
	{
		path: 'embed/forgot-password',
		component: ForgotPasswordComponent,
		data: {
			title: 'Forgot Password',
		},
		pathMatch: 'full',
	},
	{
		path: 'forgot/password',
		component: BaseForgotPasswordComponent,
		data: {
			title: 'Forgot Password',
		},
	},
	{
		path: 'reset/password',
		component: BaseResetPasswordComponent,
		data: {
			title: 'Reset Password',
		},
	},
	{
		path: 'embed/reset-password',
		component: ResetPasswordComponent,
		data: {
			title: 'Reset Password',
		},
		pathMatch: 'full',
	},
	{
		path: 'wellness/health/record/view',
		component: ReportComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			preload: true,
			permissions: {
				except: [USER_LEVEL.HUBBELL_NOT_ELIGIBLE.valueOf(), USER_LEVEL.UNMATCHED.valueOf()],
				redirectTo: '/dashboard',
				pathMatch: 'full',
			},
			title: 'Health Documents - View',
		},
	},
	{
		path: 'wellness/referral',
		loadChildren: () => import('./modules/referral/referral.module').then(m => m.ReferralModule),
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			preload: true,
			permissions: {
				except: [USER_LEVEL.HUBBELL_NOT_ELIGIBLE.valueOf(), USER_LEVEL.UNMATCHED.valueOf()],
				redirectTo: '/dashboard',
				pathMatch: 'full',
			},
		},
	},
	{
		path: 'messaging',
		loadChildren: () => import('./modules/messaging/messaging.module').then(m => m.MessagingModule),
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			preload: true,
			permissions: {
				except: [USER_LEVEL.HUBBELL_NOT_ELIGIBLE.valueOf(), USER_LEVEL.UNMATCHED.valueOf()],
				redirectTo: '/dashboard',
				pathMatch: 'full',
			},
		},
	},
	{
		path: 'wellness/health/record',
		data: { preload: true },
		loadChildren: () => import('./modules/health-record/health-record.module').then(m => m.HealthRecordModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'health/mentor',
		loadChildren: () => import('./modules/health-mentor/health-mentor.module').then(m => m.HealthMentorModule),
		canActivate: [AuthGuard, AppPermissionsGuard, NgxPermissionsGuard],
		data: {
			preload: true,
			permissions: {
				except: [
					USER_LEVEL.HUBBELL_NOT_ELIGIBLE.valueOf(),
					USER_LEVEL.UNMATCHED.valueOf(),
					USER_LEVEL.CATALENT_CLIENT.valueOf(),
				],
				only: [APP_USER_PERMISSIONS.HM, APP_USER_PERMISSIONS.PW],
				redirectTo: '/dashboard',
				pathMatch: 'full',
			},
		},
	},
	{
		path: 'maintenance',
		component: MaintenanceComponent,
		data: {
			title: 'Maintenance',
		},
	},
	{
		path: 'bridge',
		loadChildren: () => import('./modules/bridge/bridge.module').then(m => m.BridgeModule),
	},
	{
		path: 'sso/saml',
		loadChildren: () => import('./modules/sso/sso.module').then(m => m.SsoModule),
	},
	{
		path: 'book',
		loadChildren: () =>
			import('./modules/scheduling-simplified/scheduling-simplified.module').then(m => m.SchedulingSimplifiedModule),
		canActivate: [SimplifiedSchedulingGuard],
	},
	{
		path: 'migration',
		loadChildren: () => import('./views/migration/migration.module').then(m => m.MigrationModule),
	},
	{
		path: 'pulse-virtual',
		loadChildren: () => import('./modules/pulse-virtual/pulse-virtual.module').then(m => m.PulseVirtualModule),
		canActivate: [PulseVirtualGuard],
	},
	{
		path: 'activate',
		component: ActivateAccountComponent,
		data: {
			title: 'Activate Account',
		},
	},
	{
		path: 'pwc-signin',
		component: PwcLandingPageComponent,
		data: {
			title: 'Welcome',
		},
	},
	{
		path: 'pulse-login',
		component: HomeComponent,
		data: {
			title: 'Login',
			applyVariation: true,
		},
	},
	{
		path: '**',
		component: HomeComponent,
		data: {
			title: 'Login',
			applyVariation: false,
		},
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: CustomPreloadStrategy,
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
			onSameUrlNavigation: 'reload',
		}),
	],
	exports: [RouterModule],
	providers: [CustomPreloadStrategy],
})
export class AppRoutingModule {}
