import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';

/*** App Config Service */
@Injectable()
export class AppConfigService {
	/** Angular HTTPClient */
	private http: HttpClient;
	/** App Config */
	private _appConfig = null;

	/**
	 * App Config Service
	 * @param handler - Angular HttpBackend
	 */
	constructor(handler: HttpBackend) {
		// Avoids the interceptors so that we can load the App Config without authentication headers.
		this.http = new HttpClient(handler);
	}
	/**
	 * Getting the assets/environment.json file and response storing to @var _appConfig
	 */
	load() {
		return this.http
			.get('/assets/environment.json?v=' + Math.random())
			.toPromise()
			.then(config => {
				this._appConfig = config;
			});
	}

	/** Getter function for @var _appConfig*/
	getConfig(): object {
		return this._appConfig;
	}

	/** Getter function for config with specific key */
	getConfigValue(key: string) {
		return this._appConfig ? this._appConfig[key] : null;
	}
}
