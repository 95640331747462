import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Generic modal to display wordpress contents
 */
@Component({
	selector: 'app-word-press-overlay',
	templateUrl: './word-press-overlay.component.html',
	styleUrls: ['./word-press-overlay.component.scss'],
})
export class WordPressOverlayComponent implements OnInit {
	safeUrl: SafeHtml;

	/**
	 * Component constructor
	 * @param sanitizer - dom sanitizer
	 * @param dialogRef - mat dialog reference
	 * @param data - dialog input data
	 */
	constructor(
		private sanitizer: DomSanitizer,
		private dialogRef: MatDialogRef<WordPressOverlayComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		window.onpopstate = function (event) {
			history.go(1);
		};
	}

	/**
	 * On component initialization
	 */
	ngOnInit() {
		this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
	}

	/**
	 * On dialog close
	 */
	close(): void {
		this.dialogRef.close(true);
	}
}
