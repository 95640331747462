<div class="content-box form-wrapper">
	<div class="align-center">
		<div class="pad-10">
			<span [translate]="'registration.migration.password-entry-header-one'">
				Welcome to an improved EHE Health digital experience!
			</span>
		</div>
		<div class="pad-10">
			<span [translate]="'registration.migration.email-entry'">
				In order to sign in, you will need to provide your email address
			</span>
		</div>
		<div class="pad-10 error-msg-color">{{ errorMsg }}</div>
	</div>

	<div [formGroup]="enterEmailForm">
		<div class="align-center">
			<mat-form-field class="form-group">
				<mat-label>{{ 'registration.migration.email' | translate }}</mat-label>
				<input matInput formControlName="email" type="text" />
				<mat-error>
					<app-error-message [control]="'email'"></app-error-message>
				</mat-error>
			</mat-form-field>
		</div>
	</div>

	<div class="align-center">
		<button
			class="button button-medium"
			mat-button
			(click)="updateEmailAndNavigate()"
			appEheAnalytics
			[data]="{
				pageUrl: '/migration/email',
				redirectedTo: '/migration/code',
				componentName: 'migration-email-entry',
				id: 'Migration Email',
				placement: 'Migration Email'
			}"
			appGoogleAnalytics
			[eventData]="{
				eventCategory: 'Button',
				eventAction: 'Migration-EmailEntry - Button',
				eventLabel: 'Email - Migration PasswordEntryComponent'
			}">
			<span [translate]="'registration.migration.next'">Next</span>
		</button>
	</div>
</div>
