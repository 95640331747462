import { Component, OnDestroy } from '@angular/core';
import { DataCarrierService } from 'src/app/services/core/data-carrier.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AnalyticsBean } from 'src/app/beans/analytics/analytics-module.bean';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssessmentVersion } from 'src/app/services/health-history/health-history.types';
import { AssessmentCheckService } from 'src/app/services/health-history/assessment-check.service';
import { Environment } from 'src/app/config/environment';
import { ServiceRouter } from 'src/app/services/core/http-service-router.service';
import { Config } from 'src/app/config/config';
import { METHOD_TYPE } from 'src/app/constants/service.constants';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AppUtil } from 'src/app/utils/app.util';

@Component({
	selector: 'app-reports',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnDestroy {
	pdfBase64: string;
	pdfArrayBuffer: ArrayBuffer;
	id: any;
	epmsPID: any;
	documentName: any;
	pdfView = false;
	analyticsData: AnalyticsBean;
	redirectUrl: any;
	queryParams: any = {};
	printIframe: HTMLIFrameElement;
	private _destroyed$ = new Subject();
	constructor(
		private dataCarrierService: DataCarrierService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private assessmentCheckService: AssessmentCheckService,
		private environment: Environment,
		private serviceRouter: ServiceRouter,
		private config: Config,
		private spinner: SpinnerComponent
	) {
		this.analyticsData = new AnalyticsBean();
		this.analyticsData.componentName = 'reports';
		this.analyticsData.pageUrl = '/results';
		this.activatedRoute.queryParams.pipe(takeUntil(this._destroyed$)).subscribe(params => {
			if (params.fromBiometrics === 'true') {
				this.redirectUrl = '/results';
				this.queryParams = { fromBiometrics: true };
				this.openBiometricReport(params['q']);
			} else if (params.fromAssessment === 'true') {
				this.redirectUrl = '/wellness/health/old';
				this._getAssessmentVersion();
			} else if (params.fromBiometrics === 'false') {
				this.redirectUrl = '/results';
				this.queryParams = { fromBiometrics: false };
				this.openEheReport(params['q']);
			} else if (params.froms3 === 'true') {
				this.redirectUrl = '/results';
				this.queryParams = { fileName: params['q'] };
				this.openEhes3Report(params['q']);
			} else if (params.consent === 'true') {
				this.redirectUrl = '/results';
				this.queryParams = {
					fileName: params['q'],
					epmsPID: params['epmsPID'],
				};
				this.openRecordRelease(params['q'], params['epmsPID']);
			}
		});
	}

	generatePDFView(data) {
		if (
			this.environment.getEnvironment().ENV_KEY === 'dev' ||
			this.environment.getEnvironment().ENV_KEY === 'qa' ||
			this.environment.getEnvironment().CONSENT_DOMAIN === '/dev' ||
			this.environment.getEnvironment().CONSENT_DOMAIN === '/qa'
		) {
			data.content = window['pdfBase64'] ?? data.content;
		}
		this.pdfBase64 = 'data:application/pdf;base64,' + data.content;
		this.pdfArrayBuffer = AppUtil.base64ToArrayBuffer(data.content);
		this.id = data.id;
		this.epmsPID = data.epmsPID;
		this.documentName = data.documentName;
		this.pdfView = true;
	}

	closePdf() {
		this.analyticsData.id = 'Close health report pdf';
		this.analyticsData.redirectedTo = this.redirectUrl;
		this.analyticsData.placement = 'Health documents pdf';
		if (this.printIframe) {
			try {
				document.body.removeChild(this.printIframe);
			} catch (error) {}
		}
		if (this.queryParams) {
			this.router.navigate([this.redirectUrl], {
				queryParams: this.queryParams,
			});
		} else {
			this.router.navigate([this.redirectUrl]);
		}
	}

	downloadPdf() {
		this.analyticsData.id = 'Download health report pdf';
		this.analyticsData.redirectedTo = this.redirectUrl;
		this.analyticsData.placement = 'Health documents pdf';

		const pdfMime = 'application/pdf';
		const pdfBlob = new Blob([this.pdfArrayBuffer], { type: pdfMime });
		const pdfFileName = this.epmsPID + '_' + this.documentName + '.pdf';

		AppUtil.downloadFile(pdfBlob, pdfFileName, pdfMime);
	}

	/**
	 *
	 * @param {*} e
	 * @desc Get event when a page is rendered. Called for every page rendered.
	 * called on (page-rendered) property of the pdf-viewer
	 */
	renderPage(e: any): void {}

	errorCallback(e: any) {
		console.error(`[${ReportComponent.name}]`, 'PDF Error', e);
	}

	ngOnDestroy(): void {
		document.body.classList.remove('dashboard-bg');
		this._destroyed$.next(undefined);
		this._destroyed$.complete();
	}

	/**
	 *
	 * retrieves the AssessmentVersion for the user and then sends the data
	 * to _assessmentFormatCheck
	 * @private
	 * @memberof HeaderComponent
	 */
	private _getAssessmentVersion(): void {
		this.spinner.startLoader();
		this.assessmentCheckService
			.getAssessmentVersion()
			.pipe(takeUntil(this._destroyed$))
			.subscribe(
				(assessmentVersion: AssessmentVersion) => {
					this.spinner.stopLoader();
					const { oldFormat } = assessmentVersion;
					if (oldFormat === true) {
						const data = this.dataCarrierService.getChannel().getValue().assessmentPdf;
						data['content'] = assessmentVersion['content'];
						this.generatePDFView(data);
					}
				},
				err => {
					this.spinner.stopLoader();
				}
			);
	}

	openBiometricReport(selectedDocId) {
		this.spinner.startLoader();
		this.serviceRouter
			.makeRequest(
				this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
				this.config.url.healthRecord.getBiometricResults + `/${selectedDocId}`,
				METHOD_TYPE.SECURED_GET,
				''
			)
			.subscribe(
				response => {
					this.spinner.stopLoader();
					this.generatePDFView(response.data);
				},
				error => {
					this.spinner.stopLoader();
				}
			);
	}

	openEheReport(selectedDocId) {
		this.spinner.startLoader();
		this.serviceRouter
			.makeRequest(
				this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
				this.config.url.healthRecord.getHealthDocuments + `/${selectedDocId}`,
				METHOD_TYPE.SECURED_GET,
				''
			)
			.subscribe(
				response => {
					this.spinner.stopLoader();
					this.generatePDFView(response.data);
				},
				error => {
					this.spinner.stopLoader();
				}
			);
	}

	openEhes3Report(s3filename) {
		this.spinner.startLoader();
		this.serviceRouter
			.makeRequest(
				this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
				this.config.url.healthRecord.getHealthDocuments + `/document?fileName=${s3filename}`,
				METHOD_TYPE.SECURED_GET,
				''
			)
			.subscribe(
				response => {
					this.spinner.stopLoader();
					this.generatePDFView(response.data);
				},
				error => {
					this.spinner.stopLoader();
				}
			);
	}

	openRecordRelease(s3filename, epmsPID) {
		this.spinner.startLoader();
		this.serviceRouter
			.makeRequest(
				'https://' + this.environment.getEnvironment().CONSENT_URL,
				this.config.url.healthRecord.viewRecordRelease + `?epmsPID=${epmsPID}&fileName=${s3filename}`,
				METHOD_TYPE.SECURED_GET,
				'',
				this.environment.getEnvironment().CONSENT_URL,
				this.environment.getEnvironment().CONSENT_DOMAIN
			)
			.subscribe(
				response => {
					this.spinner.stopLoader();
					this.generatePDFView(response);
				},
				error => {
					this.spinner.stopLoader();
				}
			);
	}
}
